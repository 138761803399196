<div class="header">
  <h1 mat-dialog-title>{{ data.title | translate }}</h1>
</div>
<div mat-dialog-content>
  <div class="dialog-message" [innerHTML]="data.message | translate"></div>
</div>
<div mat-dialog-actions>
  <button
    [class.bordered]="data.confirm !== undefined"
    *ngIf="data.cancel != undefined"
    mat-flat-button
    class="btn btn-cancel"
    (click)="cancel()"
  >
    {{ data.cancel | translate }}
  </button>
  <button mat-flat-button (click)="confirm()" *ngIf="data.confirm">
    {{ data.confirm | translate }}
  </button>
</div>
