export enum LocationSource {
  Ptv = 0,
  Manual = 1,
  OpenStreetMap = 2,
  Paavo = 3,
  LounaisTieto = 4,
  TurunKaupunki = 5,
  Prh = 6,
  Hakosalo = 7,
  Yhdistykset = 8,
  OpenStreetMap2 = 9,
  OpenStreetMapHoods = 10,
}
