/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./single-review-rating.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "@angular/material/dialog";
import * as i8 from "./single-review-rating.component";
var styles_SingleReviewRatingComponent = [i0.styles];
var RenderType_SingleReviewRatingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SingleReviewRatingComponent, data: {} });
export { RenderType_SingleReviewRatingComponent as RenderType_SingleReviewRatingComponent };
function View_SingleReviewRatingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_SingleReviewRatingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fa-icon", [["class", "star ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "rating-value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 3)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.faHeartSolid; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 1), ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.value), "1.1-1", "fi")); _ck(_v, 4, 0, currVal_2); }); }
function View_SingleReviewRatingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fa-icon", [["class", "star ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "rating-value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["-"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.faHeartSolid; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0); }); }
function View_SingleReviewRatingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "rating-category"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "label", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 1), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "average-rating"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SingleReviewRatingComponent_2)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["thenAverage", 2]], null, 0, null, View_SingleReviewRatingComponent_3)), (_l()(), i1.ɵand(0, [["elseAverage", 2]], null, 0, null, View_SingleReviewRatingComponent_4))], function (_ck, _v) { var currVal_1 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.value) != 0); var currVal_2 = i1.ɵnov(_v, 9); var currVal_3 = i1.ɵnov(_v, 10); _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 5).transform((("SUBFEATURE." + i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.category == null) ? null : _v.context.$implicit.category.name))))) + ".TITLE"))); _ck(_v, 3, 0, currVal_0); }); }
export function View_SingleReviewRatingComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.UpperCasePipe, []), i1.ɵpid(0, i6.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "dialog-rating mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i7.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SingleReviewRatingComponent_1)), i1.ɵdid(5, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.ratings; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_SingleReviewRatingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "single-review-rating", [], null, null, null, View_SingleReviewRatingComponent_0, RenderType_SingleReviewRatingComponent)), i1.ɵdid(1, 114688, null, 0, i8.SingleReviewRatingComponent, [i7.MatDialogRef, i7.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SingleReviewRatingComponentNgFactory = i1.ɵccf("single-review-rating", i8.SingleReviewRatingComponent, View_SingleReviewRatingComponent_Host_0, {}, {}, []);
export { SingleReviewRatingComponentNgFactory as SingleReviewRatingComponentNgFactory };
