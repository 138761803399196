import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FabService {
  private showFabFeedback = new Subject<boolean>();
  public showFabFeedback$ = this.showFabFeedback.asObservable();

  private showFabScrollUp = new Subject<boolean>();
  public showFabScrollUp$ = this.showFabScrollUp.asObservable();

  constructor() {}

  setShowFeedbackBubbleState(state: boolean) {
    this.showFabFeedback.next(state);
  }

  setShowUpState(state: boolean) {
    this.showFabScrollUp.next(state);
  }
}
