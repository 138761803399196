import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { endpoints } from '../../configs/sources';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SubregionsService = /** @class */ (function () {
    function SubregionsService(http) {
        this.http = http;
        this.endpoint = endpoints.subregions;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        this.showDrafts = false;
        var location = window.location.host;
        if (location == 'dev.hoods.fi' || location == 'beta.hoods.fi' || location == 'localhost:4200') {
            this.showDrafts = true;
        }
    }
    SubregionsService.prototype.setShowDrafts = function (value) {
        this.showDrafts = value;
    };
    SubregionsService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    SubregionsService.prototype.getSubregions = function () {
        return this.http.get(this.endpoint + '?draft=' + this.showDrafts).pipe(map(this.extractData));
    };
    SubregionsService.prototype.getSubregion = function (id) {
        return this.http
            .get(this.endpoint + '/' + id + '?draft=' + this.showDrafts)
            .pipe(map(this.extractData));
    };
    SubregionsService.prototype.getSubregionsMunicipalities = function (id) {
        return this.http
            .get(this.endpoint + '/' + id + '/municipalities' + '?draft=' + this.showDrafts)
            .pipe(map(this.extractData));
    };
    SubregionsService.ngInjectableDef = i0.defineInjectable({ factory: function SubregionsService_Factory() { return new SubregionsService(i0.inject(i1.HttpClient)); }, token: SubregionsService, providedIn: "root" });
    return SubregionsService;
}());
export { SubregionsService };
