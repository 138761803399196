import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
var RoutingState = /** @class */ (function () {
    function RoutingState(router) {
        this.router = router;
        this.history = [];
    }
    RoutingState.prototype.loadRouting = function () {
        var _this = this;
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (_a) {
            var urlAfterRedirects = _a.urlAfterRedirects;
            var url = urlAfterRedirects.split('?');
            _this.history = _this.history.concat([{ url: url[0], params: url[1] }]);
            // console.log(this.history);
        });
    };
    RoutingState.prototype.getHistory = function () {
        return this.history;
    };
    RoutingState.prototype.getPreviousUrl = function () {
        var previous = this.history[this.history.length - 2] || '/index';
        //console.log('Previous view:' + previous);
        return previous;
    };
    RoutingState.prototype.getCurrent = function () {
        var current = this.history[this.history.length - 1];
        return current;
    };
    return RoutingState;
}());
export { RoutingState };
