import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ServicesFilterComponent } from '../services-filter/services-filter.component';
import {
  SelectedServiceFilters,
  ServicesFilterService,
} from '../../../services/services-filter/services-filter.service';

@Component({
  selector: 'app-services-filter-mobile',
  templateUrl: './services-filter-mobile.component.html',
  styleUrls: ['./services-filter-mobile.component.scss'],
})
export class ServicesFilterMobileComponent implements OnInit {
  @ViewChild(ServicesFilterComponent) servicesFilterComponent: ServicesFilterComponent;

  public parentCategories;
  public childCategories;
  public dataSources;

  constructor(
    public dialogRef: MatDialogRef<ServicesFilterMobileComponent>,
    private servicesFilterService: ServicesFilterService,
  ) {}

  ngOnInit() {
    this.getCategories();
  }

  async getCategories() {
    const { parents, childs, sources } = await this.servicesFilterService.getCategories();
    this.parentCategories = parents;
    this.childCategories = childs;
    this.dataSources = sources;
    this.getParentChildBadgeCount();
  }

  saveSelectedFilters(filterType: string) {
    if (filterType === 'category') {
      this.getParentChildBadgeCount();
    }
  }

  resetUserSelection() {
    this.childCategories.forEach((child) => {
      child.selected = false;
    });
    this.dataSources.forEach((source) => {
      // TODO: Temporarily set data source 12 as default filter. Remove this default
      // when integrating other service types.
      if (source.code !== 12) {
        source.selected = false;
      }
    });
    this.getParentChildBadgeCount();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openResults() {
    const selected = this.getUserSelection();
    this.servicesFilterService.setSelectedServicesFilters(selected);
    this.dialogRef.close();
  }

  getUserSelection(): SelectedServiceFilters {
    const filteredSources = this.dataSources.filter((source) => source.selected);
    const selectedSources = filteredSources.map((source) => source.code);

    const filteredCategories = this.childCategories.filter((child) => child.selected);
    const selectedCategories = filteredCategories.map((child) => child.id);

    const selected: SelectedServiceFilters = {
      sources: selectedSources,
      categories: selectedCategories,
    };
    return selected;
  }

  getParentChildBadgeCount() {
    this.parentCategories.forEach((parent) => {
      const selectedChilds = this.childCategories.filter(
        (child) => child.locationCategoryId === parent.id && child.selected,
      );
      parent.selectedChildCount = selectedChilds.length;
    });
  }

  scrollContentIntoView(expansionPanelIndex: number) {
    // Scrolls the expansion panel to top when selected
    const container: Element = document.getElementsByClassName('mat-dialog-container')[0];
    const panel: HTMLElement = document.getElementsByClassName('mat-expansion-panel')[
      expansionPanelIndex
    ] as HTMLElement;
    const height: Element = document.getElementsByClassName('mat-toolbar-single-row')[0];

    container.scrollTop = panel.offsetTop - height.clientHeight;
  }
}
