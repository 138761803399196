import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { base, endpoints } from '../../configs/sources';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../registration/user.service';
import { TokenStorageService } from '../registration/token-storage.service';
import { IUser } from '../../models/IUser';

@Injectable({
  providedIn: 'root',
})
export class HoodsService {
  endpoint = endpoints.hoods;
  endpoint2 = endpoints.hood;
  endpointCountry = endpoints.search;

  showDrafts = false;

  private hoodsSubject = new BehaviorSubject([]);
  hoods = this.hoodsSubject.asObservable();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private tokenStorage: TokenStorageService,
  ) {
    let location = window.location.host;

    if (location == 'dev.hoods.fi' || location == 'beta.hoods.fi' || location == 'localhost:4200') {
      this.showDrafts = true;
    }
  }

  setShowDrafts(value: boolean) {
    this.showDrafts = value;
  }

  private extractData(res) {
    /* const hoodFilter = [
      7, 29, 17, 5, 8, 11, 28, 26, 34, 36, 35, 38, 40, 44, 52, 54, 42, 59, 72, 69, 27,
      57, 56, 32, 58, 55, 47, 33, 16, 45, 39, 73, 71, 70, 37, 46, 13, 53, 61, 31, 68, 41,
      62, 63, 64, 65, 67, 43, 48, 49, 50, 51, 60
    ]; */
    const body = res || {};

    // check if we're in the beta domain and filter results accordingly
    /* const domain = window.location.host.split('.');
    console.log(domain[0]);
    if (domain[0] !== 'dev' && body.hoods !== undefined){
      body['hoods'] = body.hoods.filter(item => hoodFilter.indexOf(item.id) > -1);
      body['count'] = hoodFilter.length;
    } */

    return body;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  // Fix undefined variables in query params
  fixme(p, i = -1) {
    try {
      if (p == null) {
        return '';
      } else if (i > -1) {
        return p[i] ? p[i] : p;
      } else {
        return p;
      }
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  // Translates the given number from eg. 0-1 range to min-max range
  numTransform(min, max, rangeMin, value) {
    const range = max - min;
    const percentage = value - rangeMin;
    const position = range * percentage;
    // console.log(min + position);
    return Math.round(min + position);
  }

  range(type, value, pos) {
    if (type == 'sales') {
      // console.log(value);
      if (pos == 0 && value == 1) {
        value = 1.00001;
      }

      if (value == 0) {
        return 0;
      }
      if (value > 0 && value <= 1) {
        const rangeMin = 0;
        const min = 1254;
        let max;
        if (this.translate.instant('FILTER.PRICE.SALES_AVERAGE')) {
          max = this.translate.instant('FILTER.PRICE.SALES_AVERAGE');
        } else {
          max = 2090;
        }
        return this.numTransform(min, max, rangeMin, value);
      }
      if (value > 1 && value <= 2) {
        const rangeMin = 1;
        let max;
        let min;
        if (this.translate.instant('FILTER.PRICE.SALES_AVERAGE')) {
          min = parseInt(this.translate.instant('FILTER.PRICE.SALES_AVERAGE'));
        } else {
          min = 2090;
        }
        if (this.translate.instant('FILTER.PRICE.SALES_MAX')) {
          max = parseInt(this.translate.instant('FILTER.PRICE.SALES_MAX'));
        } else {
          max = 3344;
        }
        return this.numTransform(min, max, rangeMin, value);
      }
    }
    if (type == 'rent') {
      //console.log(value);
      if (pos == 0 && value == 1) {
        value = 1.1;
      }

      if (value >= 0 && value <= 1) {
        const rangeMin = 0;
        const min = 8;
        const max = 12.3;
        return this.numTransform(min, max, rangeMin, value);
      }
      if (value > 1 && value <= 2) {
        const rangeMin = 1;
        const min = 11.5;
        const max = 17.7;
        return this.numTransform(min, max, rangeMin, value);
      }
    }
    if (type == 'buildings') {
      if (pos == 0 && value > 1) {
        value = value - 0.5;
      }
      if (pos == 1 && value == 1) {
        value = 1.1;
      }

      if (value >= 0 && value <= 0.5) {
        const rangeMin = 0;
        const min = 0;
        const max = 15;
        return this.numTransform(min, max, rangeMin, value);
      }
      if (value >= 0.5 && value <= 1.5) {
        const rangeMin = 1;
        const min = 15;
        const max = 50;
        return this.numTransform(min, max, rangeMin, value);
      }
      if (value >= 1.5 && value <= 2.5) {
        const rangeMin = 1;
        const min = 50;
        const max = 500;
        return this.numTransform(min, max, rangeMin, value);
      }
      if (value >= 2.5 && value <= 3) {
        const rangeMin = 2;
        const min = 900;
        const max = 2340;
        return this.numTransform(min, max, rangeMin, value);
      }
    }
  }

  searchHoods(q): Observable<any> {
    // console.log(q);
    let params;
    if (
      q.services == null &&
      q.buildings == null &&
      q.rent == null &&
      q.sales == null &&
      q.features == null &&
      q.address == null &&
      q.municipalities == null
    ) {
      params = '';
    } else {
      let pricefilter = '';
      // console.log(q.pricefilter);
      if (q.pricefilter == 'rent') {
        pricefilter =
          '&rent-price=' +
          this.range('rent', this.fixme(q.rent, 0), 0) +
          '-' +
          this.range('rent', this.fixme(q.rent, 1), 1);
      }
      if (q.pricefilter == 'sales') {
        pricefilter =
          '&sales-price=' +
          this.range('sales', this.fixme(q.sales, 0), 0) +
          '-' +
          this.range('sales', this.fixme(q.sales, 1), 1);
      }
      params = '?locations=' + this.fixme(q.services) + pricefilter;
      // '&buildings=' + this.range('buildings', this.fixme(q.buildings, 0), 0) + '-' + this.range('buildings', this.fixme(q.buildings, 1), 1) +
      if (q.features) {
        params += '&features=' + q.mode + q.features;
      }

      if (q.lots === 'true') params += '&lots';
      if (q.lotstypes !== undefined) params += '&lots-type=' + this.fixme(q.lotstypes);
      if (q.lotspricetypes !== undefined)
        params += '&lots-price-type=' + this.fixme(q.lotspricetypes);
      if (q.municipalities !== undefined)
        params += '&municipalities=' + this.fixme(q.municipalities);
      if (q.address && q.addressWayOfTransport && q.addressTimeLimit) {
        params += `&address=${q.address}`;
        params += `&addressWayOfTransport=${q.addressWayOfTransport}`;
        params += `&addressTimeLimit=${q.addressTimeLimit}`;
      }

      console.log('Query url:' + params);
    }

    let s = q.sorting;
    let sorting = 'sort=' + s.sort + '&dir=' + s.dir;
    // UNCOMMENT TO USE PAGINATION:
    // let sorting = 'sort=' + s.sort + '&dir=' + s.dir + '&page=' + s.page + '&start=' + s.start;

    if (params == '') {
      sorting = '?' + sorting;
    } else {
      sorting = '&' + sorting;
    }
    const query = endpoints.search + params + sorting + '&draft=' + this.showDrafts;
    // console.log(query);
    // Send query params to GA

    //(<any>window).gtag('event', 'page_view', {
    //  'page_path': '/results' + params + sorting
    //});
    let analytics_string =
      'featureFilter:' +
      q.features +
      '; priceFilter:' +
      q.price +
      '; serviceFilter:' +
      q.services +
      '; searchArea:Turku';

    // console.log(analytics_string);
    (<any>window).gtag('event', 'search', {
      event_category: 'hood_search',
      event_label: analytics_string,
    });

    const response = this.http.get(query).pipe(map(this.extractData));
    return response;
  }

  getHoods(): Observable<any> {
    return this.http
      .get(this.endpoint + '?sort=name&draft=' + this.showDrafts)
      .pipe(map(this.extractData));
  }

  getHoodsFromCountry(): Observable<any> {
    return this.http
      .get(this.endpointCountry + '?sort=name&draft=' + this.showDrafts)
      .pipe(map(this.extractData));
  }

  updateHoodsSubject(hoods) {
    this.hoodsSubject.next(hoods.hoods);
  }

  getHood(id): Observable<any> {
    return this.http.get(this.endpoint2 + id).pipe(map(this.extractData));
  }

  getSlugs(): Observable<any> {
    return this.http
      .get(this.endpointCountry + '?draft=' + this.showDrafts)
      .pipe(map(this.extractData));
  }
}
