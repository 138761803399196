import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { TokenStorageService } from '../services/registration/token-storage.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/registration/auth.service';
import { Router } from '@angular/router';
import { catchError, switchMap, filter, take } from 'rxjs/operators';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshingInProgress: boolean;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private tokenStorage: TokenStorageService, private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.tokenStorage.getToken() || this.tokenStorage.getTemporaryToken();

    return next.handle(this.addAuthorizationHeader(req, accessToken)).pipe(
      catchError((err) => {
        // in case of 401 http error
        if (err instanceof HttpErrorResponse && err.status === 401) {
          // get refresh tokens
          const refreshToken =
            this.tokenStorage.getRefreshToken() || this.tokenStorage.getTemporaryRefreshToken();

          // if there are tokens then send refresh token request
          if (refreshToken && accessToken) {
            let temporary = !this.tokenStorage.getRefreshToken();
            return this.refreshToken(req, next, temporary);
          }

          // otherwise logout and redirect to login page
          return this.logoutAndRedirect(err);
        }

        // in case of 440 http error (refresh token failed)
        if (err instanceof HttpErrorResponse && err.status === 440) {
          // logout and redirect to login page
          return this.logoutAndRedirect(err);
        }
        // if error has status neither 401 nor 403 then just return this error
        return throwError(err);
      }),
    );
  }

  private addAuthorizationHeader(req: HttpRequest<any>, token: string): HttpRequest<any> {
    let regex = new RegExp('cyf-directus.*azurewebsites.net.*');
    //checks if req url is for directus, no matter the environtment
    if (regex.test(req.url)) {
      return req;
    }
    regex = new RegExp('digitransit.fi');
    //checks if req url is for digitransit
    if (regex.test(req.url)) {
      return req;
    }
    if (token != null) {
      return req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    }
    return req;
  }

  private logoutAndRedirect(err): Observable<HttpEvent<any>> {
    this.authService.logoutUser();
    // location.reload();
    return throwError(err);
  }

  private refreshToken(
    req: HttpRequest<any>,
    next: HttpHandler,
    temporary = false,
  ): Observable<HttpEvent<any>> {
    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.accessTokenSubject.next(null);

      return this.authService.refreshToken(temporary).pipe(
        switchMap((res) => {
          this.refreshingInProgress = false;
          this.accessTokenSubject.next(res.accessToken);
          // repeat failed request with new token
          return next.handle(this.addAuthorizationHeader(req, res.accessToken));
        }),
      );
    } else {
      // wait while getting new token
      return this.accessTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => {
          // repeat failed request with new token
          return next.handle(this.addAuthorizationHeader(req, token));
        }),
      );
    }
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
