import { Injectable } from '@angular/core';
import { IUser } from '../../models/IUser';

const TOKEN_KEY = 'auth-token';
const REFRESH_TOKEN_KEY = 'refresh-token';
const USER_KEY = 'auth-user';

const TEMPORARY_TOKEN_KEY = 'temporary-auth-token';
const TEMPORARY_REFRESH_TOKEN_KEY = 'temporary-refresh-token';
const TEMPORARY_USER_KEY = 'temporary-auth-user';

const TEST_USER = 'is_test_user';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor() {}

  public signOut() {
    window.sessionStorage.clear();
    localStorage.clear();
  }

  public removeTemporaryLogin() {
    window.sessionStorage.removeItem(TEMPORARY_TOKEN_KEY);
    window.sessionStorage.removeItem(TEMPORARY_REFRESH_TOKEN_KEY);
    window.sessionStorage.removeItem(TEMPORARY_USER_KEY);
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }
  public saveTemporaryToken(token: string) {
    window.sessionStorage.removeItem(TEMPORARY_TOKEN_KEY);
    window.sessionStorage.setItem(TEMPORARY_TOKEN_KEY, token);
  }

  public saveRefreshToken(token: string) {
    window.sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    window.sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
  }
  public saveTemporaryRefreshToken(token: string) {
    window.sessionStorage.removeItem(TEMPORARY_REFRESH_TOKEN_KEY);
    window.sessionStorage.setItem(TEMPORARY_REFRESH_TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }
  public getTemporaryToken(): string {
    return sessionStorage.getItem(TEMPORARY_TOKEN_KEY);
  }

  public getRefreshToken(): string {
    return sessionStorage.getItem(REFRESH_TOKEN_KEY);
  }
  public getTemporaryRefreshToken(): string {
    return sessionStorage.getItem(TEMPORARY_REFRESH_TOKEN_KEY);
  }

  public saveUser(user: IUser) {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }
  public saveTemporaryUser(user: IUser) {
    window.sessionStorage.removeItem(TEMPORARY_USER_KEY);
    window.sessionStorage.setItem(TEMPORARY_USER_KEY, JSON.stringify(user));
  }

  public getTemporaryUser(): IUser {
    return JSON.parse(sessionStorage.getItem(TEMPORARY_USER_KEY));
  }
  public getCurrentUser(): IUser {
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }

  public saveTestUser(showDrafts: string) {
    window.sessionStorage.setItem(TEST_USER, showDrafts);
  }

  public getTestUser(): string {
    return sessionStorage.getItem(TEST_USER);
  }
}
