import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { endpoints } from '../../configs/sources';
import { TranslateService } from '@ngx-translate/core';
import { mustUrlProtocol, formatUrl2 } from '../../utils/urlUtils';
import { LocationSource } from '../../models/LocationSource';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngx-translate/core";
var LocationsService = /** @class */ (function () {
    function LocationsService(http, translate) {
        this.http = http;
        this.translate = translate;
        this.endpoint = endpoints.locationtypes;
        this.endpoint2 = endpoints.locations;
        this.endpoint3 = endpoints.locationscats;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
    }
    LocationsService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    LocationsService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            // console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            // console.log(`${operation} failed: ${error.message}`);
            return of(result);
        };
    };
    LocationsService.prototype.getCategories = function () {
        return this.http.get(this.endpoint3).pipe(map(this.extractData));
    };
    LocationsService.prototype.getTypes = function () {
        return this.http.get(this.endpoint).pipe(map(this.extractData));
    };
    LocationsService.prototype.getLocations = function () {
        return this.http.get(this.endpoint2).pipe(map(this.extractData));
    };
    LocationsService.prototype.getLocationsBbox = function (bbox, simplified) {
        if (simplified === void 0) { simplified = false; }
        return this.http
            .get(this.endpoint2 + "?bbox=" + bbox + "&simplified=" + simplified)
            .pipe(map(this.extractData));
    };
    LocationsService.prototype.getLocation = function (id, simplified) {
        var url = this.endpoint2 + id;
        if (simplified) {
            url += '?simplified=true';
        }
        return this.http.get(url).pipe(map(this.extractData));
    };
    LocationsService.prototype.isJson = function (str) {
        try {
            JSON.parse(str);
        }
        catch (error) {
            return false;
        }
        return true;
    };
    LocationsService.prototype.getTranslation = function (point, lang) {
        var ret = {
            name: point.name,
            brandName: '',
            contactInfo: '',
            description: point.description ? point.description : '',
            summary: '',
            url: point.url,
        };
        if (!point.description || !this.isJson(point.description))
            return ret;
        var translations = JSON.parse(point.description);
        var setTranslation = function (t) {
            if (t.type === 'Description') {
                ret.description = t.value;
            }
            else if (t.type === 'Summary') {
                ret.summary = t.value;
            }
            else if (t.type === 'Name') {
                ret.name = t.value;
            }
            else if (t.type === 'BrandName') {
                ret.brandName = t.value;
            }
            else if (t.type === 'ContactInfo') {
                ret.contactInfo = t.value;
            }
            else if (t.type === 'URL') {
                ret.url = t.value;
            }
        };
        // Set fallback values first
        for (var _i = 0, _a = translations.filter(function (x) { return x.language === 'fi'; }); _i < _a.length; _i++) {
            var t = _a[_i];
            setTranslation(t);
        }
        if (lang === 'fi') {
            return ret;
        }
        // Set correct language values
        for (var _b = 0, _c = translations.filter(function (x) { return x.language === lang; }); _b < _c.length; _b++) {
            var t = _c[_b];
            setTranslation(t);
        }
        return ret;
    };
    LocationsService.prototype.locationPopupText = function (point, currentLang, iconString) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var icons, icon, osmUrl, popupContent, strings, description, translations, latitude, longitude;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        icons = ['utensils', 'home', 'building', 'briefcase', 'hotel'];
                        icon = '<i style="font-size: 1.3em;" class="fas fa-' + ("" + iconString) + '"></i>';
                        popupContent = '';
                        return [4 /*yield*/, this.translate
                                .get([
                                'LOCATIONS.RECOMMEND_CHANGES',
                                'LOCATIONS.OSM_EXPLANATION',
                                'LOCATIONS.GO_TO_OSM_MAP',
                                'LOCATIONS.OSM_TITLE',
                            ])
                                .toPromise()];
                    case 1:
                        strings = _a.sent();
                        description = '';
                        translations = this.getTranslation(point, currentLang);
                        if (point.sourceId === LocationSource.OpenStreetMapHoods) {
                            latitude = point.point.geometry.coordinates[1];
                            longitude = point.point.geometry.coordinates[0];
                            if (point.externalId) {
                                osmUrl = "https://www.openstreetmap.org/node/" + point.externalId + "#map=18/" + latitude + "/" + longitude;
                            }
                            else {
                                osmUrl = "https://www.openstreetmap.org/query?lat=" + latitude + "&lon=" + longitude + "#map=18/" + latitude + "/" + longitude;
                            }
                        }
                        description = translations.description;
                        if (point.sourceId === LocationSource.Ptv) {
                            description = translations.summary ? translations.summary : point.description;
                        }
                        // All the classes are in styles.scss
                        popupContent = "\n    <div class=\"location-card\">\n      <div class=\"location-card-title\">\n        <div class=\"card-title-icon\">" + icon + "</div>\n        <div class=\"card-title-text\">\n          <div class=\"card-title-name\"><span>" + translations.name + "</span></div>\n        </div>\n      </div>\n\n      " + (point.imageUrl
                            ? "<div class=\"card-image\" style=\"background-image:url(" + point.picture + ");\">\n      </div>"
                            : '<div class="card-divider-top"><div class="card-divider"></div></div>') + "\n\n      " + (description
                            ? "<div class=\"card-description\">\n          <p>" + description + "</p>\n        </div>"
                            : "") + "\n      <div class=\"card-content-wrapper\">\n      " + (point.address
                            ? "<div class=\"card-row\">\n            <div class=\"card-row-container flex\">\n              <i style=\"font-size: 1.3em;margin-right: 0.8em;\" class=\"fas fa-map-marker-alt\"></i>\n              <span>" + point.address + (point.city ? ', ' + point.city : '') + "</span>\n            </div>\n          </div>"
                            : '') + "\n\n      " + (translations.url
                            ? "<div class=\"card-row flex\">\n              <div class=\"card-row-container url-container\">\n                <i style=\"font-size: 1.3em;margin-right: 0.8em;\" class=\"fas fa-globe\"></i>\n                <a href=\"" + mustUrlProtocol(translations.url) + "\" target=\"_blank\">" + formatUrl2(translations.url) + "</a>\n              </div>\n              <div class=\"\">\n                <i style=\"font-size: 1.3em;\" class=\"fas fa-external-link-alt\"></i>\n              </div>\n          </div>"
                            : '') + "\n      </div>\n\n\n      " + (osmUrl
                            ? "\n\n      <div class=\"card-divider\"></div>\n      <div id=\"feedbackPopup\" class=\"hide-modal feedbackPopupBtn\">\n          <span class=\"card-link\">" + strings['LOCATIONS.RECOMMEND_CHANGES'] + "</span>\n          <div class=\"feedback-modal\">\n            <button class=\"closeFeedbackBtn leaflet-popup-close-button\">\u00D7</button>\n            <div class=\"header\">\n              <h3>" + strings['LOCATIONS.OSM_TITLE'] + "</h3>\n            </div>\n            <div class=\"content\">\n              <p>" + strings['LOCATIONS.OSM_EXPLANATION'] + "</p>\n            </div>\n            <a class=\"card-link card-btn\"\n              href=\"" + osmUrl + "\"\n              style=\"display:block;\"\n              target=\"_blank\">\n                <span>" + strings['LOCATIONS.GO_TO_OSM_MAP'] + "</span>\n                <i class=\"fas fa-external-link-alt\"></i>\n            </a>\n          </div>\n      </div>\n\n      "
                            : '') + "\n\n      </div>\n      ";
                        return [2 /*return*/, popupContent];
                }
            });
        });
    };
    LocationsService.ngInjectableDef = i0.defineInjectable({ factory: function LocationsService_Factory() { return new LocationsService(i0.inject(i1.HttpClient), i0.inject(i2.TranslateService)); }, token: LocationsService, providedIn: "root" });
    return LocationsService;
}());
export { LocationsService };
