import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { endpoints } from '../../configs/sources';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AZURE_MAPS_KEY = 'ShKg6ldy-yJKk3-lgSxMl_r0CvS5N-dwy6hvC_3l90A';
var addressSearch = 'https://atlas.microsoft.com/search/address/json' +
    '?&api-version=1.0' +
    ("&subscription-key=" + AZURE_MAPS_KEY) +
    '&countrySet=FI' +
    '&language=fi-FI' +
    '&limit=5&query=';
var GeocodingService = /** @class */ (function () {
    function GeocodingService(http) {
        this.http = http;
        this.endpoint = endpoints.geocoding;
        this.httpOptions = {
            headers: new HttpHeaders({
                'digitransit-subscription-key': environment.digitransitKey,
            }),
        };
    }
    GeocodingService.prototype.getAutocomplete = function (partial) {
        if (!partial)
            return of([]);
        var endpoint = 'https://api.digitransit.fi/geocoding/v1/autocomplete?sources=osm,oa';
        var string = encodeURIComponent(partial);
        return this.http.get(endpoint + '&text=' + string, this.httpOptions).pipe(map(function (data) {
            return data.features.map(function (f) { return f.properties.label; });
        }));
    };
    GeocodingService.prototype.getAutocompleteFromAzureMaps = function (partial) {
        var string = encodeURIComponent(partial);
        return this.http.get(addressSearch + string).pipe(map(function (data) {
            return data.results.map(function (f) { return f.address.freeformAddress; });
        }));
    };
    GeocodingService.prototype.getLocationByAddress = function (query) {
        var _this = this;
        return new Observable(function (observer) {
            var response;
            var string = encodeURIComponent(query);
            _this.http.get(_this.endpoint + string, _this.httpOptions).subscribe(function (result) {
                if (result.features[0] !== undefined) {
                    response = [
                        {
                            lat: result.features[0].geometry.coordinates[1],
                            lon: result.features[0].geometry.coordinates[0],
                            label: result.features[0].properties.label,
                            zipcode: result.features[0].properties.postalcode,
                        },
                    ];
                    observer.next(response);
                }
                else {
                    observer.error('Address does not exist');
                }
            });
        });
    };
    GeocodingService.prototype.getAddressByCoords = function (latLng) {
        var _this = this;
        return new Observable(function (observer) {
            var response;
            _this.http
                .get(endpoints.reversegeo + '?size=1&point.lat=' + latLng.lat + '&point.lon=' + latLng.lng, _this.httpOptions)
                .subscribe(function (result) {
                if (result.features[0] !== undefined) {
                    response = {
                        lat: result.features[0].geometry.coordinates[1],
                        lon: result.features[0].geometry.coordinates[0],
                        address: result.features[0].properties.name,
                        zipcode: result.features[0].properties.postalcode,
                        city: result.features[0].properties.localadmin,
                    };
                    observer.next(response);
                }
                else {
                    observer.error('No address found for coordinates.');
                }
            });
        });
    };
    GeocodingService.ngInjectableDef = i0.defineInjectable({ factory: function GeocodingService_Factory() { return new GeocodingService(i0.inject(i1.HttpClient)); }, token: GeocodingService, providedIn: "root" });
    return GeocodingService;
}());
export { GeocodingService };
