import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router, private meta: Meta) {
    this.meta.addTag({ name: 'robots', content: 'noindex, follow' });
  }

  ngOnInit() {}

  goHome() {
    this.router.navigate(['/home']);
  }
}
