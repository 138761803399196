/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./paginator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./paginator.component";
var styles_PaginatorComponent = [i0.styles];
var RenderType_PaginatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaginatorComponent, data: {} });
export { RenderType_PaginatorComponent as RenderType_PaginatorComponent };
function View_PaginatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "page"]], [[8, "disabled", 0], [2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.index + _co.getPageOffset()) == (_co.paginationOptionsRef.pageIndex - 1)); var currVal_1 = ((_v.context.index + _co.getPageOffset()) == (_co.paginationOptionsRef.pageIndex - 1)); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("AC.PAGE")); _ck(_v, 2, 0, currVal_2); var currVal_3 = ((_co.getPageOffset() + _v.context.index) + 1); _ck(_v, 4, 0, currVal_3); }); }
export function View_PaginatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "prev"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.prevPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-chevron-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_1)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [["class", "next"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fas fa-chevron-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getPageArray().slice(_co.getPageOffset(), _co.maxPageNumbersToShow()); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.paginationOptionsRef.pageIndex === 1); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("AC.PREV_PAGE")); _ck(_v, 4, 0, currVal_1); var currVal_3 = (_co.paginationOptionsRef.pageIndex === _co.getPageArray().length); _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("AC.NEXT_PAGE")); _ck(_v, 11, 0, currVal_4); }); }
export function View_PaginatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "paginator", [], null, null, null, View_PaginatorComponent_0, RenderType_PaginatorComponent)), i1.ɵdid(1, 114688, null, 0, i4.PaginatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginatorComponentNgFactory = i1.ɵccf("paginator", i4.PaginatorComponent, View_PaginatorComponent_Host_0, { total: "total", paginationOptionsRef: "paginationOptionsRef", pageNumberCount: "pageNumberCount" }, { paginationOptions: "paginationOptions" }, []);
export { PaginatorComponentNgFactory as PaginatorComponentNgFactory };
