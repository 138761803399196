import { Injectable } from '@angular/core';
import * as L from 'leaflet';

@Injectable({
  providedIn: 'root',
})
export class LeafletMessageBoxService {
  container: HTMLElement;
  timeoutId: number;
  control: any;
  map: L.Map;

  constructor() {}

  createControl(map: L.Map) {
    if (!this.control) {
      // don't create multiple controls for no reason
      if (!this.container) {
        // control container element
        this.container = L.DomUtil.create('div', 'leaflet-messagebox');
      }
      this.control = new L.Control({
        position: 'bottomleft',
      });
      this.control.onAdd = () => {
        return this.container;
      };
      this.control.showMessage = (message: string, timeout: number) => {
        this.container.innerHTML = message;
        this.container.style.display = 'block';
        if (typeof this.timeoutId === 'number') {
          clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(() => {
          this.container.style.display = 'none';
        }, timeout);
      };
      this.control.hideMessage = () => {
        this.container.style.display = 'none';
      };
    }
    this.control.addTo(map);
    return this.control;
  }

  showMessage(message: string, timeout: number = 3000) {
    if (!this.control) {
      console.error('no control for messagebox');
      return;
    }
    this.control.showMessage(message, timeout);
  }
  hideMessage() {
    if (!this.control) {
      console.error('no control for messagebox');
      return;
    }
    this.control.hideMessage();
  }
}
