import { Inject, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { GeocodingService } from '../geocoding/geocoding.service';
import { HoodsService } from '../hoods/hoods.service';
import { LocationsService } from '../locations/locations.service';
import { MunicipalitiesService, Municipality } from '../municipalities/municipalities.service';

const RESULTS = 'search_results';
const POINTS = 'custom_points';
const FILTERS = 'search_filters';
const PREFERENCES = 'user_preferences';
const SLUGS = 'hoods_slugs';

const STORAGE_MUNICIPALITIES = 'municipalities_1';
const SERVICE_CATS = 'service_cats';
const SERVICE_TYPES = 'service_types';

const HOOD_SERVICE_POINTS = 'hood_services';

@Injectable({
  providedIn: 'root',
})
export class MapDataService implements OnInit {
  alwaysSelectedTypes = [
    // add typeIds to always show locations within bounding box
    21, // naapurustosuunnistus
    24, // kesäretkihaaste
  ];
  servicePointRequest: any;
  getAlwaysSelectedTypes() {
    return this.alwaysSelectedTypes;
  }

  defaultLocations = [
    {
      name: 'Kaarinan keskusta',
      address: '',
      location: {
        lat: 60.4084384,
        lon: 22.3659232,
      },
    },
    {
      name: 'Turun keskusta',
      address: 'Kauppatori, 20100 Turku',
      location: {
        lat: 60.4519743,
        lon: 22.2661993,
      },
    },
  ];
  locations = [];

  searchpoint = {
    lat: 0,
    lon: 0,
    distance: 0,
    label: '',
  };

  hoods = [];
  hood = 7;

  currentRegion = 1;

  region = {
    averageSalesPrice: 2090,
    averageRentPrice: 12.2,
  };

  sortingField = 'name';
  sortingDirection = 'ascending';

  preferences = {
    acceptTerms: false,
    language: 'fi',
    area: 'hood',
  };

  serviceTypes;

  public hoodResults = new BehaviorSubject(this.hoods);
  public savedLocations = new BehaviorSubject(this.locations);

  public searchPoint = new BehaviorSubject(this.searchpoint);

  public selectedHood = new BehaviorSubject(this.hood);

  public userPreferences = new BehaviorSubject(this.preferences);

  public filterRefreshed = new BehaviorSubject<'city' | 'hood'>('city');
  currentFilter = this.filterRefreshed.asObservable();

  private servicePointTypesSubject = new ReplaySubject<ServiceType[]>(1);
  public servicePointTypes$ = this.servicePointTypesSubject.asObservable();

  constructor(
    @Inject(SESSION_STORAGE) private storage: StorageService,
    public geocode: GeocodingService,
    private hoodsService: HoodsService,
    private locationService: LocationsService,
    private municipalities: MunicipalitiesService,
    private router: Router,
  ) {
    this.locations.forEach((location) => {
      this.getLocation(location.address).then((loc) => {
        location.location = <any>loc;
      });
    });

    (<any>window).locations = this.locations;
    this.getLocations();
    this.getHoods();
    this.initServicePointTypes();

    // console.log('mapdata init');
    this.hoods = this.storage.get(RESULTS);
  }

  ngOnInit() {}

  //---- IndexedDB operations
  public getConnection(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      let request = indexedDB.open('hoodsDb', 2);

      request.onerror = (event: any) => {
        let request = event.target;
        console.error('IndexedDB error', request.error);
        reject(request.error);
      };

      // DB version has changed
      request.onupgradeneeded = () => {
        let db = request.result;

        // Create an object store for results if it does not exist
        if (!db.objectStoreNames.contains(RESULTS)) {
          db.createObjectStore(RESULTS, { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('service_points')) {
          db.createObjectStore('service_points', { keyPath: 'id', autoIncrement: true });
        }
      };

      request.onsuccess = () => {
        // onSuccess event result is the db instance
        let db = request.result;
        /* console.log('IndexedDB connection opened succesfully.', db); */

        // DB version has changed, close previous connection
        db.onversionchange = () => {
          db.close();
          // location.reload();
        };

        // Return db instance
        resolve(db);
      };
    });
  }

  public async hoodToDb(object) {
    // Get DB connection
    let db = await this.getConnection();

    // Write test object to db
    let transaction = db.transaction(RESULTS, 'readwrite');
    let store = transaction.objectStore(RESULTS);

    store.add(object);
  }

  public async hoodsFromDb(): Promise<any> {
    // Get db connection
    let db = await this.getConnection();

    // Get list of db objects
    let transaction = db.transaction(RESULTS, 'readonly');
    let store = transaction.objectStore(RESULTS);

    let request = store.getAll();
    request.onsuccess = () => {
      // console.log("Get all results", request.result);
      return request.result;
    };
    request.onerror = () => {
      console.error('Error', request.error);
    };
  }

  public async clearDb() {
    var req = indexedDB.deleteDatabase('hoodsDb');
    req.onsuccess = () => console.log('Deleted database successfully');
    req.onerror = () => console.log("Couldn't delete database");
    req.onblocked = () =>
      console.log("Couldn't delete database due to the operation being blocked");

    // // Get db connection
    // let db = await this.getConnection();

    // // Clear objects
    // let transaction = db.transaction(RESULTS, "readwrite");
    // let store = transaction.objectStore(RESULTS);

    // let request = store.clear();
    // request.onsuccess = () => {
    //   // console.log("Clear store", request.result);
    // };
    // request.onerror = () => {
    //   console.log("Error", request.error);
    // }
  }

  // ---- END IndexedDB operations

  // SEARCH RESULTS

  public hoodToLocalStorage(object): void {
    // Save hood to db
    if (object !== undefined) {
      this.hoodToDb(object);
      this.hoods.push(object);
      return;
    } else return;

    /* delete object.country;

    const currentItems = this.storage.get(RESULTS) || [];

    if (object === undefined || currentItems === undefined) {
      return;
    } else {

      currentItems.push(object);
      this.hoods.push(object);

      try {
        this.storage.set(RESULTS, currentItems);
      } catch (error) {
        console.log(error);
        return;
      }

    } */
  }

  addHood(object) {
    this.hoodToLocalStorage(object);
  }

  saveSlug(id, slug) {
    let slugs = [];
    if (this.storage.has(SLUGS)) {
      slugs = this.storage.get(SLUGS);
    }
    slugs.push({
      id: id,
      slug: slug,
    });
    this.storage.set(SLUGS, slugs);
  }

  refreshHoods() {
    this.hoodResults.next(this.hoods);
    (<any>window).hoods = this.hoods;
    return;

    /* if (this.hoodsResultsExist()) {
      this.hoods = this.storage.get(RESULTS);
      this.hoodResults.next(this.hoods);
    }
    else {
      this.hoodResults.next(this.hoods);
    }
    (<any>window).hoods = this.hoods; */
  }

  hoodsResultsExist() {
    return this.storage.has(RESULTS);
  }

  async getHoods() {
    this.hoods = await this.hoodsFromDb();
    this.refreshHoods();
    return;

    /* if (this.storage.get(RESULTS) !== undefined) {
      this.hoods = this.storage.get(RESULTS);
      this.refreshHoods();
    } */
  }

  generateSlugs() {
    return new Promise((resolve) => {
      this.hoodsService.getSlugs().subscribe((res) => {
        let slugs = res.hoods.map((hood) => {
          return {
            id: hood.id,
            slug: hood.slug,
          };
        });
        this.storage.set(SLUGS, slugs);
        resolve(this.storage.get(SLUGS));
      });
    });
  }

  getHoodSlugs(): Promise<any> {
    return new Promise((resolve) => {
      if (this.storage.get(SLUGS) !== undefined) {
        resolve(this.storage.get(SLUGS));
      } else {
        this.generateSlugs().then((slugs) => {
          resolve(slugs);
        });
      }
    });
  }

  clearHoods() {
    this.clearDb();
    // this.storage.remove(RESULTS);
    this.storage.remove(SLUGS);
    this.hoods = [];
    this.refreshHoods();
  }

  // CUSTOM MAP POINTS

  public pointToLocalStorage(object): void {
    const currentItems = this.storage.get(POINTS) || [];
    currentItems.push(object);
    this.storage.set(POINTS, currentItems);
    this.refreshLocations();
  }

  getLocation(address) {
    return new Promise((resolve) => {
      this.geocode.getLocationByAddress(address).subscribe((data) => {
        resolve({
          lat: data[0].lat,
          lon: data[0].lon,
        });
      });
    });
  }

  getLocations() {
    if (this.storage.has(POINTS)) {
      this.locations = this.storage.get(POINTS);
    } else {
      this.storage.set(POINTS, this.locations);
    }
    this.refreshLocations();
  }

  refreshLocations(getDefaults: boolean = false) {
    if (this.storage.has(POINTS) && !getDefaults) {
      this.locations = this.storage.get(POINTS);
      this.savedLocations.next(this.storage.get(POINTS));
    } else if (getDefaults) {
      // console.log(this.defaultLocations);
      this.savedLocations.next(this.defaultLocations);
    } else {
      this.savedLocations.next(this.locations);
    }
  }

  searchPointToLocalStorage(object) {
    this.storage.set('searchpoint', object);
    this.refreshSearchPoint();
  }

  refreshSearchPoint() {
    if (this.storage.has('searchpoint')) {
      this.searchpoint = this.storage.get('searchpoint');
      this.searchPoint.next(this.storage.get('searchpoint'));
    } else {
      this.searchPoint.next(this.searchpoint);
    }
  }

  clearSearchPoint() {
    this.searchpoint = {
      lat: 0,
      lon: 0,
      distance: 0,
      label: '',
    };
    this.searchPointToLocalStorage(this.searchpoint);
  }

  showPoint(object) {
    this.searchPointToLocalStorage(object);
    this.searchpoint = object;
    this.refreshSearchPoint();
  }

  addLocation(object) {
    this.pointToLocalStorage(object);
    this.locations.push(object);
    this.refreshLocations();
  }

  removeLocation(index) {
    const currentItems = this.storage.get(POINTS) || [];
    currentItems.splice(index, 1);
    this.storage.set(POINTS, currentItems);
    this.refreshLocations();
  }

  clearLocations() {
    this.storage.remove(POINTS);
    this.refreshLocations();
  }

  changeHood(id) {
    this.selectedHood.next(id);
    this.hood = id;
  }

  saveFilters(filters) {
    this.storage.set(FILTERS, filters);
  }

  loadFilters() {
    return this.storage.get(FILTERS);
  }

  refreshPreferences() {
    const prefs = this.storage.get(PREFERENCES);
    this.userPreferences.next(prefs);
  }

  saveLanguage(code) {
    // console.log('Saving language to session.');
    console.log(code);
    if (code.length !== 2) {
      this.router.navigate(['not-found']);
      return;
    }
    if (this.storage.has(PREFERENCES)) {
      let prefs = this.storage.get(PREFERENCES);
      if (prefs !== undefined) {
        prefs['language'] = code;
      } else {
        prefs = { language: code };
      }
      this.storage.set(PREFERENCES, prefs);
      this.refreshPreferences();
    } else {
      this.storage.set(PREFERENCES, { language: code });
      this.refreshPreferences();
    }
  }

  checkLanguage() {
    if (this.storage.has(PREFERENCES)) {
      const prefs = this.storage.get(PREFERENCES);
      if (prefs['language'] !== undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getLanguage() {
    const prefs = this.storage.get(PREFERENCES);
    return prefs['language'];
  }

  acceptTerms() {
    if (this.storage.has(PREFERENCES)) {
      let prefs = this.storage.get(PREFERENCES);
      if (prefs !== undefined) {
        prefs['acceptTerms'] = true;
      } else {
        prefs = { acceptTerms: true };
      }
      this.storage.set(PREFERENCES, prefs);
      this.refreshPreferences();
    } else {
      this.storage.set(PREFERENCES, { acceptTerms: true });
      this.refreshPreferences();
    }
  }

  savePreference(id, value) {
    let prefs = this.storage.get(PREFERENCES);

    if (prefs !== undefined) {
      prefs[id] = value;
    } else {
      prefs = { [id]: value };
    }

    this.storage.set(PREFERENCES, prefs);
    // console.log('preferences saved');

    this.userPreferences.next(prefs);
  }

  getPreference(id) {
    if (this.storage.has(PREFERENCES)) {
      return this.storage.get(PREFERENCES)[id];
    } else {
      return '';
    }
  }

  getPreferences(): Observable<any> {
    return this.userPreferences.asObservable();
  }

  // MUNICIPALITY FUNCTIONS

  getMunicipalities(): Promise<Municipality[]> {
    interface cachedList {
      list: Municipality[];
      expirationDate: Date;
    }
    return new Promise((resolve, reject) => {
      if (this.storage.has(STORAGE_MUNICIPALITIES)) {
        const item: cachedList = this.storage.get(STORAGE_MUNICIPALITIES);
        if (new Date(item.expirationDate) < new Date()) return item.list;
      }

      this.municipalities.getMunicipalities().subscribe((result) => {
        const item: cachedList = {
          list: result.municipalities,
          expirationDate: new Date(),
        };
        item.expirationDate.setDate(item.expirationDate.getDate() + 1);
        this.storage.set(STORAGE_MUNICIPALITIES, item);
        resolve(result.municipalities);
      });
    });
  }

  // SERVICE POINT FUNCTIONS

  async getServicePointCats() {
    if (this.storage.has(SERVICE_CATS)) {
      return this.storage.get(SERVICE_CATS);
    }
    const cats = await this.locationService.getCategories().toPromise();

    this.storage.set(SERVICE_CATS, cats.locationCategories);
    return cats.locationCategories;
  }

  private async initServicePointTypes(): Promise<void> {
    if (this.storage.has(SERVICE_TYPES)) {
      this.servicePointTypesSubject.next(this.storage.get(SERVICE_TYPES));
      return;
    }

    const types = await this.locationService.getTypes().toPromise();

    // Enable the naapurustosuunnistus campaign category
    types.locationTypes.forEach((type) => {
      if (this.alwaysSelectedTypes.includes(type.id)) {
        type.selected = true;
      }
    });

    // Enable the events campaign category
    // types.locationTypes[21]['selected'] = true;

    // Remove majoitus. See tickets 11237, 12277
    types.locationTypes = types.locationTypes.filter((type) => type.id !== 5);

    this.storage.set(SERVICE_TYPES, types.locationTypes);
    this.servicePointTypesSubject.next(this.storage.get(SERVICE_TYPES));
  }

  saveServicePointTypes(array: ServiceType[]) {
    this.storage.set(SERVICE_TYPES, array);
    this.servicePointTypesSubject.next(array);
  }

  getServicePoints(
    area: 'hood' | 'city',
    bbox: string | undefined = undefined,
    simplified = false,
  ) {
    return new Promise<any[]>((resolve, reject) => {
      if (area === 'hood') {
        if (this.storage.has(HOOD_SERVICE_POINTS)) {
          resolve([this.storage.get(HOOD_SERVICE_POINTS)]);
        }
      }
      if (area !== 'city') {
        console.error(`Invalid area ${area}`);
        reject('error');
        return;
      }
      if (this.servicePointRequest && !this.servicePointRequest.closed) {
        this.cancelServicePoints();
      }
      this.servicePointRequest = this.locationService
        .getLocationsBbox(bbox, simplified)
        .subscribe((result) => {
          if (!result.locations) resolve([]);
          resolve(result.locations);
        });
    });
  }

  public async cancelServicePoints() {
    this.servicePointRequest.unsubscribe();
  }

  saveServicePoints(area, array) {
    // if (area == 'city') {
    //   this.storage.set(SERVICE_POINTS, array);
    // }
    if (area == 'hood') {
      this.storage.set(HOOD_SERVICE_POINTS, array);
    }
  }
}

export interface SimplifiedLocation {
  id: number;
  name: string;
  /** Location type category ids */
  tids: number[];
  /** coordinates */
  c: [number, number];

  visible: boolean | undefined;
  showLocations: boolean | undefined;
  url: string;
}

export interface ServiceType {
  id: number;
  locationCategoryId: number;
  name: string;
  icon: string;
  selected?: boolean;
}
