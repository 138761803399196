import { Component, Input, OnInit } from '@angular/core';
import { LocationSource } from '../../../models/LocationSource';
import { LocationsService } from '../../../services/locations/locations.service';
import { formatUrl, mustUrlProtocol } from '../../../utils/urlUtils';

@Component({
  selector: 'app-service-content',
  templateUrl: './service-content.component.html',
  styleUrls: ['./service-content.component.scss'],
})
export class ServiceContentComponent implements OnInit {
  @Input() public data: any;

  public service: any;
  public osmUrl = undefined;
  public currentLanguage = 'fi';
  public translations: any;
  public description = '';

  constructor(private locationService: LocationsService) {}

  ngOnInit() {
    this.service = this.data.point;
    this.currentLanguage = this.data.currentLanguage;
    this.initServiceData();
  }

  initServiceData() {
    this.translations = {
      name: this.service.name,
      brandName: '',
      contactInfo: '',
      description: '',
    };

    this.translations = this.locationService.getTranslation(this.service, this.currentLanguage);

    if (this.service.sourceId === LocationSource.OpenStreetMapHoods) {
      let latitude = this.service.point.geometry.coordinates[1];
      let longitude = this.service.point.geometry.coordinates[0];
      if (this.service.externalId) {
        this.osmUrl = `https://www.openstreetmap.org/node/${this.service.externalId}#map=18/${latitude}/${longitude}`;
      } else {
        this.osmUrl = `https://www.openstreetmap.org/query?lat=${latitude}&lon=${longitude}`;
      }
    }

    this.description = this.translations.description;

    if (this.service.sourceId === LocationSource.Ptv) {
      this.description = this.translations.summary;
    }
  }

  urlLink(url) {
    return mustUrlProtocol(url);
  }

  formatUrl(url) {
    return formatUrl(url);
  }
}
