/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-card-small.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-bar";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "./event-card-small.component";
import * as i9 from "@ngx-translate/core";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../../services/events/event.service";
import * as i12 from "@angular/router";
var styles_EventCardSmallComponent = [i0.styles];
var RenderType_EventCardSmallComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventCardSmallComponent, data: {} });
export { RenderType_EventCardSmallComponent as RenderType_EventCardSmallComponent };
function View_EventCardSmallComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.imageUrl, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.imageAltText, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_EventCardSmallComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "placeholder-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["favorite"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_EventCardSmallComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatProgressBar_0, i4.RenderType_MatProgressBar)), i1.ɵdid(2, 4374528, null, 0, i5.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i6.ANIMATION_MODULE_TYPE], [2, i5.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).value; var currVal_1 = i1.ɵnov(_v, 2).mode; var currVal_2 = i1.ɵnov(_v, 2)._isNoopAnimation; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_EventCardSmallComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "card"], ["role", "button"]], [[2, "border", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openEventItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventCardSmallComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventCardSmallComponent_2)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventCardSmallComponent_3)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.imageUrl; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.imageUrl; _ck(_v, 5, 0, currVal_2); var currVal_5 = _co.loading; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.name; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.date; _ck(_v, 10, 0, currVal_4); }); }
export function View_EventCardSmallComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "event-card-small", [], null, null, null, View_EventCardSmallComponent_0, RenderType_EventCardSmallComponent)), i1.ɵdid(1, 114688, null, 0, i8.EventCardSmallComponent, [i9.TranslateService, i10.MatDialog, i11.EventService, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventCardSmallComponentNgFactory = i1.ɵccf("event-card-small", i8.EventCardSmallComponent, View_EventCardSmallComponent_Host_0, { data: "data" }, {}, []);
export { EventCardSmallComponentNgFactory as EventCardSmallComponentNgFactory };
