<div class="container">
  <mat-toolbar>
    <h1>{{ 'services-filter-mobile.label.header' | translate }}</h1>
    <span class="fill-remaining-space"></span>
    <mat-icon (click)="closeDialog()">close</mat-icon>
  </mat-toolbar>

  <div class="category-label">
    <h2>{{ 'services-filter-mobile.label.category' | translate }}</h2>
  </div>

  <div *ngIf="parentCategories && childCategories" class="category">
    <div class="options-panel">
      <mat-accordion displayMode="flat" class="add-offset" [hideToggle]="true">
        <mat-expansion-panel
          #parentPanel
          *ngFor="let parent of parentCategories; let i = index"
          (opened)="parent.open = !parent.open"
          (afterExpand)="scrollContentIntoView(i)"
          id="panel-{{ parent.id }}"
          [expanded]="true"
        >
          <mat-expansion-panel-header expandedHeight="48px">
            <mat-panel-title>
              {{ 'FILTER.SERVICE_CAT.' + parent.id | translate }}
            </mat-panel-title>
            <mat-panel-description>
              <span *ngIf="parent.selectedChildCount > 0" class="badge"
                >+{{ parent.selectedChildCount }}</span
              >
            </mat-panel-description>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent class="checkboxes" *ngIf="parentPanel.open">
            <mat-selection-list>
              <ng-container *ngFor="let child of childCategories">
                <mat-list-option
                  *ngIf="child.locationCategoryId === parent.id"
                  [selected]="child.selected"
                  (click)="child.selected = !child.selected; saveSelectedFilters('category')"
                >
                  <div class="option-list-item-container">
                    <div class="icon-container">
                      <fa-icon matListIcon icon="{{ child.icon }}"></fa-icon>
                    </div>
                    <span class="checkbox-label">{{
                      'FILTER.SERVICE_CAT.' + parent.id + '-' + child.id | translate
                    }}</span>
                  </div>
                </mat-list-option>
              </ng-container>
            </mat-selection-list>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <div class="action-btns">
    <button class="clear-btn" mat-button (click)="resetUserSelection()">
      {{ 'services-filter-mobile.button.reset' | translate }}
    </button>
    <button class="close-btn" mat-button (click)="openResults()">
      {{ 'services-filter-mobile.button.search' | translate }}
    </button>
  </div>
</div>
