/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scroll-up.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "./scroll-up.component";
import * as i8 from "../fab.service";
import * as i9 from "@angular/router";
var styles_ScrollUpComponent = [i0.styles];
var RenderType_ScrollUpComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScrollUpComponent, data: {} });
export { RenderType_ScrollUpComponent as RenderType_ScrollUpComponent };
function View_ScrollUpComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "floating up hide-fab"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"], ["icon", "arrow-up"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(2, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = "arrow-up"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("AC.BUTTON.ARROW_UP")); _ck(_v, 4, 0, currVal_2); }); }
export function View_ScrollUpComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { searchComponent: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScrollUpComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showUp; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ScrollUpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-scroll-up", [], null, null, null, View_ScrollUpComponent_0, RenderType_ScrollUpComponent)), i1.ɵdid(1, 245760, null, 0, i7.ScrollUpComponent, [i8.FabService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScrollUpComponentNgFactory = i1.ɵccf("app-scroll-up", i7.ScrollUpComponent, View_ScrollUpComponent_Host_0, {}, {}, []);
export { ScrollUpComponentNgFactory as ScrollUpComponentNgFactory };
