import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { endpoints } from '../../configs/sources';
import { environment } from '../../../environments/environment';

const AZURE_MAPS_KEY = 'ShKg6ldy-yJKk3-lgSxMl_r0CvS5N-dwy6hvC_3l90A';

const addressSearch =
  'https://atlas.microsoft.com/search/address/json' +
  '?&api-version=1.0' +
  `&subscription-key=${AZURE_MAPS_KEY}` +
  '&countrySet=FI' +
  '&language=fi-FI' +
  '&limit=5&query=';

@Injectable({
  providedIn: 'root',
})
export class GeocodingService {
  endpoint = endpoints.geocoding;

  httpOptions = {
    headers: new HttpHeaders({
      'digitransit-subscription-key': environment.digitransitKey,
    }),
  };

  constructor(private http: HttpClient) {}

  getAutocomplete(partial: string): Observable<string[]> {
    if (!partial) return of([]);

    let endpoint = 'https://api.digitransit.fi/geocoding/v1/autocomplete?sources=osm,oa';

    let string = encodeURIComponent(partial);

    return this.http.get(endpoint + '&text=' + string, this.httpOptions).pipe(
      map((data: any) => {
        return data.features.map((f: any) => f.properties.label);
      }),
    );
  }

  getAutocompleteFromAzureMaps(partial: string): Observable<string[]> {
    let string = encodeURIComponent(partial);

    return this.http.get(addressSearch + string).pipe(
      map((data: any) => {
        return data.results.map((f: any) => f.address.freeformAddress);
      }),
    );
  }

  getLocationByAddress(query): Observable<any> {
    return new Observable((observer) => {
      let response;

      const string = encodeURIComponent(query);
      this.http.get(this.endpoint + string, this.httpOptions).subscribe((result: any) => {
        if (result.features[0] !== undefined) {
          response = [
            {
              lat: result.features[0].geometry.coordinates[1],
              lon: result.features[0].geometry.coordinates[0],
              label: result.features[0].properties.label,
              zipcode: result.features[0].properties.postalcode,
            },
          ];
          observer.next(response);
        } else {
          observer.error('Address does not exist');
        }
      });
    });
  }

  getAddressByCoords(latLng): Observable<any> {
    return new Observable((observer) => {
      let response;

      this.http
        .get(
          endpoints.reversegeo + '?size=1&point.lat=' + latLng.lat + '&point.lon=' + latLng.lng,
          this.httpOptions,
        )
        .subscribe((result: any) => {
          if (result.features[0] !== undefined) {
            response = {
              lat: result.features[0].geometry.coordinates[1],
              lon: result.features[0].geometry.coordinates[0],
              address: result.features[0].properties.name,
              zipcode: result.features[0].properties.postalcode,
              city: result.features[0].properties.localadmin,
            };
            observer.next(response);
          } else {
            observer.error('No address found for coordinates.');
          }
        });
    });
  }
}
