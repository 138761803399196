import { Component, OnInit, PLATFORM_ID, Inject, isDevMode, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import fi from '@angular/common/locales/fi';

import { TextsService } from './services/texts/texts.service';
import { MapDataService } from './services/mapdata/mapdata.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import { RoutingState } from './services/routing/routingState';

import { base } from './configs/sources';
import { MunicipalitiesService } from './services/municipalities/municipalities.service';
import { RegionsService } from './services/regions/regions.service';
import { SubregionsService } from './services/subregions/subregions.service';
import { StorageService, SESSION_STORAGE } from 'ngx-webstorage-service';
import { Meta } from '@angular/platform-browser';
import { TokenStorageService } from './services/registration/token-storage.service';
import { AuthService } from './services/registration/auth.service';

import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { UserService } from './services/registration/user.service';
import { HoodsService } from './services/hoods/hoods.service';
import { MatMenuTrigger } from '@angular/material';
import { filter } from 'rxjs/operators';
import { IUser } from './models/IUser';

declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Inject(PLATFORM_ID) platformId: string;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  faUser = faUser;
  faLock = faLock;

  userHoodSlug;

  title = 'Hoods';
  texts;

  lang;

  showDisclaimer = true;

  showFeedback = false;

  pages = [
    {
      title: 'HOME.NAVLINK',
      route: '/home',
      visible: true,
      children: false,
    },
    {
      title: 'RESULTS.NAVLINK',
      route: '/search',
      visible: true,
      children: false,
    },
    {
      title: 'CITIES.NAVLINK',
      route: '',
      visible: true,
      children: true,
      subpages: [
        {
          title: 'Turku',
          route: '/city/turku',
        },
      ],
    },
    {
      title: 'BLOG.NAVLINK',
      route: '/blog',
      visible: true,
      children: false,
    },
    {
      title: 'INFO.NAVLINK',
      route: '',
      visible: true,
      children: true,
      subpages: [
        {
          title: 'INFO.NAVLINK-HOODS',
          route: '/info',
        },
        {
          title: 'INFO.NAVLINK-MAP',
          route: '/info',
        },
      ],
    },
  ];

  navRegions = [];
  currentLang;
  backendServer = base;
  userHoodId: number;

  showDebug: boolean = false;

  constructor(
    private mapdata: MapDataService,
    private router: Router,
    public translate: TranslateService,
    public strings: TextsService,
    public tooltipConfig: NgbTooltipConfig,
    public municipalityService: MunicipalitiesService,
    public regionsService: RegionsService,
    public subregionsService: SubregionsService,
    routingState: RoutingState,
    private tokenStorage: TokenStorageService,
    private authService: AuthService,
    private meta: Meta,
    private userService: UserService,
    public hoodsService: HoodsService,
    @Inject(SESSION_STORAGE) private storage: StorageService,
  ) {
    this.adminAccess();

    if (isDevMode()) {
      this.showDebug = true;
    }

    // Updates nav menu and draft rights when reloading the browser tab
    let isUserTest = this.tokenStorage.getTestUser();

    if (isUserTest == 'true') {
      this.router.events
        .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
        .subscribe((event) => {
          if (event.id === 1 && event.url === event.urlAfterRedirects) {
            this.hoodsService.setShowDrafts(true);
            this.municipalityService.setShowDrafts(true);
            this.regionsService.setShowDrafts(true);
            this.subregionsService.setShowDrafts(true);
            this.loadNavData();
          }
        });
    } else {
      this.hoodsService.setShowDrafts(false);
      this.municipalityService.setShowDrafts(false);
      this.regionsService.setShowDrafts(false);
      this.subregionsService.setShowDrafts(false);
      this.loadNavData();
    }

    // Updates nav menu municipality list with drafts after user signs in
    authService.isUserTester.subscribe((showDrafts: boolean) => {
      if (showDrafts === true) {
        this.loadNavData();
      } else {
        this.hoodsService.setShowDrafts(false);
        this.municipalityService.setShowDrafts(false);
        this.regionsService.setShowDrafts(false);
        this.subregionsService.setShowDrafts(false);
        this.loadNavData();
      }
    });

    if (translate.currentLang.length == 2) {
      this.currentLang = translate.currentLang;
    }

    translate.onLangChange.subscribe(() => {
      if (translate.currentLang.length == 2) {
        this.currentLang = translate.currentLang;
      }
    });

    translate.setDefaultLang('fi');
    translate.addLangs(this.strings.getAvailableLanguages());

    // this.refreshTranslations();

    routingState.loadRouting();

    //tooltipConfig.placement = 'top';
    tooltipConfig.triggers = 'click';

    // set fallback language

    // Fetch language from session preferences
    this.mapdata.userPreferences.subscribe((preferences) => {
      if (preferences !== undefined && preferences.language !== undefined) {
        this.lang = preferences.language;
        // Select initial language
        translate.use(this.lang);
        // this.refreshTranslations();
      }
    });

    // Refresh translations every second
    // TODO: Replace with a more sophisticated server side solution
    // setInterval(() => {
    //  this.refreshTranslations();
    // }, 1000);

    this.mapdata.refreshPreferences();

    // Comment this out if you want to translate by browser language
    //const browserLang = translate.getBrowserLang();
    //translate.use(browserLang.match(/en|fi/) ? browserLang : 'en');

    // Capture analytics from router
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let url = event.urlAfterRedirects.split('/');
        if (url[1] == 'search' || url[2] == 'search') {
          this.meta.updateTag({ name: 'robots', content: 'noindex, follow' });
        } else {
          this.meta.updateTag({ name: 'robots', content: 'index, follow' });
        }

        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects,
        });

        if ((<any>window).Cookiebot.consent.marketing) {
          fbq('track', 'PageView');
        }

        // Adjusted bounce rate
        setTimeout(() => {
          gtag('event', '30_seconds', {
            page_path: event.urlAfterRedirects,
          });
        }, 30000);
      }
    });

    this.mapdata.userPreferences.subscribe((preferences) => {
      if (preferences !== undefined) {
        this.showDisclaimer = !preferences.acceptTerms;
      }
    });

    this.mapdata.refreshPreferences();

    // this.metaService.updateTitle();
  }

  async ngOnInit() {
    registerLocaleData(fi);
    this.getHoods();
    this.userHoodId = await this.getUserHood();
    if (this.userHoodId) {
      this.userHoodSlug = await this.getHoodSlug(this.userHoodId);
    }
  }

  private async loadNavData() {
    const [regions, subRegions, municipalities] = await Promise.all([
      this.getRegions(), //
      this.getSubregions(),
      this.getMunicipalities(),
    ]);

    this.navRegions = this.treeify(municipalities, subRegions, regions);
    this.pages[3].subpages = this.navRegions;
  }

  viewAfterInit() {
    // this.refreshTranslations();
  }

  refreshTranslations() {
    this.texts = this.strings.parseStrings().then(
      (resolve) => {
        //console.log("refreshing translations");

        let translations = resolve;

        // Fetch the languages
        this.strings.getAvailableLanguages().forEach((key) => {
          this.translate.setTranslation(key, translations[key]);
        });

        if (this.mapdata.checkLanguage()) {
          this.translate.use(this.mapdata.getLanguage());
        } else {
          this.translate.use(this.mapdata.preferences.language);
        }

        this.currentLang = this.translate.currentLang;
      },
      (reject) => {
        console.log('Failed to load translations');
      },
    );
  }

  clearCache() {
    this.storage.clear();
    window.location.reload();
  }

  changeLang(lang) {
    let url = this.router.url.split('/');
    const supportedLanguages = this.strings.getAvailableLanguages();

    if (url[1] === lang) {
      this.mapdata.saveLanguage(lang);
      return;
    }
    if (url[1] === 'map') {
      // language missing from url if just map page. Then add it
      url.unshift('');
    }
    if (supportedLanguages.includes(lang)) {
      url[1] = lang;
    } else {
      url[0] = lang;
    }
    console.log(url);
    this.mapdata.saveLanguage(lang);
    this.router.navigateByUrl('/' + url.join('/'));
    //this.translate.use(lang);
  }

  termsAccepted() {
    this.mapdata.acceptTerms();
    this.showDisclaimer = false;
  }

  navigate(page) {
    //console.log(page);
  }

  toggleBubble() {
    this.showFeedback = !this.showFeedback;
  }

  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }

  private async getRegions() {
    const data = await this.regionsService.getRegions().toPromise();
    const ret = [];

    data.regions.forEach((element) => {
      ret.push({
        id: 'r' + element.id, // added string to id to make parent/child references easier
        parentId: 0,
        title: element.name,
        slug: element.slug,
        route: '/',
        children: true,
        subpages: [],
      });
    });
    return ret;
  }

  private async getSubregions() {
    const data = await this.subregionsService.getSubregions().toPromise();
    const ret = [];
    data.subRegions.forEach((element) => {
      ret.push({
        id: 's' + element.id, // added string to id to make parent/child references easier
        parentId: 'r' + element.regionId, // added string to id to make parent/child references easier
        title: element.name,
        slug: element.slug,
        route: '/',
        children: true,
        subpages: [],
      });
    });

    return ret;
  }

  private async getMunicipalities() {
    const data = await this.municipalityService.getMunicipalities(true).toPromise();
    return data.municipalities.map((m) => ({
      id: 'm' + m.id, // added string to id to make parent/child references easier
      parentId: 's' + m.subRegionId, // added string to id to make parent/child references easier
      title: m.name,
      slug: m.slug,
      hasHoods: m.hasHoods,
    }));
  }

  private getHoods() {
    this.hoodsService.getHoodsFromCountry().subscribe((data) => {
      this.hoodsService.updateHoodsSubject(data);
    });
  }

  getCurrentLocale(currentLanguage: string): 'fi' | 'en' | 'sv' {
    // Update switch cases if language abbreviations are modified
    switch (currentLanguage) {
      case 'fi':
        return 'fi';
      case 'en':
        return 'en';
      case 'sv':
        return 'sv';
      default:
        return 'fi';
    }
  }

  sortAlphabetically(area: any) {
    let locale = this.getCurrentLocale(this.currentLang);
    area.sort((a, b) => {
      if (!a.title[this.currentLang]) {
        // Default to fi because of some missing translations
        return a.title['fi'].localeCompare(b.title['fi'], 'fi');
      }
      return a.title[this.currentLang].localeCompare(b.title[this.currentLang], locale);
    });
    return area;
  }

  private treeify(municipalities, subRegions, regions) {
    for (const muni of municipalities) {
      for (const subRegion of subRegions) {
        if (muni.parentId !== subRegion.id) continue;
        for (const region of regions) {
          if (subRegion.parentId !== region.id) continue;
          region.subpages.push({
            title: muni.title,
            route: '/city/' + muni.slug,
            hasHoods: muni.hasHoods,
          });
        }
      }
    }
    return regions;
  }

  isLoggedIn() {
    return !!this.tokenStorage.getToken();
  }

  logout() {
    this.authService.logoutUser();
    this.router.navigate([(this.currentLang ? '/' + this.currentLang : '') + '/home']);
    this.menuTrigger.closeMenu();
  }

  getUserHood(): Promise<number> {
    const user: IUser = this.tokenStorage.getCurrentUser();
    if (!user) {
      return;
    }
    return new Promise((resolve) => {
      this.userService.getUserDetailsById(user.id).subscribe((data) => {
        const userHood = data.details.hood;
        resolve(userHood);
      });
    });
  }

  async getHoodSlug(id: number) {
    return new Promise((resolve) => {
      this.hoodsService.getHood(id).subscribe((data) => {
        const dataHood = data['hood'];
        const userHoodSlug = {
          hood: dataHood.slug,
          municipality: dataHood.municipality.slug,
        };
        resolve(userHoodSlug);
      });
    });
  }

  async gotoUserHood() {
    this.router.navigate([
      (this.currentLang ? '/' + this.currentLang : '') +
        '/hood/' +
        this.userHoodSlug.municipality +
        '/',
      this.userHoodSlug.hood,
    ]);
  }

  adminAccess() {
    // Login and register pages always have access
    const url = window.location.href;
    if (url.includes('login') || url.includes('register') || url.includes('verify')) return;

    let currentUser = this.tokenStorage.getCurrentUser();

    let host = window.location.host;
    let params = new URLSearchParams(window.location.search);
    let mode = params.get('mode');
    let token = params.get('token');
    if (host === 'beta.hoods.fi') {
      console.log('You are veiwing in development mode');
      if (!currentUser) {
        if (mode === 'test' && token === 'xyz') {
          console.log('Access granted');
          return;
        } else {
          this.router.navigate(['/login']);
        }
      }
    }
  }
}
