import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'ngx-webstorage-service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { GeocodingService } from '../geocoding/geocoding.service';
import { HoodsService } from '../hoods/hoods.service';
import { LocationsService } from '../locations/locations.service';
import { MunicipalitiesService } from '../municipalities/municipalities.service';
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage-service";
import * as i2 from "../geocoding/geocoding.service";
import * as i3 from "../hoods/hoods.service";
import * as i4 from "../locations/locations.service";
import * as i5 from "../municipalities/municipalities.service";
import * as i6 from "@angular/router";
var RESULTS = 'search_results';
var POINTS = 'custom_points';
var FILTERS = 'search_filters';
var PREFERENCES = 'user_preferences';
var SLUGS = 'hoods_slugs';
var STORAGE_MUNICIPALITIES = 'municipalities_1';
var SERVICE_CATS = 'service_cats';
var SERVICE_TYPES = 'service_types';
var HOOD_SERVICE_POINTS = 'hood_services';
var MapDataService = /** @class */ (function () {
    function MapDataService(storage, geocode, hoodsService, locationService, municipalities, router) {
        var _this = this;
        this.storage = storage;
        this.geocode = geocode;
        this.hoodsService = hoodsService;
        this.locationService = locationService;
        this.municipalities = municipalities;
        this.router = router;
        this.alwaysSelectedTypes = [
            // add typeIds to always show locations within bounding box
            21,
            24,
        ];
        this.defaultLocations = [
            {
                name: 'Kaarinan keskusta',
                address: '',
                location: {
                    lat: 60.4084384,
                    lon: 22.3659232,
                },
            },
            {
                name: 'Turun keskusta',
                address: 'Kauppatori, 20100 Turku',
                location: {
                    lat: 60.4519743,
                    lon: 22.2661993,
                },
            },
        ];
        this.locations = [];
        this.searchpoint = {
            lat: 0,
            lon: 0,
            distance: 0,
            label: '',
        };
        this.hoods = [];
        this.hood = 7;
        this.currentRegion = 1;
        this.region = {
            averageSalesPrice: 2090,
            averageRentPrice: 12.2,
        };
        this.sortingField = 'name';
        this.sortingDirection = 'ascending';
        this.preferences = {
            acceptTerms: false,
            language: 'fi',
            area: 'hood',
        };
        this.hoodResults = new BehaviorSubject(this.hoods);
        this.savedLocations = new BehaviorSubject(this.locations);
        this.searchPoint = new BehaviorSubject(this.searchpoint);
        this.selectedHood = new BehaviorSubject(this.hood);
        this.userPreferences = new BehaviorSubject(this.preferences);
        this.filterRefreshed = new BehaviorSubject('city');
        this.currentFilter = this.filterRefreshed.asObservable();
        this.servicePointTypesSubject = new ReplaySubject(1);
        this.servicePointTypes$ = this.servicePointTypesSubject.asObservable();
        this.locations.forEach(function (location) {
            _this.getLocation(location.address).then(function (loc) {
                location.location = loc;
            });
        });
        window.locations = this.locations;
        this.getLocations();
        this.getHoods();
        this.initServicePointTypes();
        // console.log('mapdata init');
        this.hoods = this.storage.get(RESULTS);
    }
    MapDataService.prototype.getAlwaysSelectedTypes = function () {
        return this.alwaysSelectedTypes;
    };
    MapDataService.prototype.ngOnInit = function () { };
    //---- IndexedDB operations
    MapDataService.prototype.getConnection = function () {
        return new Promise(function (resolve, reject) {
            var request = indexedDB.open('hoodsDb', 2);
            request.onerror = function (event) {
                var request = event.target;
                console.error('IndexedDB error', request.error);
                reject(request.error);
            };
            // DB version has changed
            request.onupgradeneeded = function () {
                var db = request.result;
                // Create an object store for results if it does not exist
                if (!db.objectStoreNames.contains(RESULTS)) {
                    db.createObjectStore(RESULTS, { keyPath: 'id' });
                }
                if (!db.objectStoreNames.contains('service_points')) {
                    db.createObjectStore('service_points', { keyPath: 'id', autoIncrement: true });
                }
            };
            request.onsuccess = function () {
                // onSuccess event result is the db instance
                var db = request.result;
                /* console.log('IndexedDB connection opened succesfully.', db); */
                // DB version has changed, close previous connection
                db.onversionchange = function () {
                    db.close();
                    // location.reload();
                };
                // Return db instance
                resolve(db);
            };
        });
    };
    MapDataService.prototype.hoodToDb = function (object) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var db, transaction, store;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getConnection()];
                    case 1:
                        db = _a.sent();
                        transaction = db.transaction(RESULTS, 'readwrite');
                        store = transaction.objectStore(RESULTS);
                        store.add(object);
                        return [2 /*return*/];
                }
            });
        });
    };
    MapDataService.prototype.hoodsFromDb = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var db, transaction, store, request;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getConnection()];
                    case 1:
                        db = _a.sent();
                        transaction = db.transaction(RESULTS, 'readonly');
                        store = transaction.objectStore(RESULTS);
                        request = store.getAll();
                        request.onsuccess = function () {
                            // console.log("Get all results", request.result);
                            return request.result;
                        };
                        request.onerror = function () {
                            console.error('Error', request.error);
                        };
                        return [2 /*return*/];
                }
            });
        });
    };
    MapDataService.prototype.clearDb = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var req;
            return tslib_1.__generator(this, function (_a) {
                req = indexedDB.deleteDatabase('hoodsDb');
                req.onsuccess = function () { return console.log('Deleted database successfully'); };
                req.onerror = function () { return console.log("Couldn't delete database"); };
                req.onblocked = function () {
                    return console.log("Couldn't delete database due to the operation being blocked");
                };
                return [2 /*return*/];
            });
        });
    };
    // ---- END IndexedDB operations
    // SEARCH RESULTS
    MapDataService.prototype.hoodToLocalStorage = function (object) {
        // Save hood to db
        if (object !== undefined) {
            this.hoodToDb(object);
            this.hoods.push(object);
            return;
        }
        else
            return;
        /* delete object.country;
    
        const currentItems = this.storage.get(RESULTS) || [];
    
        if (object === undefined || currentItems === undefined) {
          return;
        } else {
    
          currentItems.push(object);
          this.hoods.push(object);
    
          try {
            this.storage.set(RESULTS, currentItems);
          } catch (error) {
            console.log(error);
            return;
          }
    
        } */
    };
    MapDataService.prototype.addHood = function (object) {
        this.hoodToLocalStorage(object);
    };
    MapDataService.prototype.saveSlug = function (id, slug) {
        var slugs = [];
        if (this.storage.has(SLUGS)) {
            slugs = this.storage.get(SLUGS);
        }
        slugs.push({
            id: id,
            slug: slug,
        });
        this.storage.set(SLUGS, slugs);
    };
    MapDataService.prototype.refreshHoods = function () {
        this.hoodResults.next(this.hoods);
        window.hoods = this.hoods;
        return;
        /* if (this.hoodsResultsExist()) {
          this.hoods = this.storage.get(RESULTS);
          this.hoodResults.next(this.hoods);
        }
        else {
          this.hoodResults.next(this.hoods);
        }
        (<any>window).hoods = this.hoods; */
    };
    MapDataService.prototype.hoodsResultsExist = function () {
        return this.storage.has(RESULTS);
    };
    MapDataService.prototype.getHoods = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.hoodsFromDb()];
                    case 1:
                        _a.hoods = _b.sent();
                        this.refreshHoods();
                        return [2 /*return*/];
                }
            });
        });
    };
    MapDataService.prototype.generateSlugs = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.hoodsService.getSlugs().subscribe(function (res) {
                var slugs = res.hoods.map(function (hood) {
                    return {
                        id: hood.id,
                        slug: hood.slug,
                    };
                });
                _this.storage.set(SLUGS, slugs);
                resolve(_this.storage.get(SLUGS));
            });
        });
    };
    MapDataService.prototype.getHoodSlugs = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.storage.get(SLUGS) !== undefined) {
                resolve(_this.storage.get(SLUGS));
            }
            else {
                _this.generateSlugs().then(function (slugs) {
                    resolve(slugs);
                });
            }
        });
    };
    MapDataService.prototype.clearHoods = function () {
        this.clearDb();
        // this.storage.remove(RESULTS);
        this.storage.remove(SLUGS);
        this.hoods = [];
        this.refreshHoods();
    };
    // CUSTOM MAP POINTS
    MapDataService.prototype.pointToLocalStorage = function (object) {
        var currentItems = this.storage.get(POINTS) || [];
        currentItems.push(object);
        this.storage.set(POINTS, currentItems);
        this.refreshLocations();
    };
    MapDataService.prototype.getLocation = function (address) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.geocode.getLocationByAddress(address).subscribe(function (data) {
                resolve({
                    lat: data[0].lat,
                    lon: data[0].lon,
                });
            });
        });
    };
    MapDataService.prototype.getLocations = function () {
        if (this.storage.has(POINTS)) {
            this.locations = this.storage.get(POINTS);
        }
        else {
            this.storage.set(POINTS, this.locations);
        }
        this.refreshLocations();
    };
    MapDataService.prototype.refreshLocations = function (getDefaults) {
        if (getDefaults === void 0) { getDefaults = false; }
        if (this.storage.has(POINTS) && !getDefaults) {
            this.locations = this.storage.get(POINTS);
            this.savedLocations.next(this.storage.get(POINTS));
        }
        else if (getDefaults) {
            // console.log(this.defaultLocations);
            this.savedLocations.next(this.defaultLocations);
        }
        else {
            this.savedLocations.next(this.locations);
        }
    };
    MapDataService.prototype.searchPointToLocalStorage = function (object) {
        this.storage.set('searchpoint', object);
        this.refreshSearchPoint();
    };
    MapDataService.prototype.refreshSearchPoint = function () {
        if (this.storage.has('searchpoint')) {
            this.searchpoint = this.storage.get('searchpoint');
            this.searchPoint.next(this.storage.get('searchpoint'));
        }
        else {
            this.searchPoint.next(this.searchpoint);
        }
    };
    MapDataService.prototype.clearSearchPoint = function () {
        this.searchpoint = {
            lat: 0,
            lon: 0,
            distance: 0,
            label: '',
        };
        this.searchPointToLocalStorage(this.searchpoint);
    };
    MapDataService.prototype.showPoint = function (object) {
        this.searchPointToLocalStorage(object);
        this.searchpoint = object;
        this.refreshSearchPoint();
    };
    MapDataService.prototype.addLocation = function (object) {
        this.pointToLocalStorage(object);
        this.locations.push(object);
        this.refreshLocations();
    };
    MapDataService.prototype.removeLocation = function (index) {
        var currentItems = this.storage.get(POINTS) || [];
        currentItems.splice(index, 1);
        this.storage.set(POINTS, currentItems);
        this.refreshLocations();
    };
    MapDataService.prototype.clearLocations = function () {
        this.storage.remove(POINTS);
        this.refreshLocations();
    };
    MapDataService.prototype.changeHood = function (id) {
        this.selectedHood.next(id);
        this.hood = id;
    };
    MapDataService.prototype.saveFilters = function (filters) {
        this.storage.set(FILTERS, filters);
    };
    MapDataService.prototype.loadFilters = function () {
        return this.storage.get(FILTERS);
    };
    MapDataService.prototype.refreshPreferences = function () {
        var prefs = this.storage.get(PREFERENCES);
        this.userPreferences.next(prefs);
    };
    MapDataService.prototype.saveLanguage = function (code) {
        // console.log('Saving language to session.');
        console.log(code);
        if (code.length !== 2) {
            this.router.navigate(['not-found']);
            return;
        }
        if (this.storage.has(PREFERENCES)) {
            var prefs = this.storage.get(PREFERENCES);
            if (prefs !== undefined) {
                prefs['language'] = code;
            }
            else {
                prefs = { language: code };
            }
            this.storage.set(PREFERENCES, prefs);
            this.refreshPreferences();
        }
        else {
            this.storage.set(PREFERENCES, { language: code });
            this.refreshPreferences();
        }
    };
    MapDataService.prototype.checkLanguage = function () {
        if (this.storage.has(PREFERENCES)) {
            var prefs = this.storage.get(PREFERENCES);
            if (prefs['language'] !== undefined) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    MapDataService.prototype.getLanguage = function () {
        var prefs = this.storage.get(PREFERENCES);
        return prefs['language'];
    };
    MapDataService.prototype.acceptTerms = function () {
        if (this.storage.has(PREFERENCES)) {
            var prefs = this.storage.get(PREFERENCES);
            if (prefs !== undefined) {
                prefs['acceptTerms'] = true;
            }
            else {
                prefs = { acceptTerms: true };
            }
            this.storage.set(PREFERENCES, prefs);
            this.refreshPreferences();
        }
        else {
            this.storage.set(PREFERENCES, { acceptTerms: true });
            this.refreshPreferences();
        }
    };
    MapDataService.prototype.savePreference = function (id, value) {
        var _a;
        var prefs = this.storage.get(PREFERENCES);
        if (prefs !== undefined) {
            prefs[id] = value;
        }
        else {
            prefs = (_a = {}, _a[id] = value, _a);
        }
        this.storage.set(PREFERENCES, prefs);
        // console.log('preferences saved');
        this.userPreferences.next(prefs);
    };
    MapDataService.prototype.getPreference = function (id) {
        if (this.storage.has(PREFERENCES)) {
            return this.storage.get(PREFERENCES)[id];
        }
        else {
            return '';
        }
    };
    MapDataService.prototype.getPreferences = function () {
        return this.userPreferences.asObservable();
    };
    // MUNICIPALITY FUNCTIONS
    MapDataService.prototype.getMunicipalities = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.storage.has(STORAGE_MUNICIPALITIES)) {
                var item = _this.storage.get(STORAGE_MUNICIPALITIES);
                if (new Date(item.expirationDate) < new Date())
                    return item.list;
            }
            _this.municipalities.getMunicipalities().subscribe(function (result) {
                var item = {
                    list: result.municipalities,
                    expirationDate: new Date(),
                };
                item.expirationDate.setDate(item.expirationDate.getDate() + 1);
                _this.storage.set(STORAGE_MUNICIPALITIES, item);
                resolve(result.municipalities);
            });
        });
    };
    // SERVICE POINT FUNCTIONS
    MapDataService.prototype.getServicePointCats = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cats;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.storage.has(SERVICE_CATS)) {
                            return [2 /*return*/, this.storage.get(SERVICE_CATS)];
                        }
                        return [4 /*yield*/, this.locationService.getCategories().toPromise()];
                    case 1:
                        cats = _a.sent();
                        this.storage.set(SERVICE_CATS, cats.locationCategories);
                        return [2 /*return*/, cats.locationCategories];
                }
            });
        });
    };
    MapDataService.prototype.initServicePointTypes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var types;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.storage.has(SERVICE_TYPES)) {
                            this.servicePointTypesSubject.next(this.storage.get(SERVICE_TYPES));
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.locationService.getTypes().toPromise()];
                    case 1:
                        types = _a.sent();
                        // Enable the naapurustosuunnistus campaign category
                        types.locationTypes.forEach(function (type) {
                            if (_this.alwaysSelectedTypes.includes(type.id)) {
                                type.selected = true;
                            }
                        });
                        // Enable the events campaign category
                        // types.locationTypes[21]['selected'] = true;
                        // Remove majoitus. See tickets 11237, 12277
                        types.locationTypes = types.locationTypes.filter(function (type) { return type.id !== 5; });
                        this.storage.set(SERVICE_TYPES, types.locationTypes);
                        this.servicePointTypesSubject.next(this.storage.get(SERVICE_TYPES));
                        return [2 /*return*/];
                }
            });
        });
    };
    MapDataService.prototype.saveServicePointTypes = function (array) {
        this.storage.set(SERVICE_TYPES, array);
        this.servicePointTypesSubject.next(array);
    };
    MapDataService.prototype.getServicePoints = function (area, bbox, simplified) {
        var _this = this;
        if (bbox === void 0) { bbox = undefined; }
        if (simplified === void 0) { simplified = false; }
        return new Promise(function (resolve, reject) {
            if (area === 'hood') {
                if (_this.storage.has(HOOD_SERVICE_POINTS)) {
                    resolve([_this.storage.get(HOOD_SERVICE_POINTS)]);
                }
            }
            if (area !== 'city') {
                console.error("Invalid area " + area);
                reject('error');
                return;
            }
            if (_this.servicePointRequest && !_this.servicePointRequest.closed) {
                _this.cancelServicePoints();
            }
            _this.servicePointRequest = _this.locationService
                .getLocationsBbox(bbox, simplified)
                .subscribe(function (result) {
                if (!result.locations)
                    resolve([]);
                resolve(result.locations);
            });
        });
    };
    MapDataService.prototype.cancelServicePoints = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.servicePointRequest.unsubscribe();
                return [2 /*return*/];
            });
        });
    };
    MapDataService.prototype.saveServicePoints = function (area, array) {
        // if (area == 'city') {
        //   this.storage.set(SERVICE_POINTS, array);
        // }
        if (area == 'hood') {
            this.storage.set(HOOD_SERVICE_POINTS, array);
        }
    };
    MapDataService.ngInjectableDef = i0.defineInjectable({ factory: function MapDataService_Factory() { return new MapDataService(i0.inject(i1.SESSION_STORAGE), i0.inject(i2.GeocodingService), i0.inject(i3.HoodsService), i0.inject(i4.LocationsService), i0.inject(i5.MunicipalitiesService), i0.inject(i6.Router)); }, token: MapDataService, providedIn: "root" });
    return MapDataService;
}());
export { MapDataService };
