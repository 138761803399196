<div class="popup-dialog-card">
  <!-- TITLE SECTION -->

  <div class="dialog-card-title">
    <!-- <div mat-dialog-title></div> -->
    <div class="dialog-title-text">
      <i style="font-size: 1.3em; margin-right: 0.8em" class="fas fa-{{ icon }}"></i>
      <div>
        <div class="dialog-title-name">{{ name }}</div>
        <ng-container *ngIf="isLotContent">
          <div class="dialog-title-type">{{ getLotTypes(selectedLot.lotTypes) }}</div>
        </ng-container>
      </div>
    </div>
    <button mat-button (click)="closeDialog()">
      <i class="fas fa-times close-button"></i>
      <span class="sr-only">{{ 'AC.BUTTON.CLOSE' | translate }}</span>
    </button>
  </div>

  <!-- CONTENT SECTION -->

  <div class="dialog-container">
    <app-lot-content *ngIf="isLotContent" [data]="data"></app-lot-content>
    <app-service-content *ngIf="isServiceContent" [data]="data"></app-service-content>
  </div>

  <!-- ACTION BUTTON SECTION -->

  <ng-container *ngIf="reserveUrl && isLotContent">
    <div class="go-to-button">
      <a class="dialog-link" mat-dialog-close href="{{ reserveUrl }}" target="_blank">
        {{ 'OPEN_RESERVATIONS' | translate }}
      </a>
      {{ lotTranslation.linkDescription ? lotTranslation.linkDescription : '' }}
    </div>
  </ng-container>

  <!-- <ng-container *ngIf="osmUrl && isServiceContent">
    <div class="go-to-button">
      <a class="dialog-link" mat-dialog-close href="{{ osmUrl }}" target="_blank">
        {{ 'LOCATIONS.GO_TO_OSM_MAP' | translate }}
      </a>
    </div>
  </ng-container> -->
</div>
