import { HostListener, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Location } from '../../services/locations/locations.model';
import { BehaviorSubject, Subject } from 'rxjs';

export interface FeatureCollection {
  type: 'FeatureCollection';
  features: Feature[];
}

export interface Feature {
  type: string;
  id: number;
  geometry: {
    type: string;
    coordinates: number[];
  };
  properties: {
    title: string;
    categories: {
      id: number;
      icon: string;
    }[];
  };
}

export enum AreaFeatureType {
  Polygon = 'Polygon',
  MultiPolygon = 'MultiPolygon',
}

export type GeoJsonObjectTypes =
  | 'FeatureCollection'
  | 'Feature'
  | 'Point'
  | 'MultiPoint'
  | 'LineString'
  | 'MultiLineString'
  | 'Polygon'
  | 'MultiPolygon'
  | 'GeometryCollection';

export interface AreaProperties {
  id: number;
  area: {
    geometry: {
      type: AreaFeatureType;
      coordinates: [number[]];
    };
    properties: any;
    type: GeoJsonObjectTypes;
  };
  color: string;
}

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  private isItemDetailsOpen = new BehaviorSubject<boolean>(false);
  public isItemDetailsOpen$ = this.isItemDetailsOpen.asObservable();

  private isMobileMapOpen = new Subject<boolean>();
  public isMobileMapOpen$ = this.isMobileMapOpen.asObservable();

  private areaProperties = new BehaviorSubject<AreaProperties>(null);
  public areaProperties$ = this.areaProperties.asObservable();

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    if (window.innerWidth <= 768) {
      this.isMobile = true;
    }
  }
  public isMobile = false;

  public showMunicipalityServices = false;

  constructor(public dialog: MatDialog) {
    this.getScreenSize();
  }

  public setIsMobileMapOpen(state: boolean) {
    this.isMobileMapOpen.next(state);
  }

  public setIsItemDetailsOpen(state: boolean) {
    this.isItemDetailsOpen.next(state);
  }

  public setAreaProperties(area: AreaProperties) {
    this.areaProperties.next(area);
  }

  public setShowMunicipalityServices(show: boolean) {
    this.showMunicipalityServices = show;
  }

  public getShowMunicipalityServices() {
    return this.showMunicipalityServices;
  }

  public closeDialog() {
    this.dialog.closeAll();
  }

  public mapLocationToFeatureCollection(data: Location[]): FeatureCollection {
    const featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };
    for (const service of data) {
      if (!service.coordinates) {
        continue;
      }
      const feature = {
        type: 'Feature',
        id: service.id,
        geometry: {
          type: 'Point',
          coordinates: [service.coordinates[0], service.coordinates[1]],
        },
        properties: {
          title: service.name,
          categories: service.categories,
        },
      };
      featureCollection.features.push(feature);
    }
    return featureCollection;
  }
}
