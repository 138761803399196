import { Routes } from '@angular/router';
import { NotFoundComponent } from './components/not-found/not-found.component';
var ɵ0 = {
    title: 'Know Your Hoods',
    description: '',
}, ɵ1 = {
    title: 'Know Your Hoods',
    description: '',
}, ɵ2 = {
    title: 'Know Your Hoods',
    description: '',
}, ɵ3 = {
    title: 'Sisältö',
    description: '',
}, ɵ4 = {
    title: 'Sisältö',
    description: '',
}, ɵ5 = {
    title: 'Tietoa palvelusta',
    description: '',
}, ɵ6 = {
    title: 'Tietoa palvelusta',
    description: '',
}, ɵ7 = {
    title: 'Tietoa palvelusta',
    description: '',
}, ɵ8 = {
    title: 'Tietoa palvelusta',
    description: '',
}, ɵ9 = {
    title: 'Kunta',
    description: '',
}, ɵ10 = {
    title: 'Kunta',
    description: '',
}, ɵ11 = {
    title: 'Kunta',
    description: '',
}, ɵ12 = {
    title: 'Kunta',
    description: '',
}, ɵ13 = {
    title: 'Kunta',
    description: '',
}, ɵ14 = {
    title: 'Kunta',
    description: '',
}, ɵ15 = {
    title: 'Kunta',
    description: '',
}, ɵ16 = {
    title: 'Kunta',
    description: '',
}, ɵ17 = {
    title: 'Kunta',
    description: '',
}, ɵ18 = {
    title: 'Kunta',
    description: '',
}, ɵ19 = {
    title: 'Kunta',
    description: '',
}, ɵ20 = {
    title: 'Kunta',
    description: '',
}, ɵ21 = {
    title: 'Hae unelmiesi naapurustoa',
    description: 'Know Your Hoods auttaa löytämään sinulle sopivimman asuinalueen.',
}, ɵ22 = {
    title: 'Hae unelmiesi naapurustoa',
    description: 'Know Your Hoods auttaa löytämään sinulle sopivimman asuinalueen.',
}, ɵ23 = {
    title: 'Kartta',
    description: '',
}, ɵ24 = {
    title: 'Kartta',
    description: '',
}, ɵ25 = {
    title: 'Kartta',
    description: '',
}, ɵ26 = {
    title: 'Kartta',
    description: '',
}, ɵ27 = {
    title: 'Aluesivu',
    description: '',
}, ɵ28 = {
    title: 'Aluesivu',
    description: '',
}, ɵ29 = {
    title: 'Blogi',
    description: '',
}, ɵ30 = {
    title: 'Blogi',
    description: '',
}, ɵ31 = {
    title: 'Blogi',
    description: '',
}, ɵ32 = {
    title: 'Blogi',
    description: '',
}, ɵ33 = {
    title: 'Login',
    description: '',
}, ɵ34 = {
    title: 'Login',
    description: '',
}, ɵ35 = {
    title: 'Register',
    description: '',
}, ɵ36 = {
    title: 'Register',
    description: '',
}, ɵ37 = {
    title: 'Register',
    description: '',
}, ɵ38 = {
    title: 'Register',
    description: '',
}, ɵ39 = {
    title: 'Sivua ei löydy',
    description: 'Valitettavasti hakemaasi sivua ei löydy.',
}, ɵ40 = {
    title: 'Know Your Hoods',
    description: '',
}, ɵ41 = {
    title: 'Know Your Hoods',
    description: '',
}, ɵ42 = {
    title: 'Sivua ei löydy',
    description: 'Valitettavasti hakemaasi sivua ei löydy.',
};
var routes = [
    {
        path: '',
        loadChildren: './modules/landing/landing.module#LandingModule',
        pathMatch: 'full',
        data: ɵ0,
    },
    {
        path: 'home',
        loadChildren: './modules/landing/landing.module#LandingModule',
        data: ɵ1,
    },
    {
        path: ':lang/home',
        loadChildren: './modules/landing/landing.module#LandingModule',
        data: ɵ2,
    },
    {
        path: 'content',
        loadChildren: './modules/content/content.module#ContentModule',
        data: ɵ3,
    },
    {
        path: ':lang/content',
        loadChildren: './modules/content/content.module#ContentModule',
        data: ɵ4,
    },
    {
        path: 'info',
        loadChildren: './modules/content/content.module#ContentModule',
        data: ɵ5,
    },
    {
        path: ':lang/info',
        loadChildren: './modules/content/content.module#ContentModule',
        data: ɵ6,
    },
    {
        path: 'info/:id',
        loadChildren: './modules/content/content.module#ContentModule',
        data: ɵ7,
    },
    {
        path: ':lang/info/:id',
        loadChildren: './modules/content/content.module#ContentModule',
        data: ɵ8,
    },
    {
        path: 'region',
        loadChildren: './modules/region/region.module#RegionModule',
        data: ɵ9,
    },
    {
        path: ':lang/region',
        loadChildren: './modules/region/region.module#RegionModule',
        data: ɵ10,
    },
    {
        path: 'region/:id',
        loadChildren: './modules/region/region.module#RegionModule',
        data: ɵ11,
    },
    {
        path: ':lang/region/:id',
        loadChildren: './modules/region/region.module#RegionModule',
        data: ɵ12,
    },
    {
        path: 'municipality',
        loadChildren: './modules/region/region.module#RegionModule',
        data: ɵ13,
    },
    {
        path: ':lang/municipality',
        loadChildren: './modules/region/region.module#RegionModule',
        data: ɵ14,
    },
    {
        path: 'municipality/:id',
        loadChildren: './modules/region/region.module#RegionModule',
        data: ɵ15,
    },
    {
        path: ':lang/municipality/:id',
        loadChildren: './modules/region/region.module#RegionModule',
        data: ɵ16,
    },
    {
        path: 'city',
        loadChildren: './modules/region/region.module#RegionModule',
        data: ɵ17,
    },
    {
        path: ':lang/city',
        loadChildren: './modules/region/region.module#RegionModule',
        data: ɵ18,
    },
    {
        path: 'city/:slug',
        loadChildren: './modules/region/region.module#RegionModule',
        data: ɵ19,
    },
    {
        path: ':lang/city/:slug',
        loadChildren: './modules/region/region.module#RegionModule',
        data: ɵ20,
    },
    {
        path: 'search',
        loadChildren: './modules/search/search.module#SearchModule',
        data: ɵ21,
    },
    {
        path: ':lang/search',
        loadChildren: './modules/search/search.module#SearchModule',
        data: ɵ22,
    },
    {
        path: 'map',
        loadChildren: './modules/map/map.module#MapModule',
        pathMatch: 'full',
        data: ɵ23,
    },
    {
        path: ':lang/map',
        loadChildren: './modules/map/map.module#MapModule',
        pathMatch: 'full',
        data: ɵ24,
    },
    {
        path: 'map/:slug',
        loadChildren: './modules/map/map.module#MapModule',
        pathMatch: 'full',
        data: ɵ25,
    },
    {
        path: ':lang/map/:slug',
        loadChildren: './modules/map/map.module#MapModule',
        pathMatch: 'full',
        data: ɵ26,
    },
    {
        path: 'hood',
        loadChildren: './modules/hood/hood.module#HoodModule',
        data: ɵ27,
    },
    {
        path: ':lang/hood',
        loadChildren: './modules/hood/hood.module#HoodModule',
        data: ɵ28,
    },
    /* Redundant routing, child routing is handled in the Hood module.
     {
        "path": "hood/:city/:id",
        "loadChildren": "./modules/hood/hood.module#HoodModule",
        "data": {
           "title": "Aluesivu",
           "description": ""
        }
     },
     {
        "path": ":lang/hood/:city/:id",
        "loadChildren": "./modules/hood/hood.module#HoodModule",
        "data": {
           "title": "Aluesivu",
           "description": ""
        }
     },
     {
        "path": "hood/:region/:subregion/:city/:id",
        "loadChildren": "./modules/hood/hood.module#HoodModule",
        "data": {
           "title": "Aluesivu",
           "description": ""
        }
     },
     {
        "path": ":lang/hood/:region/:subregion/:city/:id",
        "loadChildren": "./modules/hood/hood.module#HoodModule",
        "data": {
           "title": "Aluesivu",
           "description": ""
        }
     },
     {
        "path": "hood/:id",
        "loadChildren": "./modules/hood/hood.module#HoodModule",
        "data": {
           "title": "Aluesivu",
           "description": ""
        }
     },
     {
        "path": ":lang/hood/:id",
        "loadChildren": "./modules/hood/hood.module#HoodModule",
        "data": {
           "title": "Aluesivu",
           "description": ""
        }
     }, */
    {
        path: 'blog',
        loadChildren: './modules/article/article.module#ArticleModule',
        data: ɵ29,
    },
    {
        path: ':lang/blog',
        loadChildren: './modules/article/article.module#ArticleModule',
        data: ɵ30,
    },
    {
        path: 'blog/article/:id',
        loadChildren: './modules/article/article.module#ArticleModule',
        data: ɵ31,
    },
    {
        path: ':lang/blog/article/:id',
        loadChildren: './modules/article/article.module#ArticleModule',
        data: ɵ32,
    },
    {
        path: 'blog/galleria1',
        redirectTo: 'blog/article/364',
        pathMatch: 'full',
    },
    {
        path: ':lang/blog/galleria1',
        redirectTo: 'blog/article/364',
        pathMatch: 'full',
    },
    {
        path: 'blog/galleria2',
        redirectTo: 'blog/article/505',
        pathMatch: 'full',
    },
    {
        path: ':lang/blog/galleria2',
        redirectTo: 'blog/article/505',
        pathMatch: 'full',
    },
    {
        path: 'blog/galleria3',
        redirectTo: 'blog/article/626',
        pathMatch: 'full',
    },
    {
        path: ':lang/blog/galleria3',
        redirectTo: 'blog/article/626',
        pathMatch: 'full',
    },
    {
        path: 'user',
        loadChildren: './modules/registration/registration.module#RegistrationModule',
        data: ɵ33,
    },
    {
        path: ':lang/user',
        loadChildren: './modules/registration/registration.module#RegistrationModule',
        data: ɵ34,
    },
    {
        path: 'profile',
        loadChildren: './modules/profile/profile.module#ProfileModule',
        data: ɵ35,
    },
    {
        path: ':lang/profile',
        loadChildren: './modules/profile/profile.module#ProfileModule',
        data: ɵ36,
    },
    {
        path: 'edit',
        redirectTo: 'profile/edit',
        pathMatch: 'full',
    },
    {
        path: ':lang/edit',
        redirectTo: ':lang/profile/edit',
        pathMatch: 'full',
    },
    {
        path: 'logout',
        loadChildren: './modules/registration/registration.module#RegistrationModule',
        data: ɵ37,
    },
    {
        path: ':lang/logout',
        loadChildren: './modules/registration/registration.module#RegistrationModule',
        data: ɵ38,
    },
    {
        path: 'login',
        redirectTo: 'user/login',
        pathMatch: 'full',
    },
    {
        path: ':lang/login',
        redirectTo: ':lang/user/login',
        pathMatch: 'full',
    },
    {
        path: 'register',
        redirectTo: 'user/register',
        pathMatch: 'full',
    },
    {
        path: ':lang/register',
        redirectTo: ':lang/user/register',
        pathMatch: 'full',
    },
    {
        path: 'verify',
        redirectTo: 'user/verify',
        pathMatch: 'full',
    },
    {
        path: ':lang/verify',
        redirectTo: ':lang/user/verify',
        pathMatch: 'full',
    },
    {
        path: 'thanks',
        redirectTo: 'user/thanks',
        pathMatch: 'full',
    },
    {
        path: ':lang/thanks',
        redirectTo: ':lang/user/thanks',
        pathMatch: 'full',
    },
    {
        path: '404',
        redirectTo: 'not-found',
        pathMatch: 'full',
    },
    {
        path: ':lang/404',
        redirectTo: 'not-found',
        pathMatch: 'full',
    },
    {
        path: ':lang/not-found',
        redirectTo: 'not-found',
        pathMatch: 'full',
    },
    {
        path: 'not-found',
        component: NotFoundComponent,
        data: ɵ39,
    },
    {
        path: ':lang/',
        loadChildren: './modules/landing/landing.module#LandingModule',
        pathMatch: 'full',
        data: ɵ40,
    },
    {
        path: ':lang',
        loadChildren: './modules/landing/landing.module#LandingModule',
        pathMatch: 'full',
        data: ɵ41,
    },
    {
        path: '**',
        component: NotFoundComponent,
        data: ɵ42,
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39, ɵ40, ɵ41, ɵ42 };
