import { MatDialog } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
export var AreaFeatureType;
(function (AreaFeatureType) {
    AreaFeatureType["Polygon"] = "Polygon";
    AreaFeatureType["MultiPolygon"] = "MultiPolygon";
})(AreaFeatureType || (AreaFeatureType = {}));
var ServicesService = /** @class */ (function () {
    function ServicesService(dialog) {
        this.dialog = dialog;
        this.isItemDetailsOpen = new BehaviorSubject(false);
        this.isItemDetailsOpen$ = this.isItemDetailsOpen.asObservable();
        this.isMobileMapOpen = new Subject();
        this.isMobileMapOpen$ = this.isMobileMapOpen.asObservable();
        this.areaProperties = new BehaviorSubject(null);
        this.areaProperties$ = this.areaProperties.asObservable();
        this.isMobile = false;
        this.showMunicipalityServices = false;
        this.getScreenSize();
    }
    ServicesService.prototype.getScreenSize = function (event) {
        if (window.innerWidth <= 768) {
            this.isMobile = true;
        }
    };
    ServicesService.prototype.setIsMobileMapOpen = function (state) {
        this.isMobileMapOpen.next(state);
    };
    ServicesService.prototype.setIsItemDetailsOpen = function (state) {
        this.isItemDetailsOpen.next(state);
    };
    ServicesService.prototype.setAreaProperties = function (area) {
        this.areaProperties.next(area);
    };
    ServicesService.prototype.setShowMunicipalityServices = function (show) {
        this.showMunicipalityServices = show;
    };
    ServicesService.prototype.getShowMunicipalityServices = function () {
        return this.showMunicipalityServices;
    };
    ServicesService.prototype.closeDialog = function () {
        this.dialog.closeAll();
    };
    ServicesService.prototype.mapLocationToFeatureCollection = function (data) {
        var featureCollection = {
            type: 'FeatureCollection',
            features: [],
        };
        for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
            var service = data_1[_i];
            if (!service.coordinates) {
                continue;
            }
            var feature = {
                type: 'Feature',
                id: service.id,
                geometry: {
                    type: 'Point',
                    coordinates: [service.coordinates[0], service.coordinates[1]],
                },
                properties: {
                    title: service.name,
                    categories: service.categories,
                },
            };
            featureCollection.features.push(feature);
        }
        return featureCollection;
    };
    ServicesService.ngInjectableDef = i0.defineInjectable({ factory: function ServicesService_Factory() { return new ServicesService(i0.inject(i1.MatDialog)); }, token: ServicesService, providedIn: "root" });
    return ServicesService;
}());
export { ServicesService };
