import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventService } from '../../../services/events/event.service';
import { GeoJsonPoint } from '../../simple-map/simple-map.component';
import { Event, TranslationExternalLink } from '../../../services/events/event.model';
import { getTranslation } from '../../../utils/getTranslation';
import { getDateRange } from '../../../utils/getFormattedDate';
import { ActivatedRoute, Router } from '@angular/router';
import { getMultipleShuffled } from '../../../utils/shuffle';

export interface ParsedEvent {
  superEventName: string;
  name: string;
  date: string;
  shortDescription: string;
  longDescription: string;
  address: {
    street: string;
    name: string;
    postalCode: string;
    city: string;
  };
  web: string;
  instagram: string;
  facebook: string;
  twitter: string;
  image: string;
  imageAltText: string;
  virtualeventUrl: string;
}

export enum ExternalLink {
  INSTAGRAM = 'extlink_instagram',
  FACEBOOK = 'extlink_facebook',
  TWITTER = 'extlink_twitter',
}

@Component({
  selector: 'event-item-small',
  templateUrl: './event-item-small.component.html',
  styleUrls: ['./event-item-small.component.scss'],
})
export class EventItemSmallComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public currentLanguage = 'fi';

  public point: any;
  public event: ParsedEvent;
  public isVirtual = false;

  public relatedEvents: any[];
  private superEventId = '';
  public relatedEventTitle = 'EVENT_ITEM.SUBEVENT.TITLE_RELATED';

  constructor(
    public dialogRef: MatDialogRef<EventItemSmallComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    private eventService: EventService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      if (params.item) {
        this.initEvent(params.item);
      }
    });
  }

  ngOnInit() {
    // this.initEvent(this.data.id);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  closeItem(): void {
    this.dialogRef.close({ action: 'close', id: null });
  }

  initEvent(id) {
    this.resetEvents();
    this.eventService
      .getEvent(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        const event = response.data;
        this.point = event.point;
        this.isVirtual = event.is_virtualevent;
        this.parseEventData(event);
        this.getRelatedEvents(event);
      });
  }

  resetEvents() {
    this.event = {
      superEventName: '',
      name: '',
      date: '',
      shortDescription: '',
      longDescription: '',
      address: {
        street: '',
        name: '',
        postalCode: '',
        city: '',
      },
      web: '',
      instagram: '',
      facebook: '',
      twitter: '',
      image: '',
      imageAltText: '',
      virtualeventUrl: '',
    };
    this.relatedEvents = [];
    this.superEventId = '';
  }

  async getRelatedEvents(event: Event) {
    let ids = [];

    // Event is a super event
    if (event.sub_events && event.sub_events.length > 0) {
      ids = ids.concat(this.getSubEventsIds(event));
    }

    // Event is a sub event
    if (event.super_event_id) {
      this.superEventId = event.super_event_id;
      ids = ids.concat(event.super_event_id);
      const response = await this.eventService.getSubEvents(event.super_event_id).toPromise();
      // Remove current event from list
      ids = ids.concat(this.getSubEventsIds(response.data[0])).filter((id) => id !== event.id);
      if (event.sub_events && event.sub_events.length > 0) {
      }
    }

    // Get similar events by category if no sub/super events exist
    if (ids.length < 1) {
      this.relatedEventTitle = 'EVENT_ITEM.SUBEVENT.TITLE_MORE';
      const categories = event.linked_events_category.map((category) => {
        return category.linked_events_category_id;
      });
      const similar = await this.eventService.getSimilarEvents(categories).toPromise();
      const shuffled = getMultipleShuffled(similar.data, 5);
      shuffled.sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());
      this.parseSubEvents(shuffled);
      return;
    }

    this.eventService
      .getEventsById(ids)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.parseSubEvents(response.data);
      });
  }

  getSubEventsIds(event: Event) {
    return this.extractIdFromUri(event.sub_events);
  }

  openEventItem(eventId: string) {
    this.dialogRef.close({ action: 'open', id: eventId });
  }

  parseEventData(event: Event) {
    const images = event.images && event.images.length > 0 ? event.images[0] : null;
    const altText = images && event.images[0].alt_text ? event.images[0].alt_text : null;
    const location = event.location ? event.location : null;
    this.event.superEventName = getTranslation(event.super_event_name, this.currentLanguage);
    this.event.name = getTranslation(event.name, this.currentLanguage);
    this.event.date = this.getEventDate(event.start_time, event.end_time);
    this.event.shortDescription = getTranslation(event.short_description, this.currentLanguage);
    this.event.longDescription = getTranslation(event.description, this.currentLanguage);
    this.event.address.name = location
      ? getTranslation(event.location.name, this.currentLanguage)
      : '';
    this.event.address.street = location
      ? getTranslation(event.location.street_address, this.currentLanguage)
      : '';
    this.event.address.postalCode =
      location && event.location.postal_code ? event.location.postal_code : '';
    this.event.address.city = location
      ? getTranslation(event.location.address_locality, this.currentLanguage)
      : '';
    this.event.web = getTranslation(event.info_url, this.currentLanguage);
    this.event.instagram = this.getExternalLink(event.external_links, ExternalLink.INSTAGRAM);
    this.event.facebook = this.getExternalLink(event.external_links, ExternalLink.FACEBOOK);
    this.event.twitter = this.getExternalLink(event.external_links, ExternalLink.TWITTER);
    this.event.image = images && event.images[0].url ? event.images[0].url : '';
    this.event.imageAltText = altText ? getTranslation(altText, this.currentLanguage) : '';
    this.event.virtualeventUrl = event.virtualevent_url;
  }

  parseSubEvents(events: Event[]) {
    const subevents = [];
    events.forEach((event) => {
      const parsed = {
        id: event.id,
        name: event.name,
        start_time: event.start_time,
        end_time: event.end_time,
        images: event.images,
      };
      subevents.push(parsed);
    });
    this.relatedEvents = subevents;
  }

  getExternalLink(links: TranslationExternalLink[], platform: ExternalLink) {
    const found = links.find((link) => {
      if (link.name === platform) {
        if (link.language === this.currentLanguage) {
          return true;
        } else {
          return link.language === 'fi';
        }
      }
    });
    if (!found) {
      return '';
    }
    return found.link;
  }

  getEventDate(start: string, end: string): string {
    return getDateRange(start, end);
  }

  extractIdFromUri(uris: { '@id': string }[]) {
    if (!uris || (Array.isArray(uris) && !uris.length)) {
      return;
    }
    const ids = [];
    for (const uri of uris) {
      let path = uri['@id'];
      if (path.endsWith('/')) {
        path = path.slice(0, -1);
      }
      const lastPath = path.substring(path.lastIndexOf('/') + 1);
      ids.push(lastPath);
    }
    return ids;
  }
}
