import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { IUser } from '../../models/IUser';
import { TokenStorageService } from './token-storage.service';
import { tap } from 'rxjs/operators';
import { endpoints } from '../../configs/sources';

interface LoginResp {
  user: IUser;
  accessToken: string;
  refreshToken: string;
}

interface TokenRefreshResp {
  accessToken: string;
  refreshToken: string;
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) {}

  isUserTester = new Subject<any>();

  // HTTP root
  readonly ROOT_URL = endpoints.auth;

  get facebookAuthUrl(): string {
    return this.ROOT_URL + '/facebook';
  }
  get googleAuthUrl(): string {
    return this.ROOT_URL + '/google';
  }

  setIsUserTester(value: boolean) {
    this.isUserTester.next(value);
  }

  loginUser(post: any): Observable<LoginResp> {
    return this.http.post<LoginResp>(
      this.ROOT_URL + '/login',
      {
        email: post.email,
        password: post.password,
      },
      httpOptions,
    );
  }

  logoutUser() {
    let location = window.location.host;

    if (location == 'dev.hoods.fi' || location == 'beta.hoods.fi' || location == 'localhost:4200') {
      this.setIsUserTester(true);
    } else {
      this.setIsUserTester(false);
    }

    this.tokenStorage.signOut();
  }

  refreshToken(temporary = false): Observable<TokenRefreshResp> {
    const refreshToken = temporary
      ? this.tokenStorage.getTemporaryRefreshToken()
      : this.tokenStorage.getRefreshToken();
    return this.http
      .post<TokenRefreshResp>(
        this.ROOT_URL + '/refresh-token',
        {
          refreshToken,
        },
        httpOptions,
      )
      .pipe(
        tap((res) => {
          if (temporary) {
            this.tokenStorage.saveTemporaryToken(res.accessToken);
            this.tokenStorage.saveTemporaryRefreshToken(res.refreshToken);
            return;
          }
          this.tokenStorage.saveToken(res.accessToken);
          this.tokenStorage.saveRefreshToken(res.refreshToken);
        }),
      );
  }
}
