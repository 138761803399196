import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ServicesFilterComponent } from '../services-filter/services-filter.component';
import { ServicesFilterService, } from '../../../services/services-filter/services-filter.service';
var ServicesFilterMobileComponent = /** @class */ (function () {
    function ServicesFilterMobileComponent(dialogRef, servicesFilterService) {
        this.dialogRef = dialogRef;
        this.servicesFilterService = servicesFilterService;
    }
    ServicesFilterMobileComponent.prototype.ngOnInit = function () {
        this.getCategories();
    };
    ServicesFilterMobileComponent.prototype.getCategories = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, parents, childs, sources;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.servicesFilterService.getCategories()];
                    case 1:
                        _a = _b.sent(), parents = _a.parents, childs = _a.childs, sources = _a.sources;
                        this.parentCategories = parents;
                        this.childCategories = childs;
                        this.dataSources = sources;
                        this.getParentChildBadgeCount();
                        return [2 /*return*/];
                }
            });
        });
    };
    ServicesFilterMobileComponent.prototype.saveSelectedFilters = function (filterType) {
        if (filterType === 'category') {
            this.getParentChildBadgeCount();
        }
    };
    ServicesFilterMobileComponent.prototype.resetUserSelection = function () {
        this.childCategories.forEach(function (child) {
            child.selected = false;
        });
        this.dataSources.forEach(function (source) {
            // TODO: Temporarily set data source 12 as default filter. Remove this default
            // when integrating other service types.
            if (source.code !== 12) {
                source.selected = false;
            }
        });
        this.getParentChildBadgeCount();
    };
    ServicesFilterMobileComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    ServicesFilterMobileComponent.prototype.openResults = function () {
        var selected = this.getUserSelection();
        this.servicesFilterService.setSelectedServicesFilters(selected);
        this.dialogRef.close();
    };
    ServicesFilterMobileComponent.prototype.getUserSelection = function () {
        var filteredSources = this.dataSources.filter(function (source) { return source.selected; });
        var selectedSources = filteredSources.map(function (source) { return source.code; });
        var filteredCategories = this.childCategories.filter(function (child) { return child.selected; });
        var selectedCategories = filteredCategories.map(function (child) { return child.id; });
        var selected = {
            sources: selectedSources,
            categories: selectedCategories,
        };
        return selected;
    };
    ServicesFilterMobileComponent.prototype.getParentChildBadgeCount = function () {
        var _this = this;
        this.parentCategories.forEach(function (parent) {
            var selectedChilds = _this.childCategories.filter(function (child) { return child.locationCategoryId === parent.id && child.selected; });
            parent.selectedChildCount = selectedChilds.length;
        });
    };
    ServicesFilterMobileComponent.prototype.scrollContentIntoView = function (expansionPanelIndex) {
        // Scrolls the expansion panel to top when selected
        var container = document.getElementsByClassName('mat-dialog-container')[0];
        var panel = document.getElementsByClassName('mat-expansion-panel')[expansionPanelIndex];
        var height = document.getElementsByClassName('mat-toolbar-single-row')[0];
        container.scrollTop = panel.offsetTop - height.clientHeight;
    };
    return ServicesFilterMobileComponent;
}());
export { ServicesFilterMobileComponent };
