import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { EventService } from '../../../services/events/event.service';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { getTodaysDate } from '../../../utils/getFormattedDate';
import { DateAdapter, MatDialogRef } from '@angular/material';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
var EventFilterComponent = /** @class */ (function () {
    function EventFilterComponent(eventService, translate, adapter, tooltipConfig, dialogRef) {
        var _this = this;
        this.eventService = eventService;
        this.translate = translate;
        this.adapter = adapter;
        this.tooltipConfig = tooltipConfig;
        this.dialogRef = dialogRef;
        // Select forms
        this.filterGroup = new FormGroup({
            formControlAudience: new FormControl([]),
            formControlContent: new FormControl([]),
            formControlType: new FormControl([]),
            formControlSuper: new FormControl(false),
            formControlStart: new FormControl(''),
            formControlEnd: new FormControl(''),
        });
        // Date picker
        this.today = null;
        this.selectedEnd = null;
        this.selectedStart = null;
        this.currentLanguage = 'fi';
        this.loading = false;
        // CSS
        this.isSmallDeviceWidth = false;
        this.destroyed$ = new ReplaySubject(1);
        this.getScreenSize();
        this.currentLanguage = translate.currentLang;
        this.translate.onLangChange.pipe(takeUntil(this.destroyed$)).subscribe(function (event) {
            _this.currentLanguage = _this.translate.currentLang;
            _this.adapter.setLocale(_this.currentLanguage);
        });
        tooltipConfig.triggers = 'click hover';
    }
    EventFilterComponent.prototype.getScreenSize = function (event) {
        if (window.innerWidth <= 768) {
            this.isSmallDeviceWidth = true;
        }
    };
    EventFilterComponent.prototype.ngOnInit = function () {
        this.getFilterCategories();
        this.updateEventsOnFilterChanges();
        this.today = getTodaysDate();
        this.getLoadingStatus();
        this.adapter.setLocale(this.currentLanguage);
        this.initFormGroup();
    };
    EventFilterComponent.prototype.ngOnDestroy = function () {
        this.destroyed$.next(true);
        this.destroyed$.complete();
        if (!this.isSmallDeviceWidth) {
            this.resetFilters();
        }
    };
    EventFilterComponent.prototype.initFormGroup = function () {
        var _this = this;
        if (this.dialogRef) {
            this.dialogRef.afterOpened().subscribe(function () {
                _this.eventService.currentFilters.subscribe(function (filters) {
                    if (filters) {
                        _this.filterGroup.setValue(filters);
                    }
                });
            });
            return;
        }
        this.filterCopy = this.filterGroup.value;
        this.eventService.setFilters(this.filterCopy);
    };
    EventFilterComponent.prototype.getLoadingStatus = function () {
        var _this = this;
        this.eventService.loadingStatus
            .pipe(takeUntil(this.destroyed$))
            .subscribe(function (loading) { return (_this.loading = loading); });
    };
    EventFilterComponent.prototype.updateEventsOnFilterChanges = function () {
        var _this = this;
        this.filterGroup.valueChanges
            .pipe(
        // Eliminate double value changes in datepicker
        distinctUntilChanged(function (prev, curr) {
            return (prev.formControlStart === curr.formControlStart &&
                prev.formControlEnd === curr.formControlEnd &&
                prev.formControlAudience === curr.formControlAudience &&
                prev.formControlContent === curr.formControlContent &&
                prev.formControlType === curr.formControlType &&
                prev.formControlSuper === curr.formControlSuper);
        }))
            .subscribe(function (changes) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.filterCopy = changes;
                return [2 /*return*/];
            });
        }); });
    };
    EventFilterComponent.prototype.getFilterCategories = function () {
        var _this = this;
        this.eventService
            .getEventCategories()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(function (response) {
            _this.contents = response.data.filter(function (category) { return category.category_group === 'turku:topic_content'; });
            _this.types = response.data.filter(function (category) { return category.category_group === 'turku:topic_type'; });
            _this.audiences = response.data.filter(function (category) { return category.category_group === 'turku:audience'; });
        });
    };
    EventFilterComponent.prototype.endChanged = function (event) {
        this.selectedEnd = event.value.toISOString();
    };
    EventFilterComponent.prototype.startChanged = function (event) {
        this.selectedStart = event.value.toISOString();
    };
    EventFilterComponent.prototype.resetDate = function (type) {
        if (type === 'start') {
            this.selectedStart = null;
            this.filterGroup.get('formControlStart').patchValue('');
        }
        if (type === 'end') {
            this.selectedEnd = null;
            this.filterGroup.get('formControlEnd').patchValue('');
        }
    };
    EventFilterComponent.prototype.closeFilterDialog = function (fromElement) {
        var filters = fromElement === 'close' ? null : this.filterCopy;
        this.dialogRef.close(filters);
    };
    EventFilterComponent.prototype.showResults = function () {
        var filters = this.eventService.getFilters();
        if (JSON.stringify(this.filterCopy) !== JSON.stringify(filters)) {
            this.eventService.setFilters(this.filterCopy);
        }
    };
    EventFilterComponent.prototype.clearFilters = function (device) {
        this.resetFilters();
        if (device === 'mobile') {
            return;
        }
        var filters = this.eventService.getFilters();
        if (JSON.stringify(this.filterCopy) !== JSON.stringify(filters)) {
            this.eventService.setFilters(this.filterCopy);
        }
    };
    EventFilterComponent.prototype.resetFilters = function () {
        this.selectedStart = null;
        this.selectedEnd = null;
        this.filterGroup.reset({
            formControlAudience: [],
            formControlContent: [],
            formControlType: [],
            formControlSuper: false,
            formControlStart: '',
            formControlEnd: '',
        });
    };
    return EventFilterComponent;
}());
export { EventFilterComponent };
