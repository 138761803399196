import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { LoginComponent } from '../modules/registration/login/login.component';
import { TokenStorageService } from '../services/registration/token-storage.service';

// This guard grants admins access to the application when using development or beta domains.
// Add this to a Routes path object with "canDeactivate: [AdminDeactivateGuard]".

@Injectable()
export class AdminDeactivateGuard implements CanDeactivate<LoginComponent> {
  constructor(private tokenStorage: TokenStorageService) {}

  canDeactivate(
    component: LoginComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ): boolean {
    // Allow register and verify pages
    if (nextState.url.includes('register') || nextState.url.includes('verify')) return true;

    const url = window.location.host;

    const domains = ['hoods.fi', 'dev.hoods.fi', 'localhost:4200'];

    if (domains.includes(url)) {
      return true;
    } else {
      const currentUser = this.tokenStorage.getCurrentUser();

      if (currentUser !== null) {
        if (currentUser.role === true) {
          return true;
        }
      }

      console.log('You do not have access to', url);
      return false;
    }
  }
}
