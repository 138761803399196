import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CategoryGroup, Event, LinkedEventsCategory } from './event.model';
import { endpoints } from '../../configs/sources';
import { convertToBasic, getUpcomingDate } from '../../utils/getFormattedDate';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private pageLimit = 20;

  private filterSource = new BehaviorSubject<Filter>(null);
  public currentFilters = this.filterSource.asObservable();
  private filters: Filter;

  public loadingSource = new BehaviorSubject(false);
  public loadingStatus = this.loadingSource.asObservable();

  constructor(private http: HttpClient) {
    this.currentFilters.subscribe((data) => {
      this.filters = data;
    });
  }

  getEvents(
    requesterId: number,
    requester: 'hood' | 'municipality',
    opts?: { skipFilters?: boolean; limit?: number; page?: number },
  ): Observable<LinkedEventResponse> {
    this.setLoadingStatus(true);
    let filters = this.filters;
    if (opts && opts.skipFilters) {
      filters = undefined;
    }
    const searchParams: any = { _and: [] };

    // Search by hood or municipality
    if (requester && requester === 'hood') {
      searchParams._and.push({ areas: { area_id: { hoods_id: { _eq: requesterId } } } });
    }
    if (requester && requester === 'municipality') {
      searchParams._and.push({
        areas: { area_id: { municipality_id: { _eq: requesterId } } },
      });
    }

    // Search by date when no filters or no specific date filters exist
    if (!filters || (!filters.formControlStart && !filters.formControlEnd)) {
      searchParams._and.push(this.getDefaultDateFilter());
    }

    if (filters && (filters.formControlStart || filters.formControlEnd)) {
      filters.formControlStart = filters.formControlStart
        ? convertToBasic(filters.formControlStart)
        : '';
      filters.formControlEnd = filters.formControlEnd ? convertToBasic(filters.formControlEnd) : '';

      // If start date without end date
      if (filters.formControlStart && !filters.formControlEnd) {
        searchParams._and.push({
          end_time: {
            _gte: filters.formControlStart + 'T00:00Z',
          },
        });
      }

      // If end date without start date
      if (!filters.formControlStart && filters.formControlEnd) {
        searchParams._and.push({
          end_time: {
            _gte: filters.formControlEnd + 'T00:00Z',
          },
        });
        searchParams._and.push({
          start_time: {
            _lte: filters.formControlEnd + 'T23:59Z',
          },
        });
      }

      // If start date with end date
      if (filters.formControlStart && filters.formControlEnd) {
        searchParams._and.push({
          end_time: {
            _lte: filters.formControlEnd + 'T23:59Z',
          },
        });
        searchParams._and.push({
          end_time: {
            _gte: filters.formControlStart + 'T00:00Z',
          },
        });
      }
    }

    // Search by audience
    if (filters && filters.formControlAudience && filters.formControlAudience.length > 0) {
      searchParams._and.push({
        audience: {
          linked_events_category_id: { id: { _in: filters.formControlAudience.toString() } },
        },
      });
    }

    // Search by categories
    if (
      filters &&
      ((filters.formControlContent && filters.formControlContent.length > 0) ||
        (filters.formControlType && filters.formControlType.length > 0))
    ) {
      let categories: string[] = [];
      if (filters.formControlContent.length > 0) {
        categories = categories.concat(filters.formControlContent);
      }
      if (filters.formControlType.length > 0) {
        categories = categories.concat(filters.formControlType);
      }
      searchParams._and.push({
        linked_events_category: {
          linked_events_category_id: { id: { _in: categories.toString() } },
        },
      });
    }

    // Search by super event > disabled because of bad user experience
    // if (filters && filters.formControlSuper) {
    //   searchParams._and.push({ super_event_type: { _nnull: true } });
    // }

    // Hide sub events from list
    searchParams._and.push({ sub_event_type: { _null: true } });

    let limit = this.pageLimit;
    if (opts && opts.limit) {
      limit = opts.limit;
    }

    const params = {
      filter: `?filter=${JSON.stringify(searchParams)}`,
      sort: `&sort=start_time`,
      fields: `&fields=id,name,start_time,end_time,images`,
      meta: `&meta=filter_count`,
      limit: `&limit=${limit}`,
    };

    let url: string =
      endpoints.directusLinkedEvents + params.filter + params.fields + params.meta + params.limit;

    if (opts && opts.page) {
      url += `&page=${opts.page}`;
    }

    return this.http.get<LinkedEventResponse>(url);
  }

  getEvent(id: string): Observable<SingleLinkedEventResponse> {
    const params = {
      fields: `?fields=id,name,start_time,end_time,images,location,super_event_name,short_description,description,info_url,external_links,virtualevent_url,linked_events_category.linked_events_category_id,sub_events,super_event_id,audience,point`,
    };
    const url: string = endpoints.directusLinkedEvents + id + params.fields;
    return this.http.get<SingleLinkedEventResponse>(url);
  }

  getEventsById(ids: string[]): Observable<LinkedEventResponse> {
    const searchParams: any = { _and: [] };
    searchParams._and.push(
      {
        id: { _in: ids },
      },
      this.getDefaultDateFilter(),
    );
    const params = {
      filter: `?filter=${JSON.stringify(searchParams)}`,
      fields: `&fields=id,name,start_time,end_time,images`,
      sort: `&sort=start_time`,
    };
    const url = endpoints.directusLinkedEvents + params.filter + params.fields + params.sort;
    return this.http.get<LinkedEventResponse>(url);
  }

  getSubEvents(id: string): Observable<SingleLinkedEventResponse> {
    const searchParams: any = { _and: [] };
    searchParams._and.push(
      {
        id: { _eq: id },
      },
      this.getDefaultDateFilter(),
    );
    const params = {
      filter: `?filter=${JSON.stringify(searchParams)}`,
      fields: `&fields=sub_events`,
    };
    const url = endpoints.directusLinkedEvents + params.filter + params.fields;
    return this.http.get<SingleLinkedEventResponse>(url);
  }

  getSimilarEvents(categoryIds: string[]): Observable<LinkedEventResponse> {
    const searchParams: any = { _and: [] };
    const twoMonthsFromNow = getUpcomingDate(2);
    searchParams._and.push(
      {
        linked_events_category: {
          linked_events_category_id: { id: { _in: categoryIds.toString() } },
        },
      },
      {
        end_time: {
          _lte: twoMonthsFromNow,
        },
      },
    );
    const params = {
      filter: `?filter=${JSON.stringify(searchParams)}`,
      fields: `&fields=id,name,start_time,end_time,images`,
    };
    const url = endpoints.directusLinkedEvents + params.filter + params.fields;
    return this.http.get<LinkedEventResponse>(url);
  }

  getEventCategories(): Observable<LinkedEventsCategoryResponse> {
    const url = endpoints.directusLinkedEventsCategory;
    return this.http.get<LinkedEventsCategoryResponse>(url);
  }

  getDefaultDateFilter() {
    const filter = {
      _or: [
        {
          end_time: {
            _gte: '$NOW()',
          },
        },
        {
          _and: [
            {
              end_time: {
                _null: true,
              },
            },
            {
              start_time: {
                _gte: '$NOW()',
              },
            },
          ],
        },
        {
          _and: [
            {
              end_time: {
                _null: true,
              },
            },
            {
              start_time: {
                _null: true,
              },
            },
          ],
        },
      ],
    };
    return filter;
  }

  setFilters(filters: Filter) {
    this.filterSource.next(filters);
    this.setLoadingStatus(true);
  }

  getFilters() {
    return this.filters;
  }

  setLoadingStatus(loading: boolean) {
    this.loadingSource.next(loading);
  }
}

export interface LinkedEventResponse {
  meta?: any;
  data: Event[];
}

export interface SingleLinkedEventResponse {
  meta?: any;
  data: Event;
}

export interface LinkedEventsCategoryResponse {
  data: LinkedEventsCategory[];
}

export interface Filter {
  formControlAudience: string[] | null | [];
  formControlContent: string[] | null | [];
  formControlEnd: string | null;
  formControlStart: string | null;
  formControlSuper: boolean | null;
  formControlType: string[] | null | [];
}
