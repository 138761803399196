<div class="main">
  <div class="left-padding"></div>

  <div class="container">
    <!-- HEADER -->
    <div class="header" [ngStyle]="{ 'background-image': 'url(' + event.image + ')' }">
      <div class="close">
        <button mat-fab (click)="closeItem()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="gradient">
        <div class="title">
          <div class="super-name" *ngIf="event.superEventName">{{ event.superEventName }}</div>
          <h1 class="name">{{ event.name }}</h1>
          <div class="date">{{ event.date }}</div>
        </div>
      </div>
    </div>

    <!-- CONTENT -->
    <div class="content">
      <p>{{ event.shortDescription }}</p>
      <p [innerHTML]="event.longDescription"></p>
    </div>

    <!-- SIDEBAR -->
    <div class="sidebar">
      <mat-tab-group mat-stretch-tabs animationDuration="0ms">
        <mat-tab label="{{ 'EVENT_ITEM.CONTACT.TITLE' | translate }}">
          <!-- FOOTER -->
          <div class="footer">
            <div
              *ngIf="
                !event.virtualeventUrl &&
                !event.address.street &&
                !event.web &&
                !event.instagram &&
                !event.facebook &&
                !event.twitter
              "
            >
              <p>{{ 'EVENT_ITEM.CONTACT.NO_RESULTS' | translate }}</p>
            </div>

            <div *ngIf="event.address.street || event.virtualeventUrl">
              <div class="sub-title">{{ 'EVENT_ITEM.ADDRESS.TITLE' | translate }}</div>

              <div>{{ event.address.name }}</div>

              <div *ngIf="!event.virtualeventUrl">{{ event.address.street }}</div>

              <a *ngIf="event.virtualeventUrl" href="{{ event.virtualeventUrl }}" target="_blank">{{
                'EVENT_ITEM.CONTACT.VIRTUAL_OPEN' | translate
              }}</a>
            </div>

            <div *ngIf="event.web">
              <div class="sub-title">{{ 'EVENT_ITEM.WEB.TITLE' | translate }}</div>
              <a href="{{ event.web }}" target="_blank">{{ 'EVENT_ITEM.WEB.OPEN' | translate }}</a>
            </div>

            <div class="socials">
              <div *ngIf="event.facebook">
                <div class="sub-title">{{ 'EVENT_ITEM.MEDIA.FACEBOOK_TITLE' | translate }}</div>
                <a href="{{ event.facebook }}" target="_blank">{{
                  'EVENT_ITEM.MEDIA.FACEBOOK_OPEN' | translate
                }}</a>
              </div>

              <div *ngIf="event.instagram">
                <div class="sub-title">{{ 'EVENT_ITEM.MEDIA.INSTAGRAM_TITLE' | translate }}</div>
                <a href="{{ event.instagram }}" target="_blank">{{
                  'EVENT_ITEM.MEDIA.INSTAGRAM_OPEN' | translate
                }}</a>
              </div>

              <div *ngIf="event.twitter">
                <div class="sub-title">{{ 'EVENT_ITEM.MEDIA.TWITTER_TITLE' | translate }}</div>
                <a href="{{ event.twitter }}" target="_blank">{{
                  'EVENT_ITEM.MEDIA.TWITTER_OPEN' | translate
                }}</a>
              </div>
            </div>
          </div>

          <!-- MAP -->
          <ng-container *ngIf="!event.virtualeventUrl && point">
            <div class="map-area">
              <simple-map [point]="point"></simple-map>
            </div>
          </ng-container>
        </mat-tab>

        <mat-tab label="{{ relatedEventTitle | translate }}">
          <div class="related">
            <ng-container *ngIf="superEventId">
              <div class="superevent">
                <a (click)="openEventItem(superEventId)">Näytä kattotapahtuma</a>
              </div>
            </ng-container>

            <ng-container *ngIf="relatedEvents.length > 0">
              <ng-container
                *ngFor="let relatedEvent of relatedEvents | slice : eventSliceStart : eventSliceEnd"
              >
                <div class="subevent-list">
                  <a class="name" (click)="openEventItem(relatedEvent.id)">{{
                    relatedEvent.name
                  }}</a>
                  <div>{{ relatedEvent.date }}</div>
                </div>
              </ng-container>

              <paginator
                class="paginator"
                *ngIf="relatedEvents.length > paginationOptionsRef.pageSize"
                [total]="relatedEvents.length"
                [paginationOptionsRef]="paginationOptionsRef"
                (paginationOptions)="paginationOptions($event)"
              ></paginator>
            </ng-container>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <div class="right-padding"></div>
</div>
