import * as L from 'leaflet';
import * as i0 from "@angular/core";
var LeafletMessageBoxService = /** @class */ (function () {
    function LeafletMessageBoxService() {
    }
    LeafletMessageBoxService.prototype.createControl = function (map) {
        var _this = this;
        if (!this.control) {
            // don't create multiple controls for no reason
            if (!this.container) {
                // control container element
                this.container = L.DomUtil.create('div', 'leaflet-messagebox');
            }
            this.control = new L.Control({
                position: 'bottomleft',
            });
            this.control.onAdd = function () {
                return _this.container;
            };
            this.control.showMessage = function (message, timeout) {
                _this.container.innerHTML = message;
                _this.container.style.display = 'block';
                if (typeof _this.timeoutId === 'number') {
                    clearTimeout(_this.timeoutId);
                }
                _this.timeoutId = setTimeout(function () {
                    _this.container.style.display = 'none';
                }, timeout);
            };
            this.control.hideMessage = function () {
                _this.container.style.display = 'none';
            };
        }
        this.control.addTo(map);
        return this.control;
    };
    LeafletMessageBoxService.prototype.showMessage = function (message, timeout) {
        if (timeout === void 0) { timeout = 3000; }
        if (!this.control) {
            console.error('no control for messagebox');
            return;
        }
        this.control.showMessage(message, timeout);
    };
    LeafletMessageBoxService.prototype.hideMessage = function () {
        if (!this.control) {
            console.error('no control for messagebox');
            return;
        }
        this.control.hideMessage();
    };
    LeafletMessageBoxService.ngInjectableDef = i0.defineInjectable({ factory: function LeafletMessageBoxService_Factory() { return new LeafletMessageBoxService(); }, token: LeafletMessageBoxService, providedIn: "root" });
    return LeafletMessageBoxService;
}());
export { LeafletMessageBoxService };
