import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'single-review-rating',
  templateUrl: './single-review-rating.component.html',
  styleUrls: ['./single-review-rating.component.scss'],
})
export class SingleReviewRatingComponent implements OnInit {
  faHeartSolid = faHeartSolid;

  constructor(
    public dialog: MatDialogRef<SingleReviewRatingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {}
}
