import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { endpoints } from '../../configs/sources';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RegionsService = /** @class */ (function () {
    function RegionsService(http) {
        this.http = http;
        this.endpoint = endpoints.regions;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        this.showDrafts = false;
        var location = window.location.host;
        if (location == 'dev.hoods.fi' || location == 'beta.hoods.fi' || location == 'localhost:4200') {
            this.showDrafts = true;
        }
    }
    RegionsService.prototype.setShowDrafts = function (value) {
        this.showDrafts = value;
    };
    RegionsService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    RegionsService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            // console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            // console.log(`${operation} failed: ${error.message}`);
            return of(result);
        };
    };
    RegionsService.prototype.getRegions = function () {
        return this.http.get(this.endpoint + '?draft=' + this.showDrafts).pipe(map(this.extractData));
    };
    RegionsService.prototype.getRegion = function (id) {
        return this.http
            .get(this.endpoint + '/' + id + +'?draft=' + this.showDrafts)
            .pipe(map(this.extractData));
    };
    RegionsService.prototype.getRegionsSubregions = function (id) {
        return this.http
            .get(this.endpoint + '/' + id + '/subregions' + +'?draft=' + this.showDrafts)
            .pipe(map(this.extractData));
    };
    RegionsService.ngInjectableDef = i0.defineInjectable({ factory: function RegionsService_Factory() { return new RegionsService(i0.inject(i1.HttpClient)); }, token: RegionsService, providedIn: "root" });
    return RegionsService;
}());
export { RegionsService };
