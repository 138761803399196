import * as tslib_1 from "tslib";
import { MunicipalitiesService } from '../municipalities/municipalities.service';
import * as i0 from "@angular/core";
import * as i1 from "../municipalities/municipalities.service";
var SlugService = /** @class */ (function () {
    function SlugService(municipalityService) {
        this.municipalityService = municipalityService;
    }
    SlugService.prototype.getCityIdBySlug = function (slug) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.municipalityService.getMunicipalities().toPromise()];
                    case 1:
                        data = _a.sent();
                        res = data.municipalities.find(function (m) { return m.slug == slug; });
                        if (res !== undefined) {
                            return [2 /*return*/, res];
                        }
                        else {
                            throw new Error('Municipality not found.');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SlugService.ngInjectableDef = i0.defineInjectable({ factory: function SlugService_Factory() { return new SlugService(i0.inject(i1.MunicipalitiesService)); }, token: SlugService, providedIn: "root" });
    return SlugService;
}());
export { SlugService };
