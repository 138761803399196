import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';

@Component({
  selector: 'snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any, // @Inject(MAT_SNACK_BAR_DATA) public data: {
  ) //   message: string,
  //   action: string,
  //   config: {
  //     duration: number,
  //   }
  // }
  {}

  ngOnInit() {}
}
