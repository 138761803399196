import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUser } from '../../models/IUser';
import { RegisterPost } from '../../modules/registration/register/registerPost';
import { endpoints } from '../../configs/sources';

interface UpdatePost {
  username?: string;
  email?: string;
}

interface UpdateResp {
  user: IUser;
  success: string;
}

interface UpdatePassword {
  oldPassword: string;
  newPassword: string;
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly API_URL = endpoints.users;

  constructor(private http: HttpClient) {}

  register(user: RegisterPost): Observable<any> {
    return this.http.post(this.API_URL, user);
  }

  getUsers(): Observable<any> {
    return this.http.get(this.API_URL, httpOptions);
  }

  getUserById(id: number): Observable<IUser> {
    return this.http.get<IUser>(this.API_URL + id, httpOptions);
  }

  getUserStatus(id: number): Observable<IUser> {
    return this.http.get<IUser>(this.API_URL + id + '/status', httpOptions);
  }

  getUserByIdWithDetails(id: number): Observable<IUser> {
    return this.http.get<IUser>(this.API_URL + id + '/all', httpOptions);
  }

  getUserDetailsById(id: number): Observable<{ details: UserDetails }> {
    return this.http.get<any>(this.API_URL + id + '/details', httpOptions);
  }

  updateUser(
    id: number,
    userData: UpdatePost,
    opts?: { skipEmail?: boolean },
  ): Observable<UpdateResp> {
    let url = this.API_URL + id;
    if (opts && opts.skipEmail) {
      url += `?skipEmail=true`;
    }
    return this.http.put<UpdateResp>(url, userData, httpOptions);
  }

  deleteUser(id: number): Observable<any> {
    return this.http.delete(this.API_URL + id, httpOptions);
  }

  resetPassword(email: string): Observable<any> {
    return this.http.get(this.API_URL + 'reset-password?email=' + email);
  }

  modifyPassword(id: number, password: UpdatePassword): Observable<any> {
    return this.http.put(this.API_URL + 'modify-password?id=' + id, password, httpOptions);
  }

  resendVerification(payload: { email: string }): Observable<any> {
    return this.http.post(this.API_URL + 'resend-verification', payload);
  }

  updateProfilePicture(id: number, pictureData: { image: string }): Observable<any> {
    return this.http.post(this.API_URL + id + '/profile-picture', pictureData);
  }

  deleteProfilePicture(id: number) {
    return this.http.delete(this.API_URL + id + '/profile-picture');
  }
}
export interface UserDetails {
  userId?: number;
  detailsId?: number;
  firstName?: string;
  lastName?: string;
  hood?: number;
  zipcode?: string;
  municipality?: string;
  country?: number;
  residencyDate?: string;
  policyConsent?: boolean;
  contactConsent?: boolean;
  newsletterConsent?: boolean;
  visitedProfile?: boolean;
  profilePicture?: string | null;
}
