<div class="debug-tools" *ngIf="showDebug">
  Debug tools:
  <button (click)="clearCache()">Nuke session storage</button>
</div>
<!-- Global page content -->
<mat-toolbar>
  <div class="logo" [routerLink]="currentLang ? '/' + currentLang : ''">
    <img src="../assets/images/hoods-logo-full.svg" height="42" width="144" alt="Hoods logo" />
  </div>

  <span class="spacer"></span>

  <button mat-icon-button [matMenuTriggerFor]="appMenu" *xs>
    <mat-icon>menu</mat-icon>
  </button>

  <!-- MOBILE NAV MENU -->
  <mat-menu #appMenu="matMenu">
    <ng-container *ngFor="let page of pages">
      <button
        mat-menu-item
        *ngIf="page.visible && !page.children"
        [routerLink]="(currentLang ? '/' + currentLang : '') + page.route"
        routerLinkActive="active"
      >
        {{ page.title | translate }}
      </button>

      <span *ngIf="page.visible && page.children">
        <button mat-menu-item [matMenuTriggerFor]="submenu" routerLinkActive="active">
          {{ page.title | translate }}
        </button>
        <mat-menu #submenu="matMenu" class="submenu">
          <ng-container *ngIf="page.title === 'INFO.NAVLINK'">
            <div class="list" *ngFor="let subpage of page.subpages">
              <button
                mat-menu-item
                [routerLink]="(currentLang ? '/' + currentLang : '') + subpage.route"
                [queryParams]="subpage.title === 'INFO.NAVLINK-MAP' ? { service: 'map' } : ''"
              >
                <span>{{ subpage.title | translate }}</span>
              </button>
            </div>
          </ng-container>

          <ng-container *ngIf="page.title === 'CITIES.NAVLINK'">
            <div class="list" *ngFor="let region of sortAlphabetically(navRegions); let i = index">
              <p class="region-name">{{ region.title[lang] }}</p>
              <ng-container *ngFor="let municipality of sortAlphabetically(navRegions[i].subpages)">
                <span *ngIf="municipality.hasHoods">
                  <button
                    class="muncipality-name-bold"
                    mat-menu-item
                    [routerLink]="(currentLang ? '/' + currentLang : '') + municipality.route"
                  >
                    {{ municipality.title[lang] }}
                  </button>
                </span>
                <span *ngIf="!municipality.hasHoods">
                  <button
                    class="muncipality-name"
                    mat-menu-item
                    [routerLink]="(currentLang ? '/' + currentLang : '') + municipality.route"
                  >
                    {{ municipality.title[lang] }}
                  </button>
                </span>
              </ng-container>
            </div>
          </ng-container>
        </mat-menu>
      </span>
    </ng-container>

    <button
      mat-menu-item
      *ngIf="!isLoggedIn()"
      [routerLink]="(currentLang ? '/' + currentLang : '') + '/login'"
    >
      {{ 'LOGIN.NAVLINK' | translate }}
    </button>

    <ng-container *ngIf="isLoggedIn()">
      <button mat-menu-item [matMenuTriggerFor]="profile" routerLinkActive="active">
        {{ 'PROFILE.NAVLINK' | translate }}
      </button>
      <mat-menu #profile="matMenu">
        <button mat-menu-item [routerLink]="(currentLang ? '/' + currentLang : '') + '/profile'">
          {{ 'GOTO_PROFILE.NAVLINK' | translate }}
        </button>
        <button *ngIf="userHoodId && userHoodSlug" mat-menu-item (click)="gotoUserHood()">
          {{ 'GOTO_HOOD.NAVLINK' | translate }}
        </button>
        <button mat-menu-item (click)="logout()">
          {{ 'LOGOUT.NAVLINK' | translate }}
        </button>
      </mat-menu>
    </ng-container>

    <button mat-menu-item [matMenuTriggerFor]="langSelectMenu" routerLinkActive="active">
      {{ 'SELECT_LANG_NAV' | translate }}
    </button>
    <mat-menu #langSelectMenu="matMenu">
      <button
        *ngFor="let lang of translate.getLangs()"
        class="lang-button"
        (click)="changeLang(lang)"
        mat-menu-item
      >
        {{ lang }}
      </button>
    </mat-menu>
  </mat-menu>

  <!-- DESKTOP NAV MENU -->
  <nav attr.aria-label="{{ 'AC.LABEL.MAIN_NAV' | translate }}" *hideItBootstrap="['xs']">
    <span class="nav-items" *hideItBootstrap="['xs']">
      <ng-container *ngFor="let page of pages">
        <a
          *ngIf="page.visible && !page.children"
          [routerLink]="(currentLang ? '/' + currentLang : '') + page.route"
          routerLinkActive="active"
          >{{ page.title | translate }}</a
        >

        <span *ngIf="page.visible && page.children">
          <a [matMenuTriggerFor]="menu" routerLinkActive="active"
            >{{ page.title | translate }}<span class="arrow-down-profile"></span
          ></a>
          <mat-menu #menu="matMenu">
            <ng-container *ngIf="page.title === 'INFO.NAVLINK'">
              <div class="list" *ngFor="let subpage of page.subpages">
                <button
                  mat-menu-item
                  [routerLink]="(currentLang ? '/' + currentLang : '') + subpage.route"
                  [queryParams]="subpage.title === 'INFO.NAVLINK-MAP' ? { service: 'map' } : ''"
                >
                  <span>{{ subpage.title | translate }}</span>
                </button>
              </div>
            </ng-container>

            <ng-container *ngIf="page.title === 'CITIES.NAVLINK'">
              <div
                class="list"
                *ngFor="let region of sortAlphabetically(navRegions); let i = index"
              >
                <p class="region-name">
                  <ng-container *ngIf="region.title[lang]; else defaultRegionTitle">{{
                    region.title[lang]
                  }}</ng-container>
                  <ng-template #defaultRegionTitle>{{ region.title['fi'] }}</ng-template>
                </p>
                <ng-container
                  *ngFor="let municipality of sortAlphabetically(navRegions[i].subpages)"
                >
                  <span>
                    <button
                      [ngClass]="
                        municipality.hasHoods ? 'muncipality-name-bold' : 'muncipality-name'
                      "
                      mat-menu-item
                      [routerLink]="(currentLang ? '/' + currentLang : '') + municipality.route"
                    >
                      <ng-container
                        *ngIf="municipality.title[lang]; else defaultMunicipalityTitle"
                        >{{ municipality.title[lang] }}</ng-container
                      >
                      <ng-template #defaultMunicipalityTitle>{{
                        municipality.title['fi']
                      }}</ng-template>
                    </button>
                  </span>
                </ng-container>
              </div>
            </ng-container>
          </mat-menu>
        </span>
      </ng-container>

      <span class="nav-divider-left"></span>

      <a
        class="capitalize-first"
        *ngIf="!isLoggedIn()"
        [routerLink]="(currentLang ? '/' + currentLang : '') + '/login'"
      >
        <fa-icon class="lock-icon" [icon]="faLock"></fa-icon>{{ 'LOGIN.NAVLINK' | translate }}
      </a>

      <ng-container *ngIf="isLoggedIn()">
        <fa-icon [icon]="faUser"></fa-icon>
        <a class="capitalize-first" [matMenuTriggerFor]="profile" routerLinkActive="active"
          >{{ 'PROFILE.NAVLINK' | translate }}<span class="arrow-down-profile"></span
        ></a>
        <mat-menu #profile="matMenu">
          <button mat-menu-item [routerLink]="(currentLang ? '/' + currentLang : '') + '/profile'">
            {{ 'GOTO_PROFILE.NAVLINK' | translate }}
          </button>
          <button *ngIf="userHoodId && userHoodSlug" mat-menu-item (click)="gotoUserHood()">
            {{ 'GOTO_HOOD.NAVLINK' | translate }}
          </button>
          <button mat-menu-item (click)="logout()">
            {{ 'LOGOUT.NAVLINK' | translate }}
          </button>
        </mat-menu>
      </ng-container>

      <span class="nav-divider-left"></span>

      <a class="lang-menu-label" for="langdropdown">{{ 'SELECT_LANG_NAV' | translate }}</a>

      <div class="lang-menu">
        <!-- <label for="langdropdown">{{ 'SELECT_LANG' | translate }}</label>

          <select id="langdropdown" matNativeControl #langSelect (change)="changeLang(langSelect.value)">
            <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang | translate }}</option>
          </select> -->
        <button
          id="langdropdown"
          mat-menu-item
          [matMenuTriggerFor]="langSelectMenu"
          routerLinkActive="active"
        >
          {{ currentLang | uppercase }}<span class="arrow down"></span>
        </button>
        <mat-menu #langSelectMenu="matMenu">
          <button
            *ngFor="let lang of translate.getLangs()"
            class="lang-button"
            (click)="changeLang(lang)"
            mat-menu-item
          >
            {{ lang | translate }}
          </button>
        </mat-menu>
      </div>
    </span>
  </nav>
</mat-toolbar>

<!-- Here we will load the current view: -->
<router-outlet></router-outlet>

<footer>
  <a href="https://cyf.fi/" target="_blank">
    <span>Powered by CYF Digital</span>
  </a>
</footer>

<!--<div class="disclaimer" [class.nudge-up]="showUp && showFeedbackBubble" *ngIf="showDisclaimer">
  <div class="row">
    <div class="column">
      <div [innerHtml]="'POPUP.POLICY.TEXT' | translate"></div>
    </div>
    <div class="column action">
      <button class="mat-flat-button text"
        (click)="termsAccepted()">{{ 'POPUP.POLICY.BUTTON' | translate }}</button>
    </div>
    <button class="icon filter-close" (click)="termsAccepted()" *xs>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>-->

<app-fab-feedback [currentLanguage]="currentLang"></app-fab-feedback>
<app-scroll-up></app-scroll-up>
