import { HttpClient, HttpHeaders } from '@angular/common/http';
import { endpoints } from '../../configs/sources';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
var VotingService = /** @class */ (function () {
    function VotingService(http) {
        this.http = http;
        this.API_URL = endpoints.votes;
    }
    VotingService.prototype.getVotes = function (type, id) {
        return this.http.get(this.API_URL + '?type=' + type + '&id=' + id);
    };
    VotingService.prototype.getFeedback = function (type, id, ip) {
        return this.http.get(this.API_URL + '?type=' + type + '&id=' + id + '&ip=' + ip);
    };
    VotingService.prototype.updateVote = function (vote) {
        return this.http.put(this.API_URL, vote);
    };
    VotingService.prototype.getIpAddress = function () {
        return this.http.get('https://hoods-users.azurewebsites.net/users/headers');
    };
    VotingService.ngInjectableDef = i0.defineInjectable({ factory: function VotingService_Factory() { return new VotingService(i0.inject(i1.HttpClient)); }, token: VotingService, providedIn: "root" });
    return VotingService;
}());
export { VotingService };
