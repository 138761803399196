import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { base, endpoints } from '../../configs/sources';
import { TranslateService } from '@ngx-translate/core';
import { formatUrl, mustUrlProtocol, formatUrl2 } from '../../utils/urlUtils';
import { LocationSource } from '../../models/LocationSource';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  endpoint = endpoints.locationtypes;
  endpoint2 = endpoints.locations;
  endpoint3 = endpoints.locationscats;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient, private translate: TranslateService) {}

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // console.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  getCategories(): Observable<any> {
    return this.http.get(this.endpoint3).pipe(map(this.extractData));
  }

  getTypes(): Observable<any> {
    return this.http.get(this.endpoint).pipe(map(this.extractData));
  }

  getLocations(): Observable<any> {
    return this.http.get(this.endpoint2).pipe(map(this.extractData));
  }

  getLocationsBbox(bbox: string, simplified = false): Observable<any> {
    return this.http
      .get(`${this.endpoint2}?bbox=${bbox}&simplified=${simplified}`)
      .pipe(map(this.extractData));
  }

  getLocation(id: number, simplified?: boolean): Observable<any> {
    let url = this.endpoint2 + id;
    if (simplified) {
      url += '?simplified=true';
    }
    return this.http.get(url).pipe(map(this.extractData));
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (error) {
      return false;
    }
    return true;
  }

  public getTranslation(point: any, lang: string): locationTranslation {
    const ret: locationTranslation = {
      name: point.name,
      brandName: '',
      contactInfo: '',
      description: point.description ? point.description : '',
      summary: '',
      url: point.url,
    };
    if (!point.description || !this.isJson(point.description)) return ret;

    const translations = JSON.parse(point.description) as translationObj[];

    interface translationObj {
      language: string;
      value: string;
      type: 'Description' | 'Summary' | 'Name' | 'BrandName' | 'ContactInfo' | 'URL';
    }
    const setTranslation = (t: translationObj) => {
      if (t.type === 'Description') {
        ret.description = t.value;
      } else if (t.type === 'Summary') {
        ret.summary = t.value;
      } else if (t.type === 'Name') {
        ret.name = t.value;
      } else if (t.type === 'BrandName') {
        ret.brandName = t.value;
      } else if (t.type === 'ContactInfo') {
        ret.contactInfo = t.value;
      } else if (t.type === 'URL') {
        ret.url = t.value;
      }
    };
    // Set fallback values first
    for (const t of translations.filter((x) => x.language === 'fi')) {
      setTranslation(t);
    }
    if (lang === 'fi') {
      return ret;
    }

    // Set correct language values
    for (const t of translations.filter((x) => x.language === lang)) {
      setTranslation(t);
    }
    return ret;
  }

  public async locationPopupText(
    point: any,
    currentLang: string,
    iconString: String,
  ): Promise<string> {
    const icons = ['utensils', 'home', 'building', 'briefcase', 'hotel'];
    const icon = '<i style="font-size: 1.3em;" class="fas fa-' + `${iconString}` + '"></i>';

    let osmUrl;

    let popupContent = '';
    const strings = await this.translate
      .get([
        'LOCATIONS.RECOMMEND_CHANGES',
        'LOCATIONS.OSM_EXPLANATION',
        'LOCATIONS.GO_TO_OSM_MAP',
        'LOCATIONS.OSM_TITLE',
      ])
      .toPromise();
    let description = '';
    const translations = this.getTranslation(point, currentLang);
    if (point.sourceId === LocationSource.OpenStreetMapHoods) {
      let latitude = point.point.geometry.coordinates[1];
      let longitude = point.point.geometry.coordinates[0];
      if (point.externalId) {
        osmUrl = `https://www.openstreetmap.org/node/${point.externalId}#map=18/${latitude}/${longitude}`;
      } else {
        osmUrl = `https://www.openstreetmap.org/query?lat=${latitude}&lon=${longitude}#map=18/${latitude}/${longitude}`;
      }
    }
    description = translations.description;
    if (point.sourceId === LocationSource.Ptv) {
      description = translations.summary ? translations.summary : point.description;
    }
    // All the classes are in styles.scss
    popupContent = `
    <div class="location-card">
      <div class="location-card-title">
        <div class="card-title-icon">${icon}</div>
        <div class="card-title-text">
          <div class="card-title-name"><span>${translations.name}</span></div>
        </div>
      </div>

      ${
        point.imageUrl
          ? `<div class="card-image" style="background-image:url(${point.picture});">
      </div>`
          : '<div class="card-divider-top"><div class="card-divider"></div></div>'
      }

      ${
        description
          ? `<div class="card-description">
          <p>${description}</p>
        </div>`
          : ``
      }
      <div class="card-content-wrapper">
      ${
        point.address
          ? `<div class="card-row">
            <div class="card-row-container flex">
              <i style="font-size: 1.3em;margin-right: 0.8em;" class="fas fa-map-marker-alt"></i>
              <span>${point.address}${point.city ? ', ' + point.city : ''}</span>
            </div>
          </div>`
          : ''
      }

      ${
        translations.url
          ? `<div class="card-row flex">
              <div class="card-row-container url-container">
                <i style="font-size: 1.3em;margin-right: 0.8em;" class="fas fa-globe"></i>
                <a href="${mustUrlProtocol(translations.url)}" target="_blank">${formatUrl2(
              translations.url,
            )}</a>
              </div>
              <div class="">
                <i style="font-size: 1.3em;" class="fas fa-external-link-alt"></i>
              </div>
          </div>`
          : ''
      }
      </div>


      ${
        osmUrl
          ? `

      <div class="card-divider"></div>
      <div id="feedbackPopup" class="hide-modal feedbackPopupBtn">
          <span class="card-link">${strings['LOCATIONS.RECOMMEND_CHANGES']}</span>
          <div class="feedback-modal">
            <button class="closeFeedbackBtn leaflet-popup-close-button">×</button>
            <div class="header">
              <h3>${strings['LOCATIONS.OSM_TITLE']}</h3>
            </div>
            <div class="content">
              <p>${strings['LOCATIONS.OSM_EXPLANATION']}</p>
            </div>
            <a class="card-link card-btn"
              href="${osmUrl}"
              style="display:block;"
              target="_blank">
                <span>${strings['LOCATIONS.GO_TO_OSM_MAP']}</span>
                <i class="fas fa-external-link-alt"></i>
            </a>
          </div>
      </div>

      `
          : ''
      }

      </div>
      `;
    return popupContent;
  }
}
export interface locationTranslation {
  name: string;
  description: string;
  summary: string;
  brandName: string;
  contactInfo: string;
  url: string | undefined;
}
