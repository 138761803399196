import { Injectable } from '@angular/core';
import { MunicipalitiesService, Municipality } from '../municipalities/municipalities.service';

@Injectable({
  providedIn: 'root',
})
export class SlugService {
  constructor(private municipalityService: MunicipalitiesService) {}

  async getCityIdBySlug(slug: string): Promise<Municipality> {
    const data = await this.municipalityService.getMunicipalities().toPromise();
    let res = data.municipalities.find((m) => m.slug == slug);
    if (res !== undefined) {
      return res;
    } else {
      throw new Error('Municipality not found.');
    }
  }
}
