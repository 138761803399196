import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { tap } from 'rxjs/operators';
import { endpoints } from '../../configs/sources';
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
var AuthService = /** @class */ (function () {
    function AuthService(http, tokenStorage) {
        this.http = http;
        this.tokenStorage = tokenStorage;
        this.isUserTester = new Subject();
        // HTTP root
        this.ROOT_URL = endpoints.auth;
    }
    Object.defineProperty(AuthService.prototype, "facebookAuthUrl", {
        get: function () {
            return this.ROOT_URL + '/facebook';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "googleAuthUrl", {
        get: function () {
            return this.ROOT_URL + '/google';
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.setIsUserTester = function (value) {
        this.isUserTester.next(value);
    };
    AuthService.prototype.loginUser = function (post) {
        return this.http.post(this.ROOT_URL + '/login', {
            email: post.email,
            password: post.password,
        }, httpOptions);
    };
    AuthService.prototype.logoutUser = function () {
        var location = window.location.host;
        if (location == 'dev.hoods.fi' || location == 'beta.hoods.fi' || location == 'localhost:4200') {
            this.setIsUserTester(true);
        }
        else {
            this.setIsUserTester(false);
        }
        this.tokenStorage.signOut();
    };
    AuthService.prototype.refreshToken = function (temporary) {
        var _this = this;
        if (temporary === void 0) { temporary = false; }
        var refreshToken = temporary
            ? this.tokenStorage.getTemporaryRefreshToken()
            : this.tokenStorage.getRefreshToken();
        return this.http
            .post(this.ROOT_URL + '/refresh-token', {
            refreshToken: refreshToken,
        }, httpOptions)
            .pipe(tap(function (res) {
            if (temporary) {
                _this.tokenStorage.saveTemporaryToken(res.accessToken);
                _this.tokenStorage.saveTemporaryRefreshToken(res.refreshToken);
                return;
            }
            _this.tokenStorage.saveToken(res.accessToken);
            _this.tokenStorage.saveRefreshToken(res.refreshToken);
        }));
    };
    return AuthService;
}());
export { AuthService };
