import { Translation } from '../services/events/event.model';

export function getTranslation(translations: Translation, language: string) {
  if (!translations) {
    return '';
  }
  if (!translations[language]) {
    if (!translations.fi) {
      return '';
    }
    return translations.fi;
  }
  return translations[language];
}
