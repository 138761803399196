<div class="feedback-box" *ngIf="showFeedback">
  <button class="close" (click)="toggleBubble()">
    <mat-icon>close</mat-icon>
    <span class="sr-only">{{ 'AC.BUTTON.CLOSE' | translate }}</span>
  </button>
  <h3>{{ 'FEEDBACK_BUBBLE.TITLE' | translate }}</h3>
  <p [innerHtml]="'FEEDBACK_BUBBLE.CONTENT' | translate"></p>
  <button
    [routerLink]="(currentLanguage ? '/' + currentLanguage : '') + '/info/feedback'"
    (click)="toggleBubble()"
    class="mat-flat-button"
  >
    {{ 'FEEDBACK_BUBBLE.BUTTON' | translate }}
  </button>
</div>

<button class="floating feedback-bubble" (click)="toggleBubble()" *ngIf="showFeedbackBubble">
  <fa-icon icon="comment-alt"></fa-icon>
  <span class="sr-only">{{ 'AC.BUTTON.FEEDBACK' | translate }}</span>
</button>
