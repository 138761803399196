import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { endpoints } from '../../configs/sources';
import { StorageService } from 'ngx-webstorage-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-webstorage-service";
var TextsService = /** @class */ (function () {
    function TextsService(http, storage) {
        var _this = this;
        this.http = http;
        this.storage = storage;
        this.endpoint = endpoints.texts;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        this.availableLanguages = [];
        this.addAvailableLanguage = function (language) {
            if (!_this.availableLanguages.includes(language)) {
                _this.availableLanguages.push(language);
            }
        };
        this.getAvailableLanguages = function () {
            return _this.availableLanguages;
        };
    }
    TextsService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    TextsService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            // console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            // console.log(`${operation} failed: ${error.message}`);
            return of(result);
        };
    };
    TextsService.prototype.parseStrings = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.storage.has('app_texts')) {
                var translations = _this.storage.get('app_texts');
                Object.keys(translations).forEach(function (key) {
                    _this.addAvailableLanguage(key);
                });
                resolve(translations);
            }
            else {
                var translations_1 = {};
                _this.getTexts().subscribe(function (results) {
                    if (results.data && results.data.length) {
                        results.data.forEach(function (translationString) {
                            if (translationString.translations && translationString.translations.length) {
                                translationString.translations.forEach(function (translation) {
                                    if (translation.translation && translation.translation.length) {
                                        var path_1 = translationString.name.split('.');
                                        if (!translations_1[translation.languages_code.split('-')[0]]) {
                                            translations_1[translation.languages_code.split('-')[0]] = {};
                                        }
                                        var temporaryObject_1 = translations_1[translation.languages_code.split('-')[0]];
                                        if (temporaryObject_1) {
                                            path_1.forEach(function (pathPart, index) {
                                                if (index !== path_1.length - 1) {
                                                    if (!temporaryObject_1[pathPart]) {
                                                        temporaryObject_1[pathPart] = {};
                                                    }
                                                    temporaryObject_1 = temporaryObject_1[pathPart];
                                                }
                                                else {
                                                    temporaryObject_1[pathPart] = translation.translation;
                                                }
                                            });
                                        }
                                    }
                                });
                            }
                        });
                    }
                    _this.storage.set('app_texts', translations_1);
                    Object.keys(translations_1).forEach(function (key) {
                        _this.addAvailableLanguage(key);
                    });
                    resolve(translations_1);
                });
            }
        });
    };
    TextsService.prototype.getTexts = function () {
        return this.http.get(this.endpoint).pipe(map(this.extractData));
    };
    TextsService.ngInjectableDef = i0.defineInjectable({ factory: function TextsService_Factory() { return new TextsService(i0.inject(i1.HttpClient), i0.inject(i2.SESSION_STORAGE)); }, token: TextsService, providedIn: "root" });
    return TextsService;
}());
export { TextsService };
