import * as i0 from "@angular/core";
var TOKEN_KEY = 'auth-token';
var REFRESH_TOKEN_KEY = 'refresh-token';
var USER_KEY = 'auth-user';
var TEMPORARY_TOKEN_KEY = 'temporary-auth-token';
var TEMPORARY_REFRESH_TOKEN_KEY = 'temporary-refresh-token';
var TEMPORARY_USER_KEY = 'temporary-auth-user';
var TEST_USER = 'is_test_user';
var TokenStorageService = /** @class */ (function () {
    function TokenStorageService() {
    }
    TokenStorageService.prototype.signOut = function () {
        window.sessionStorage.clear();
        localStorage.clear();
    };
    TokenStorageService.prototype.removeTemporaryLogin = function () {
        window.sessionStorage.removeItem(TEMPORARY_TOKEN_KEY);
        window.sessionStorage.removeItem(TEMPORARY_REFRESH_TOKEN_KEY);
        window.sessionStorage.removeItem(TEMPORARY_USER_KEY);
    };
    TokenStorageService.prototype.saveToken = function (token) {
        window.sessionStorage.removeItem(TOKEN_KEY);
        window.sessionStorage.setItem(TOKEN_KEY, token);
    };
    TokenStorageService.prototype.saveTemporaryToken = function (token) {
        window.sessionStorage.removeItem(TEMPORARY_TOKEN_KEY);
        window.sessionStorage.setItem(TEMPORARY_TOKEN_KEY, token);
    };
    TokenStorageService.prototype.saveRefreshToken = function (token) {
        window.sessionStorage.removeItem(REFRESH_TOKEN_KEY);
        window.sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
    };
    TokenStorageService.prototype.saveTemporaryRefreshToken = function (token) {
        window.sessionStorage.removeItem(TEMPORARY_REFRESH_TOKEN_KEY);
        window.sessionStorage.setItem(TEMPORARY_REFRESH_TOKEN_KEY, token);
    };
    TokenStorageService.prototype.getToken = function () {
        return sessionStorage.getItem(TOKEN_KEY);
    };
    TokenStorageService.prototype.getTemporaryToken = function () {
        return sessionStorage.getItem(TEMPORARY_TOKEN_KEY);
    };
    TokenStorageService.prototype.getRefreshToken = function () {
        return sessionStorage.getItem(REFRESH_TOKEN_KEY);
    };
    TokenStorageService.prototype.getTemporaryRefreshToken = function () {
        return sessionStorage.getItem(TEMPORARY_REFRESH_TOKEN_KEY);
    };
    TokenStorageService.prototype.saveUser = function (user) {
        window.sessionStorage.removeItem(USER_KEY);
        window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    };
    TokenStorageService.prototype.saveTemporaryUser = function (user) {
        window.sessionStorage.removeItem(TEMPORARY_USER_KEY);
        window.sessionStorage.setItem(TEMPORARY_USER_KEY, JSON.stringify(user));
    };
    TokenStorageService.prototype.getTemporaryUser = function () {
        return JSON.parse(sessionStorage.getItem(TEMPORARY_USER_KEY));
    };
    TokenStorageService.prototype.getCurrentUser = function () {
        return JSON.parse(sessionStorage.getItem(USER_KEY));
    };
    TokenStorageService.prototype.saveTestUser = function (showDrafts) {
        window.sessionStorage.setItem(TEST_USER, showDrafts);
    };
    TokenStorageService.prototype.getTestUser = function () {
        return sessionStorage.getItem(TEST_USER);
    };
    TokenStorageService.ngInjectableDef = i0.defineInjectable({ factory: function TokenStorageService_Factory() { return new TokenStorageService(); }, token: TokenStorageService, providedIn: "root" });
    return TokenStorageService;
}());
export { TokenStorageService };
