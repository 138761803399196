import { BehaviorSubject, Subject } from 'rxjs';
import { AreaFeatureType, } from '../services/services.service';
import { LeafletMessageBoxService } from '../../services/leaflet-messagebox/leafletMessageBox.service';
import { TranslateService } from '@ngx-translate/core';
import * as turf from '@turf/turf';
import * as i0 from "@angular/core";
import * as i1 from "../../services/leaflet-messagebox/leafletMessageBox.service";
import * as i2 from "@ngx-translate/core";
var SimpleMapService = /** @class */ (function () {
    function SimpleMapService(leafletMessageboxService, translate) {
        this.leafletMessageboxService = leafletMessageboxService;
        this.translate = translate;
        this.zoomToMarkerToken = new Subject();
        this.zoomToMarkerToken$ = this.zoomToMarkerToken.asObservable();
        this.mapServicesSubject = new BehaviorSubject(undefined);
        this.mapServices$ = this.mapServicesSubject.asObservable();
    }
    SimpleMapService.prototype.emitZoomToMarker = function (id) {
        this.zoomToMarkerToken.next(id);
    };
    SimpleMapService.prototype.loadMapServices = function (services) {
        this.mapServicesSubject.next(services);
    };
    SimpleMapService.prototype.showLoading = function (map) {
        this.leafletMessageboxService.createControl(map);
        var loadingMessage = this.translate.instant('MAP.LOADING_SERVICE_POINTS');
        this.leafletMessageboxService.showMessage("<i class=\"fa fas fa-icon fa-spinner spinner-fast\"></i> " + loadingMessage, 30000);
    };
    SimpleMapService.prototype.hideLoading = function () {
        this.leafletMessageboxService.hideMessage();
    };
    SimpleMapService.prototype.setServicesCompare = function (services) {
        this.servicesCompare = services;
    };
    SimpleMapService.prototype.getServicesCompare = function () {
        return this.servicesCompare;
    };
    SimpleMapService.prototype.setHoodArea = function (area) {
        this.hoodArea = area;
    };
    SimpleMapService.prototype.getHoodArea = function () {
        return this.hoodArea;
    };
    /**
     * Takes a Point and a Polygon or MultiPolygon and determines if the
     * point resides inside the polygon. The polygon can be convex or concave.
     * The function accounts for holes.
     * @param point
     * @param polygon
     * @returns True if the point resides inside the polygon
     */
    SimpleMapService.prototype.isPointInPolygon = function (point, polygon) {
        var featureType = polygon.type;
        var comparePolygon;
        if (featureType === AreaFeatureType.Polygon) {
            comparePolygon = turf.polygon(polygon.coordinates);
        }
        if (featureType === AreaFeatureType.MultiPolygon) {
            comparePolygon = turf.multiPolygon(polygon.coordinates);
        }
        var comparePoint = turf.point(point);
        var containsPoint = turf.booleanPointInPolygon(comparePoint, comparePolygon);
        return containsPoint;
    };
    SimpleMapService.ngInjectableDef = i0.defineInjectable({ factory: function SimpleMapService_Factory() { return new SimpleMapService(i0.inject(i1.LeafletMessageBoxService), i0.inject(i2.TranslateService)); }, token: SimpleMapService, providedIn: "root" });
    return SimpleMapService;
}());
export { SimpleMapService };
