<section class="content" #content>
  <div class="row">
    <div class="column">
      <img
        src="assets/images/404.svg"
        [alt]="'ALT_TEXT.RESULTS.NO_RESULTS' | translate"
        class="not-found-img"
      />
    </div>
    <div class="column">
      <h1>{{ '404_TITLE' | translate }}</h1>
      <p [innerHTML]="'404_CONTENT' | translate"></p>
      <button class="mat-flat-button" (click)="goHome()">{{ '404_BUTTON' | translate }}</button>
    </div>
  </div>
</section>
