import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      cancel: string;
      confirm: string;
    },
    private dialog: MatDialogRef<ConfirmDialogComponent>,
  ) {}

  ngOnInit() {}

  public cancel() {
    this.close(false);
  }

  public confirm() {
    this.close(true);
  }

  public close(value) {
    this.dialog.close(value);
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.close(false);
  }
}
