import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { base } from '../../configs/sources';
import { LotTranslation, Property } from './property.model';

interface ExtendedTranslation extends LotTranslation {
  linkDescription?: string;
}
@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  getTranslation(lot: Property, currentLang: string): ExtendedTranslation {
    const translations = lot.translations;

    let name = lot.name;
    let address = lot.address;
    let description = lot.description;
    let seller = '';
    let externalLink = lot.externalLink;
    let areaSize = lot.areaSize && lot.areaSize.toString();
    let salePrice = lot.salesPrice && lot.salesPrice.toString();

    if (lot.translations && translations.length > 0) {
      let lotTranslation = translations.find((x) => x.language === currentLang);
      if (lotTranslation) {
        if (lotTranslation.name) name = lotTranslation.name;
        if (lotTranslation.description) description = lotTranslation.description;
        if (lotTranslation.seller) seller = lotTranslation.seller;
        if (lotTranslation.address) address = lotTranslation.address;
        if (lotTranslation.externalLink) externalLink = lotTranslation.externalLink;
        if (lotTranslation.areaSize) areaSize = lotTranslation.areaSize;
        if (lotTranslation.salePrice) salePrice = lotTranslation.salePrice;
      }
    }
    const linkRegex = /(http[^\s]*)/;
    let linkDescription: string | undefined;
    if (externalLink) {
      const linkRes = linkRegex.exec(externalLink);
      if (linkRes) {
        linkDescription = externalLink.slice(linkRes[1].length).trim();
        externalLink = linkRes[1];
      }
    }
    if (areaSize) {
      if (areaSize.match(/^\d+$/)) {
        areaSize += ' m²';
      }
    }
    if (salePrice) {
      if (salePrice.match(/^\d+$/)) {
        salePrice += ' €';
      }
    }

    return {
      language: '',
      address,
      description,
      name,
      seller,
      areaSize,
      externalLink,
      salePrice,
      linkDescription,
    };
  }

  getProperties(): Observable<GetPropertiesResult> {
    return this.http.get<GetPropertiesResult>(base + '/lots');
  }

  getPropertiesBbox(bbox: string): Observable<GetPropertiesResult> {
    return this.http.get<GetPropertiesResult>(`${base}/lots?bbox=${bbox}`);
  }

  getMunicipalityProperties(municipalityId: number): Observable<GetPropertiesResult> {
    return this.http.get<GetPropertiesResult>(base + '/municipalities/' + municipalityId + '/lots');
  }

  getHoodProperties(hoodId: number): Observable<GetPropertiesResult> {
    return this.http.get<GetPropertiesResult>(base + '/hoods/' + hoodId + '/lots');
  }

  getDetails(municipalityId: number, id: number): Observable<any> {
    return this.http.get<any>(base + '/municipalities/' + municipalityId + '/details/' + id);
  }
  getPictures(ids: number[]): Observable<LotPicResponse> {
    if (ids.length === 0) return of({ lotPictures: [] });

    return this.http.get<LotPicResponse>(`${base}/lots/${ids.join(',')}/pictures`);
  }
}

export interface GetPropertiesResult {
  error: null;
  lots: Property[];
}
export interface LotPicResponse {
  lotPictures: LotPicture[];
}
export interface LotPicture {
  id: number;
  lotId: number;
  type: number;
  url: string;
}
