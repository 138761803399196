import { Injectable } from '@angular/core';
import { LocationsService } from '../locations/locations.service';
import { HttpClient } from '@angular/common/http';
import { endpoints } from '../../configs/sources';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SelectedServiceFilters {
  sources?: number[];
  categories?: number[];
  showMunicipalityServices?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ServicesFilterService {
  private defaultSelectedServiceFilters: SelectedServiceFilters = {
    sources: [12],
    categories: [],
    showMunicipalityServices: false,
  };

  private updatedServices = new BehaviorSubject(true);
  public updateServices$ = this.updatedServices.asObservable();

  private servicesFiltersChanged = new BehaviorSubject<SelectedServiceFilters>(
    this.defaultSelectedServiceFilters,
  );
  public servicesFiltersChanged$ = this.servicesFiltersChanged.asObservable();
  public filters: SelectedServiceFilters;

  public readonly ASSOCIATION_CATEGORY = {
    id: 15,
    name: 'Yhdistykset',
  };

  constructor(private locationService: LocationsService, private http: HttpClient) {
    this.servicesFiltersChanged$.subscribe((data) => {
      this.filters = data;
      this.refreshServices(true);
    });
  }

  async getCategories() {
    const parents = await this.getParentCategories()
      .pipe(
        map((data) => {
          // Show only the association category
          const associationCategory = data.locationCategories.filter(
            (parentCategory) => parentCategory.id === this.ASSOCIATION_CATEGORY.id,
          );
          return associationCategory;
        }),
      )
      .toPromise()
      .then((data) => data);

    const childs = await this.getChildCategories()
      .pipe(
        map((data) => {
          // Remove majoitus (see tickets 11237, 12277) and show only association categories.
          const filtered = data.locationTypes.filter(
            (type) => type.id !== 5 && type.locationCategoryId === this.ASSOCIATION_CATEGORY.id,
          );
          return filtered;
        }),
      )
      .toPromise()
      .then((data) => {
        const filters = this.filters.categories;
        data.forEach((locationType) => {
          if (filters.includes(locationType.id)) {
            locationType.selected = true;
          } else {
            locationType.selected = false;
          }
        });
        return data;
      });

    const sources = await this.getDataSources()
      .toPromise()
      .then((data) => {
        const filters = this.filters.sources;
        data.data.forEach((source) => {
          if (filters.includes(source.code)) {
            source.selected = true;
          } else {
            source.selected = false;
          }
        });
        return data.data;
      });
    return { parents, childs, sources };
  }

  getParentCategories() {
    return this.locationService.getCategories();
  }

  getChildCategories() {
    return this.locationService.getTypes();
  }

  getDataSources(): Observable<any> {
    const fields = [
      'id',
      'abbreviation',
      'code',
      'translations.languages_code',
      'translations.name',
      'translations.description',
    ];
    return this.http.get(endpoints.directusDataSource, {
      params: {
        fields: fields.join(','),
        'filter[status]': 'published',
      },
    });
  }

  setSelectedServicesFilters(selectedFilters: SelectedServiceFilters) {
    const isNullOrUndefined =
      selectedFilters.showMunicipalityServices === null ||
      selectedFilters.showMunicipalityServices === undefined;
    const toSave: SelectedServiceFilters = {
      categories: selectedFilters.categories ? selectedFilters.categories : this.filters.categories,
      sources: selectedFilters.sources ? selectedFilters.sources : this.filters.sources,
      showMunicipalityServices: !isNullOrUndefined
        ? selectedFilters.showMunicipalityServices
        : this.filters.showMunicipalityServices,
    };
    this.servicesFiltersChanged.next(toSave);
  }

  refreshServices(updateMap: boolean) {
    this.updatedServices.next(updateMap);
  }

  getSourceBadgeCount(selectedSources) {
    return selectedSources.filter((source) => source.selected).length;
  }
}
