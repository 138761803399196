<div class="pagination">
  <!-- Previous page button -->
  <button [disabled]="paginationOptionsRef.pageIndex === 1" (click)="prevPage()" class="prev">
    <i class="fas fa-chevron-left"></i>
    <span class="sr-only">{{ 'AC.PREV_PAGE' | translate }}</span>
  </button>

  <!-- Selectable page numbers -->
  <button
    *ngFor="
      let page of getPageArray().slice(getPageOffset(), maxPageNumbersToShow());
      let i = index
    "
    [disabled]="i + getPageOffset() == paginationOptionsRef.pageIndex - 1"
    (click)="goToPage(i)"
    class="page"
    [class.selected]="i + getPageOffset() == paginationOptionsRef.pageIndex - 1"
  >
    <span class="sr-only">{{ 'AC.PAGE' | translate }} </span>
    {{ getPageOffset() + i + 1 }}
  </button>

  <!-- Next page button -->
  <button
    [disabled]="paginationOptionsRef.pageIndex === getPageArray().length"
    (click)="nextPage()"
    class="next"
  >
    <i class="fas fa-chevron-right"></i>
    <span class="sr-only">{{ 'AC.NEXT_PAGE' | translate }}</span>
  </button>
</div>
