import { HttpClient, HttpHeaders } from '@angular/common/http';
import { endpoints } from '../../configs/sources';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
        this.API_URL = endpoints.users;
    }
    UserService.prototype.register = function (user) {
        return this.http.post(this.API_URL, user);
    };
    UserService.prototype.getUsers = function () {
        return this.http.get(this.API_URL, httpOptions);
    };
    UserService.prototype.getUserById = function (id) {
        return this.http.get(this.API_URL + id, httpOptions);
    };
    UserService.prototype.getUserStatus = function (id) {
        return this.http.get(this.API_URL + id + '/status', httpOptions);
    };
    UserService.prototype.getUserByIdWithDetails = function (id) {
        return this.http.get(this.API_URL + id + '/all', httpOptions);
    };
    UserService.prototype.getUserDetailsById = function (id) {
        return this.http.get(this.API_URL + id + '/details', httpOptions);
    };
    UserService.prototype.updateUser = function (id, userData, opts) {
        var url = this.API_URL + id;
        if (opts && opts.skipEmail) {
            url += "?skipEmail=true";
        }
        return this.http.put(url, userData, httpOptions);
    };
    UserService.prototype.deleteUser = function (id) {
        return this.http.delete(this.API_URL + id, httpOptions);
    };
    UserService.prototype.resetPassword = function (email) {
        return this.http.get(this.API_URL + 'reset-password?email=' + email);
    };
    UserService.prototype.modifyPassword = function (id, password) {
        return this.http.put(this.API_URL + 'modify-password?id=' + id, password, httpOptions);
    };
    UserService.prototype.resendVerification = function (payload) {
        return this.http.post(this.API_URL + 'resend-verification', payload);
    };
    UserService.prototype.updateProfilePicture = function (id, pictureData) {
        return this.http.post(this.API_URL + id + '/profile-picture', pictureData);
    };
    UserService.prototype.deleteProfilePicture = function (id) {
        return this.http.delete(this.API_URL + id + '/profile-picture');
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
