import { Component, Inject, Input, OnInit } from '@angular/core';
import { LotPicture, PropertyService } from '../../../services/properties/properties.service';
import { Property, PropertyHood } from '../../../services/properties/property.model';

@Component({
  selector: 'app-lot-content',
  templateUrl: './lot-content.component.html',
  styleUrls: ['./lot-content.component.scss'],
})
export class LotContentComponent implements OnInit {
  @Input() public data: any;

  public image = {} as LotPicture | undefined;
  public lotTranslation: any;
  public selectedLot = {} as Property;
  public currentLanguage = 'fi';
  public addressArea = '';
  public municipalitySlug = '';
  public municipalityName = '';
  public hoods = {} as PropertyHood;

  constructor(private propertyService: PropertyService) {}

  ngOnInit() {
    this.initLotData();
  }

  initLotData() {
    this.currentLanguage = this.data.currentLanguage;
    this.hoods = this.data.hoods;
    this.image = this.data.image;
    this.municipalityName = this.data.municipalityName;
    this.municipalitySlug = this.data.municipalitySlug;
    this.selectedLot = this.data.selectedLot;

    if (this.municipalityName) {
      this.addressArea = ', ' + this.municipalityName[this.currentLanguage];
    } else {
      this.addressArea = '';
    }

    this.lotTranslation = this.propertyService.getTranslation(
      this.selectedLot,
      this.currentLanguage,
    );
  }
}
