import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { LocationsService } from '../../../services/locations/locations.service';
import { map, tap } from 'rxjs/operators';
import { Location } from '../../../services/locations/locations.model';
import { mustUrlProtocol } from '../../../utils/urlUtils';
import { TranslateService } from '@ngx-translate/core';
import { SimpleMapComponent } from '../../simple-map/simple-map.component';
import { ServicesService } from '../services.service';
import { SimpleMapService } from '../../simple-map/simple-map.service';

export interface DialogData {
  id: string;
  referer: 'map' | 'item';
}

@Component({
  selector: 'app-services-list-item-details',
  templateUrl: './services-list-item-details.component.html',
  styleUrls: ['./services-list-item-details.component.scss'],
})
export class ServicesListItemDetailsComponent implements OnInit {
  public service$: Observable<Location>;
  public goBackToMap = false;
  // Default to this picture when location has multiple categories
  public multiCategoryPicture =
    'https://hoodsstorage.blob.core.windows.net/categories/yhdistykset_monta_kategoriaa.jpg';

  public featurePicture: string;

  constructor(
    public dialogRef: MatDialogRef<ServicesListItemDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private locationService: LocationsService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private servicesService: ServicesService,
    private simpleMapService: SimpleMapService,
  ) {}

  ngOnInit() {
    if (this.data.referer === 'map') {
      this.goBackToMap = true;
    }
    this.getServiceDetails();
  }

  getServiceDetails() {
    if (!this.data.id) {
      return;
    }

    const locationId = parseInt(this.data.id);

    this.service$ = this.locationService.getLocation(locationId, true).pipe(
      map((response: { error: any; location: Location }) => {
        const translations = this.locationService.getTranslation(
          response.location,
          this.translate.currentLang,
        );
        const parsed = response.location;
        parsed.url = translations.url;
        parsed.name = translations.name;
        parsed.description = translations.description;
        this.featurePicture = response.location.locationTypes[0].picture;
        if (response.location.locationTypes.length > 1) {
          this.featurePicture = this.multiCategoryPicture;
        }
        return parsed;
      }),
    );
  }

  editLocation(externalId: string) {
    const url = 'https://www.lahella.fi/organisation/' + externalId;
    window.open(url, '_blank');
  }

  fixUrl(url: string) {
    return mustUrlProtocol(url);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openMapDialog() {
    if (this.goBackToMap) {
      this.closeDialog();
      this.simpleMapService.emitZoomToMarker(parseInt(this.data.id));
      return;
    }
    const dialogRef = this.dialog.open(SimpleMapComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      panelClass: 'simple-map-dialog',
      data: {
        zoomToMarkerId: this.data.id,
      },
    });
    dialogRef.afterOpened().subscribe(() => {
      this.servicesService.setIsMobileMapOpen(true);
      this.servicesService.setIsItemDetailsOpen(false);
    });
  }
}
