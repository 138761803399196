import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { base } from '../../configs/sources';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PropertyService = /** @class */ (function () {
    function PropertyService(http) {
        this.http = http;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
    }
    PropertyService.prototype.getTranslation = function (lot, currentLang) {
        var translations = lot.translations;
        var name = lot.name;
        var address = lot.address;
        var description = lot.description;
        var seller = '';
        var externalLink = lot.externalLink;
        var areaSize = lot.areaSize && lot.areaSize.toString();
        var salePrice = lot.salesPrice && lot.salesPrice.toString();
        if (lot.translations && translations.length > 0) {
            var lotTranslation = translations.find(function (x) { return x.language === currentLang; });
            if (lotTranslation) {
                if (lotTranslation.name)
                    name = lotTranslation.name;
                if (lotTranslation.description)
                    description = lotTranslation.description;
                if (lotTranslation.seller)
                    seller = lotTranslation.seller;
                if (lotTranslation.address)
                    address = lotTranslation.address;
                if (lotTranslation.externalLink)
                    externalLink = lotTranslation.externalLink;
                if (lotTranslation.areaSize)
                    areaSize = lotTranslation.areaSize;
                if (lotTranslation.salePrice)
                    salePrice = lotTranslation.salePrice;
            }
        }
        var linkRegex = /(http[^\s]*)/;
        var linkDescription;
        if (externalLink) {
            var linkRes = linkRegex.exec(externalLink);
            if (linkRes) {
                linkDescription = externalLink.slice(linkRes[1].length).trim();
                externalLink = linkRes[1];
            }
        }
        if (areaSize) {
            if (areaSize.match(/^\d+$/)) {
                areaSize += ' m²';
            }
        }
        if (salePrice) {
            if (salePrice.match(/^\d+$/)) {
                salePrice += ' €';
            }
        }
        return {
            language: '',
            address: address,
            description: description,
            name: name,
            seller: seller,
            areaSize: areaSize,
            externalLink: externalLink,
            salePrice: salePrice,
            linkDescription: linkDescription,
        };
    };
    PropertyService.prototype.getProperties = function () {
        return this.http.get(base + '/lots');
    };
    PropertyService.prototype.getPropertiesBbox = function (bbox) {
        return this.http.get(base + "/lots?bbox=" + bbox);
    };
    PropertyService.prototype.getMunicipalityProperties = function (municipalityId) {
        return this.http.get(base + '/municipalities/' + municipalityId + '/lots');
    };
    PropertyService.prototype.getHoodProperties = function (hoodId) {
        return this.http.get(base + '/hoods/' + hoodId + '/lots');
    };
    PropertyService.prototype.getDetails = function (municipalityId, id) {
        return this.http.get(base + '/municipalities/' + municipalityId + '/details/' + id);
    };
    PropertyService.prototype.getPictures = function (ids) {
        if (ids.length === 0)
            return of({ lotPictures: [] });
        return this.http.get(base + "/lots/" + ids.join(',') + "/pictures");
    };
    PropertyService.ngInjectableDef = i0.defineInjectable({ factory: function PropertyService_Factory() { return new PropertyService(i0.inject(i1.HttpClient)); }, token: PropertyService, providedIn: "root" });
    return PropertyService;
}());
export { PropertyService };
