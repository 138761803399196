import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventService } from '../../../services/events/event.service';
import { getTranslation } from '../../../utils/getTranslation';
import { getDateRange } from '../../../utils/getFormattedDate';
import { ActivatedRoute, Router } from '@angular/router';
import { getMultipleShuffled } from '../../../utils/shuffle';
export var ExternalLink;
(function (ExternalLink) {
    ExternalLink["INSTAGRAM"] = "extlink_instagram";
    ExternalLink["FACEBOOK"] = "extlink_facebook";
    ExternalLink["TWITTER"] = "extlink_twitter";
})(ExternalLink || (ExternalLink = {}));
var EventItemSmallComponent = /** @class */ (function () {
    function EventItemSmallComponent(dialogRef, data, eventService, translate, route, router) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.data = data;
        this.eventService = eventService;
        this.translate = translate;
        this.route = route;
        this.router = router;
        this.destroyed$ = new ReplaySubject(1);
        this.currentLanguage = 'fi';
        this.isVirtual = false;
        this.superEventId = '';
        this.relatedEventTitle = 'EVENT_ITEM.SUBEVENT.TITLE_RELATED';
        route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(function (params) {
            if (params.item) {
                _this.initEvent(params.item);
            }
        });
    }
    EventItemSmallComponent.prototype.ngOnInit = function () {
        // this.initEvent(this.data.id);
    };
    EventItemSmallComponent.prototype.ngOnDestroy = function () {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    };
    EventItemSmallComponent.prototype.closeItem = function () {
        this.dialogRef.close({ action: 'close', id: null });
    };
    EventItemSmallComponent.prototype.initEvent = function (id) {
        var _this = this;
        this.resetEvents();
        this.eventService
            .getEvent(id)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(function (response) {
            var event = response.data;
            _this.point = event.point;
            _this.isVirtual = event.is_virtualevent;
            _this.parseEventData(event);
            _this.getRelatedEvents(event);
        });
    };
    EventItemSmallComponent.prototype.resetEvents = function () {
        this.event = {
            superEventName: '',
            name: '',
            date: '',
            shortDescription: '',
            longDescription: '',
            address: {
                street: '',
                name: '',
                postalCode: '',
                city: '',
            },
            web: '',
            instagram: '',
            facebook: '',
            twitter: '',
            image: '',
            imageAltText: '',
            virtualeventUrl: '',
        };
        this.relatedEvents = [];
        this.superEventId = '';
    };
    EventItemSmallComponent.prototype.getRelatedEvents = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ids, response, categories, similar, shuffled;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ids = [];
                        // Event is a super event
                        if (event.sub_events && event.sub_events.length > 0) {
                            ids = ids.concat(this.getSubEventsIds(event));
                        }
                        if (!event.super_event_id) return [3 /*break*/, 2];
                        this.superEventId = event.super_event_id;
                        ids = ids.concat(event.super_event_id);
                        return [4 /*yield*/, this.eventService.getSubEvents(event.super_event_id).toPromise()];
                    case 1:
                        response = _a.sent();
                        // Remove current event from list
                        ids = ids.concat(this.getSubEventsIds(response.data[0])).filter(function (id) { return id !== event.id; });
                        if (event.sub_events && event.sub_events.length > 0) {
                        }
                        _a.label = 2;
                    case 2:
                        if (!(ids.length < 1)) return [3 /*break*/, 4];
                        this.relatedEventTitle = 'EVENT_ITEM.SUBEVENT.TITLE_MORE';
                        categories = event.linked_events_category.map(function (category) {
                            return category.linked_events_category_id;
                        });
                        return [4 /*yield*/, this.eventService.getSimilarEvents(categories).toPromise()];
                    case 3:
                        similar = _a.sent();
                        shuffled = getMultipleShuffled(similar.data, 5);
                        shuffled.sort(function (a, b) { return new Date(a.start_time).getTime() - new Date(b.start_time).getTime(); });
                        this.parseSubEvents(shuffled);
                        return [2 /*return*/];
                    case 4:
                        this.eventService
                            .getEventsById(ids)
                            .pipe(takeUntil(this.destroyed$))
                            .subscribe(function (response) {
                            _this.parseSubEvents(response.data);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    EventItemSmallComponent.prototype.getSubEventsIds = function (event) {
        return this.extractIdFromUri(event.sub_events);
    };
    EventItemSmallComponent.prototype.openEventItem = function (eventId) {
        this.dialogRef.close({ action: 'open', id: eventId });
    };
    EventItemSmallComponent.prototype.parseEventData = function (event) {
        var images = event.images && event.images.length > 0 ? event.images[0] : null;
        var altText = images && event.images[0].alt_text ? event.images[0].alt_text : null;
        var location = event.location ? event.location : null;
        this.event.superEventName = getTranslation(event.super_event_name, this.currentLanguage);
        this.event.name = getTranslation(event.name, this.currentLanguage);
        this.event.date = this.getEventDate(event.start_time, event.end_time);
        this.event.shortDescription = getTranslation(event.short_description, this.currentLanguage);
        this.event.longDescription = getTranslation(event.description, this.currentLanguage);
        this.event.address.name = location
            ? getTranslation(event.location.name, this.currentLanguage)
            : '';
        this.event.address.street = location
            ? getTranslation(event.location.street_address, this.currentLanguage)
            : '';
        this.event.address.postalCode =
            location && event.location.postal_code ? event.location.postal_code : '';
        this.event.address.city = location
            ? getTranslation(event.location.address_locality, this.currentLanguage)
            : '';
        this.event.web = getTranslation(event.info_url, this.currentLanguage);
        this.event.instagram = this.getExternalLink(event.external_links, ExternalLink.INSTAGRAM);
        this.event.facebook = this.getExternalLink(event.external_links, ExternalLink.FACEBOOK);
        this.event.twitter = this.getExternalLink(event.external_links, ExternalLink.TWITTER);
        this.event.image = images && event.images[0].url ? event.images[0].url : '';
        this.event.imageAltText = altText ? getTranslation(altText, this.currentLanguage) : '';
        this.event.virtualeventUrl = event.virtualevent_url;
    };
    EventItemSmallComponent.prototype.parseSubEvents = function (events) {
        var subevents = [];
        events.forEach(function (event) {
            var parsed = {
                id: event.id,
                name: event.name,
                start_time: event.start_time,
                end_time: event.end_time,
                images: event.images,
            };
            subevents.push(parsed);
        });
        this.relatedEvents = subevents;
    };
    EventItemSmallComponent.prototype.getExternalLink = function (links, platform) {
        var _this = this;
        var found = links.find(function (link) {
            if (link.name === platform) {
                if (link.language === _this.currentLanguage) {
                    return true;
                }
                else {
                    return link.language === 'fi';
                }
            }
        });
        if (!found) {
            return '';
        }
        return found.link;
    };
    EventItemSmallComponent.prototype.getEventDate = function (start, end) {
        return getDateRange(start, end);
    };
    EventItemSmallComponent.prototype.extractIdFromUri = function (uris) {
        if (!uris || (Array.isArray(uris) && !uris.length)) {
            return;
        }
        var ids = [];
        for (var _i = 0, uris_1 = uris; _i < uris_1.length; _i++) {
            var uri = uris_1[_i];
            var path = uri['@id'];
            if (path.endsWith('/')) {
                path = path.slice(0, -1);
            }
            var lastPath = path.substring(path.lastIndexOf('/') + 1);
            ids.push(lastPath);
        }
        return ids;
    };
    return EventItemSmallComponent;
}());
export { EventItemSmallComponent };
