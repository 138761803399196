import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  FeatureCollection,
  AreaFeatureType,
  GeoJsonObjectTypes,
  AreaProperties,
} from '../services/services.service';
import { LeafletMessageBoxService } from '../../services/leaflet-messagebox/leafletMessageBox.service';
import { TranslateService } from '@ngx-translate/core';
import * as L from 'leaflet';
import * as turf from '@turf/turf';

@Injectable({
  providedIn: 'root',
})
export class SimpleMapService {
  private zoomToMarkerToken = new Subject<number>();
  public zoomToMarkerToken$ = this.zoomToMarkerToken.asObservable();

  private mapServicesSubject = new BehaviorSubject<FeatureCollection>(undefined);
  public mapServices$ = this.mapServicesSubject.asObservable();

  public servicesCompare: FeatureCollection;

  public hoodArea: AreaProperties;

  constructor(
    private leafletMessageboxService: LeafletMessageBoxService,
    public translate: TranslateService,
  ) {}

  emitZoomToMarker(id: number) {
    this.zoomToMarkerToken.next(id);
  }

  loadMapServices(services: FeatureCollection) {
    this.mapServicesSubject.next(services);
  }

  showLoading(map: L.Map) {
    this.leafletMessageboxService.createControl(map);
    const loadingMessage = this.translate.instant('MAP.LOADING_SERVICE_POINTS');
    this.leafletMessageboxService.showMessage(
      `<i class="fa fas fa-icon fa-spinner spinner-fast"></i> ${loadingMessage}`,
      30000, // 30sec timeout just in case
    );
  }

  hideLoading() {
    this.leafletMessageboxService.hideMessage();
  }

  setServicesCompare(services: FeatureCollection) {
    this.servicesCompare = services;
  }

  getServicesCompare() {
    return this.servicesCompare;
  }

  setHoodArea(area: AreaProperties) {
    this.hoodArea = area;
  }

  getHoodArea() {
    return this.hoodArea;
  }

  /**
   * Takes a Point and a Polygon or MultiPolygon and determines if the
   * point resides inside the polygon. The polygon can be convex or concave.
   * The function accounts for holes.
   * @param point
   * @param polygon
   * @returns True if the point resides inside the polygon
   */
  isPointInPolygon(point: [number, number], polygon: any) {
    const featureType: GeoJsonObjectTypes = polygon.type;
    let comparePolygon;
    if (featureType === AreaFeatureType.Polygon) {
      comparePolygon = turf.polygon(polygon.coordinates);
    }
    if (featureType === AreaFeatureType.MultiPolygon) {
      comparePolygon = turf.multiPolygon(polygon.coordinates);
    }
    const comparePoint = turf.point(point);
    const containsPoint = turf.booleanPointInPolygon(comparePoint, comparePolygon);
    return containsPoint;
  }
}
