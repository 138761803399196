import { environment } from '../../environments/environment';

export let base = environment.apiBase;
export let authBase = environment.authBase;
export let directusBase = environment.directusBase;

export let endpoints = {
  search: base + '/countries/1/hoods/',
  hoods: base + '/regions/1/hoods/',
  hood: base + '/hoods/',
  districts: base + '/districts',
  locationtypes: base + '/locationtypes/',
  locationscats: base + '/locationcategories',
  locations: base + '/locations/',
  regions: base + '/regions/',
  subregions: base + '/subregions/',
  municipalities: base + '/municipalities/',
  municipalitypictures: base + '/municipalitypictures/',
  //'geocoding': 'https://nominatim.openstreetmap.org/search?format=json&limit=3&q=',
  geocoding: 'https://api.digitransit.fi/geocoding/v1/search?size=3&text=',
  reversegeo: 'https://api.digitransit.fi/geocoding/v1/reverse',
  texts:
    directusBase +
    '/items/translation_strings?fields=name,translations.languages_code,translations.translation&filter[platforms][platform_id][_eq]=1&limit=-1',
  features: base + '/features/',
  blog_api: 'https://hoods-blog-api.azurewebsites.net',
  // 'blog_api_dev' : 'https://hoods-blog-api-dev.azurewebsites.net',
  wp_base: 'https://hoods-wp.azurewebsites.net',
  wp_content: 'https://hoods-wp.azurewebsites.net/wp-content/uploads/',
  articles: 'https://hoods-wp.azurewebsites.net/wp-json/wp/v2/posts',
  properties: base + '/municipalities/2/lots',
  // 'properties': 'http://lvh.me:8081'
  blocks: base + '/blocks',
  auth: authBase + '/auth',
  reviews: authBase + '/reviews',
  users: authBase + '/users/',
  votes: authBase + '/votes',
  directusContent: directusBase + '/items/content',
  directusBlocks: directusBase + '/items/content_block',
  directusPictures: directusBase + '/assets/',
  directusLinkedEvents: directusBase + '/items/linked_events/',
  directusLinkedEventsCategory: directusBase + '/items/linked_events_category/',
  directusDataSource: directusBase + '/items/data_source',
};
