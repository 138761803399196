<div class="container">
  <!-- HEADER -->
  <div class="header">
    <button mat-mini-fab (click)="closeItem()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <img *ngIf="event.image" src="{{ event.image }}" alt="{{ event.imageAltText }}" />
    <div *ngIf="!event.image" class="placeholder-bg"><mat-icon>favorite</mat-icon></div>
  </div>

  <!-- TABS -->
  <mat-tab-group dynamicHeight mat-stretch-tabs animationDuration="0ms">
    <mat-tab label="{{ 'EVENT_ITEM.TAB.INFO' | translate }}">
      <ng-container *ngIf="event">
        <event-item-small-tab-info
          [point]="point"
          [event]="event"
          [isVirtual]="isVirtual"
        ></event-item-small-tab-info>
      </ng-container>
    </mat-tab>
    <mat-tab label="{{ relatedEventTitle | translate }}">
      <ng-container *ngIf="relatedEvents">
        <event-card-small
          *ngFor="let relatedEvent of relatedEvents"
          [data]="relatedEvent"
        ></event-card-small>
      </ng-container>
    </mat-tab>
    <!-- <mat-tab *ngIf="point" label="Map" class="map-area">
      <ng-template matTabContent>
        <simple-map *ngIf="point" [point]="point"></simple-map>
      </ng-template>
    </mat-tab> -->
  </mat-tab-group>
</div>
