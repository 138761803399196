import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FabService } from '../fab.service';

@Component({
  selector: 'app-fab-feedback',
  templateUrl: './fab-feedback.component.html',
  styleUrls: ['./fab-feedback.component.scss'],
})
export class FabFeedbackComponent implements OnInit, OnDestroy {
  @Input() public currentLanguage = 'fi';
  public showFeedback = false;
  public showFeedbackBubble = true;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private fabService: FabService) {}

  ngOnInit() {
    this.getShowFeedbackBubbleState();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  toggleBubble() {
    this.showFeedback = !this.showFeedback;
  }

  getShowFeedbackBubbleState() {
    this.fabService.showFabFeedback$.pipe(takeUntil(this.destroyed$)).subscribe((data: boolean) => {
      this.showFeedbackBubble = data;
    });
  }
}
