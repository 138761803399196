export class Property {
  id: number;
  municipalityId: number;
  referenceId: string;
  type: LotType;
  lotTypes?: LotType[] | null;
  name: string;
  description: string;
  area;
  point;
  areaSize: number;
  buildArea: number;
  propertyId: string;
  district: string;
  serviceDistrict: string;
  address: string;
  propertyType: string;
  plot?: string;
  block?: string;
  rentPrice: number;
  salesPrice: number;
  buildLevels: string;
  contactDetails: string;
  buildingInstructions: string;
  drillingInstructions: string;
  siteNote: string;
  applicationStart: string;
  applicationEnd: string;
  created: string;
  hoods: PropertyHood[];
  externalLink?: string;
  translations?: LotTranslation[];
}
export enum LotType {
  Other = 0,
  Residential = 1,
  Apartment = 2,
  Commercial = 3,
  FreeTime = 4,
}

export class PropertyHood {
  id: number;
  name: string;
  slug: string;
}

export interface LotTranslation {
  language: string;
  name?: string;
  description?: string;
  address?: string;
  seller?: string;
  externalLink?: string;
  areaSize?: string;
  salePrice?: string;
}

export enum ReferenceIdPrefix {
  KarttatiimiEura = 'KarttatiimiEura',
  SwecoPoytya = 'SwecoPoytya',
  KarttatiimiKemionSaari = 'KarttatiimiKemionSaari',
}
