import * as tslib_1 from "tslib";
import { AfterViewInit, OnDestroy, OnInit, } from '@angular/core';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material';
import * as L from 'leaflet';
import { SimpleMapService } from './simple-map.service';
import { ServicesService } from '../services/services.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ServicesFilterService } from '../../services/services-filter/services-filter.service';
import { ServicesListItemDetailsComponent } from '../services/services-list-item-details/services-list-item-details.component';
var GeoJsonPoint = /** @class */ (function () {
    function GeoJsonPoint() {
    }
    return GeoJsonPoint;
}());
export { GeoJsonPoint };
var iconRetinaUrl = 'assets/marker-icon-2x.png';
var iconUrl = 'assets/icons/hoods-marker.png';
var shadowUrl = 'assets/icons/hoods-shadow.png';
var iconDefault = L.icon({
    iconRetinaUrl: iconRetinaUrl,
    iconUrl: iconUrl,
    shadowUrl: shadowUrl,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [32, 32],
    shadowAnchor: [16, 32],
});
L.Marker.prototype.options.icon = iconDefault;
var SimpleMapComponent = /** @class */ (function () {
    function SimpleMapComponent(dialogRef, data, simpleMapService, servicesService, servicesFilterService, dialog) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.data = data;
        this.simpleMapService = simpleMapService;
        this.servicesService = servicesService;
        this.servicesFilterService = servicesFilterService;
        this.dialog = dialog;
        this.isLargeMap = false;
        this.tileServerUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        this.attribution = '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
        this.options = {
            center: [60.4516, 22.2659],
            zoom: 9,
            zoomControl: false,
        };
        this.markersById = {};
        this.destroyed$ = new ReplaySubject(1);
        this.multipleCategoryMarkerIcon = 'shapes';
        this.areaBorderLayer = L.layerGroup();
        this.cluster = L.markerClusterGroup.layerSupport({
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: false,
            zoomToBoundsOnClick: true,
            spiderLegPolylineOptions: { opacity: 0 },
            maxClusterRadius: function (zoom) {
                return zoom == 18 ? 1 : 80;
            },
            // disableClusteringAtZoom: 18,
            iconCreateFunction: function (cluster) {
                return L.divIcon({
                    html: '<div class="cluster"><strong>' + cluster.getChildCount() + '</strong></div>',
                });
            },
        });
        this.getAreaProperties();
        if (this.dialogRef && this.data) {
            this.zoomToMarkerId = this.data.zoomToMarkerId;
        }
        this.servicesFilterService.updateServices$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(function (data) {
            if (data && _this.simpleMap) {
                _this.simpleMapService.showLoading(_this.simpleMap);
            }
        });
    }
    SimpleMapComponent.prototype.ngOnInit = function () { };
    SimpleMapComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.initMap().whenReady(function () {
            _this.simpleMapService.mapServices$.pipe(takeUntil(_this.destroyed$)).subscribe(function (services) {
                // Fix for mobile devices. Mobile instantiates this simple map component using a button.
                var servicesCompare = _this.simpleMapService.getServicesCompare();
                if (servicesCompare === services && !_this.servicesService.isMobile) {
                    return;
                }
                _this.simpleMapService.setServicesCompare(services);
                _this.loadMarkers(services);
            });
            _this.simpleMapService.zoomToMarkerToken$
                .pipe(takeUntil(_this.destroyed$))
                .subscribe(function (data) {
                _this.zoomToMarker(data);
            });
            if (_this.zoomToMarkerId) {
                _this.zoomToMarker(_this.zoomToMarkerId);
            }
        });
    };
    SimpleMapComponent.prototype.getAreaProperties = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.servicesService.areaProperties$.pipe(takeUntil(this.destroyed$)).subscribe(function (area) {
                    _this.area = area;
                });
                return [2 /*return*/];
            });
        });
    };
    SimpleMapComponent.prototype.ngOnDestroy = function () {
        this.simpleMap.remove();
        this.destroyed$.next(true);
        this.destroyed$.complete();
    };
    SimpleMapComponent.prototype.initMap = function () {
        this.simpleMap = L.map('simple-map', this.options);
        var tiles = L.tileLayer(this.tileServerUrl, {
            maxZoom: 18,
            minZoom: 3,
            attribution: this.attribution,
        });
        tiles.addTo(this.simpleMap);
        this.renderAreaBorders();
        return this.simpleMap;
    };
    SimpleMapComponent.prototype.getArea = function () {
        // Fix for mobile devices. Only render Hoods borders when showing all map points from municipality.
        var area = this.area;
        var showMunicipalityServices = this.servicesService.getShowMunicipalityServices();
        if (showMunicipalityServices) {
            area = this.simpleMapService.getHoodArea();
        }
        return area;
    };
    SimpleMapComponent.prototype.renderAreaBorders = function () {
        var areaToRender = this.getArea();
        var geoJson = L.geoJSON(areaToRender.area);
        var style = {
            color: areaToRender.color,
            fillColor: areaToRender.color,
            fillOpacity: 0.05,
        };
        geoJson.setStyle(style);
        this.areaBorderLayer.clearLayers();
        geoJson.addTo(this.areaBorderLayer);
        this.areaBorderLayer.addTo(this.simpleMap);
    };
    SimpleMapComponent.prototype.fitViewToAreaBounds = function () {
        var areaToRender = this.getArea();
        var bounds = L.geoJSON(areaToRender.area).getBounds();
        this.simpleMap.fitBounds(bounds);
    };
    SimpleMapComponent.prototype.renderMarkerIcon = function (icons) {
        var iconHtml = '';
        icons.forEach(function (icon) {
            iconHtml += '<i class="fas fa-' + icon + '"></i>';
        });
        var classNames = icons.length === 1 ? 'leaflet-div-icon single' : 'leaflet-div-icon single multi';
        return L.divIcon({
            html: iconHtml,
            className: classNames,
            iconSize: [32, 32],
        });
    };
    SimpleMapComponent.prototype.loadMarkers = function (geoJsonData) {
        var _this = this;
        if (!geoJsonData || geoJsonData.features.length === 0) {
            // Default to area bounds when no markers exist
            this.fitViewToAreaBounds();
            this.cluster.clearLayers();
            this.simpleMapService.hideLoading();
            return;
        }
        if (this.cluster) {
            this.cluster.clearLayers();
        }
        // Create a marker for each location
        var layerGroup = L.geoJSON(geoJsonData, {
            pointToLayer: function (feature, latlng) {
                // Do not render the marker if it is outside of selected area
                var isInsideSelectedArea = _this.simpleMapService.isPointInPolygon([latlng.lng, latlng.lat], _this.area.area.geometry);
                if (!isInsideSelectedArea) {
                    return;
                }
                var icons = feature.properties.categories.map(function (category) { return category.icon; });
                var temporaryIcon = icons.length === 1 ? icons : [_this.multipleCategoryMarkerIcon];
                var options = {
                    icon: _this.renderMarkerIcon(temporaryIcon),
                };
                var marker = L.marker(latlng, options);
                return marker;
            },
            onEachFeature: function (feature, layer) {
                var id = parseInt(feature.id.toString());
                var title = feature.properties.title;
                if (feature.properties && feature.properties.title) {
                    var options = {
                        direction: 'top',
                        offset: L.point(0, -15),
                    };
                    if (_this.servicesService.isMobile) {
                        options.permanent = true;
                    }
                    layer.bindTooltip(title, options).openTooltip();
                }
                if (feature.id) {
                    _this.markersById[feature.id] = layer;
                }
                layer.on('click', function () {
                    _this.openServiceItemDetails(id);
                });
            },
        });
        layerGroup.addTo(this.cluster);
        this.cluster.addTo(this.simpleMap);
        // BUG: A race condition occurs with fitBounds and setView
        // when zooming to marker in mobile
        if (!this.zoomToMarkerId) {
            this.simpleMap.fitBounds(layerGroup.getBounds(), {
                padding: [6, 6],
            });
        }
        this.simpleMapService.hideLoading();
    };
    SimpleMapComponent.prototype.openServiceItemDetails = function (id) {
        var _this = this;
        var options = {
            panelClass: 'services-list-item-details-dialog',
            data: { id: id, referer: 'map' },
        };
        if (this.servicesService.isMobile) {
            options.width = '100%';
            options.height = '100%';
            options.maxWidth = '100%';
            options.autoFocus = false;
        }
        else {
            options.width = '900px';
            options.height = '600px';
            // Stops autoscrolling the modal to focusable element
            options.autoFocus = false;
        }
        var dialogRef = this.dialog.open(ServicesListItemDetailsComponent, options);
        if (this.servicesService.isMobile) {
            dialogRef.afterOpened().subscribe(function () {
                _this.servicesService.setIsItemDetailsOpen(true);
            });
            dialogRef.afterClosed().subscribe(function () {
                _this.servicesService.setIsItemDetailsOpen(false);
            });
        }
    };
    SimpleMapComponent.prototype.zoomToMarker = function (id) {
        if (!this.markersById[id]) {
            return;
        }
        if (this.currentOpenToolTip) {
            this.currentOpenToolTip.removeFrom(this.simpleMap);
        }
        var feature = this.markersById[id];
        this.simpleMap.setView(feature.getLatLng(), 18);
        feature.openTooltip();
        var tooltip = feature.getTooltip();
        if (tooltip) {
            this.currentOpenToolTip = tooltip;
        }
    };
    SimpleMapComponent.prototype.closeMap = function () {
        this.dialogRef.close();
    };
    return SimpleMapComponent;
}());
export { SimpleMapComponent };
