import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { endpoints } from '../../configs/sources';
import { TranslateService } from '@ngx-translate/core';
import { TokenStorageService } from '../registration/token-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../registration/token-storage.service";
var HoodsService = /** @class */ (function () {
    function HoodsService(http, translate, tokenStorage) {
        this.http = http;
        this.translate = translate;
        this.tokenStorage = tokenStorage;
        this.endpoint = endpoints.hoods;
        this.endpoint2 = endpoints.hood;
        this.endpointCountry = endpoints.search;
        this.showDrafts = false;
        this.hoodsSubject = new BehaviorSubject([]);
        this.hoods = this.hoodsSubject.asObservable();
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        var location = window.location.host;
        if (location == 'dev.hoods.fi' || location == 'beta.hoods.fi' || location == 'localhost:4200') {
            this.showDrafts = true;
        }
    }
    HoodsService.prototype.setShowDrafts = function (value) {
        this.showDrafts = value;
    };
    HoodsService.prototype.extractData = function (res) {
        /* const hoodFilter = [
          7, 29, 17, 5, 8, 11, 28, 26, 34, 36, 35, 38, 40, 44, 52, 54, 42, 59, 72, 69, 27,
          57, 56, 32, 58, 55, 47, 33, 16, 45, 39, 73, 71, 70, 37, 46, 13, 53, 61, 31, 68, 41,
          62, 63, 64, 65, 67, 43, 48, 49, 50, 51, 60
        ]; */
        var body = res || {};
        // check if we're in the beta domain and filter results accordingly
        /* const domain = window.location.host.split('.');
        console.log(domain[0]);
        if (domain[0] !== 'dev' && body.hoods !== undefined){
          body['hoods'] = body.hoods.filter(item => hoodFilter.indexOf(item.id) > -1);
          body['count'] = hoodFilter.length;
        } */
        return body;
    };
    HoodsService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            return of(result);
        };
    };
    // Fix undefined variables in query params
    HoodsService.prototype.fixme = function (p, i) {
        if (i === void 0) { i = -1; }
        try {
            if (p == null) {
                return '';
            }
            else if (i > -1) {
                return p[i] ? p[i] : p;
            }
            else {
                return p;
            }
        }
        catch (error) {
            console.log(error);
            return '';
        }
    };
    // Translates the given number from eg. 0-1 range to min-max range
    HoodsService.prototype.numTransform = function (min, max, rangeMin, value) {
        var range = max - min;
        var percentage = value - rangeMin;
        var position = range * percentage;
        // console.log(min + position);
        return Math.round(min + position);
    };
    HoodsService.prototype.range = function (type, value, pos) {
        if (type == 'sales') {
            // console.log(value);
            if (pos == 0 && value == 1) {
                value = 1.00001;
            }
            if (value == 0) {
                return 0;
            }
            if (value > 0 && value <= 1) {
                var rangeMin = 0;
                var min = 1254;
                var max = void 0;
                if (this.translate.instant('FILTER.PRICE.SALES_AVERAGE')) {
                    max = this.translate.instant('FILTER.PRICE.SALES_AVERAGE');
                }
                else {
                    max = 2090;
                }
                return this.numTransform(min, max, rangeMin, value);
            }
            if (value > 1 && value <= 2) {
                var rangeMin = 1;
                var max = void 0;
                var min = void 0;
                if (this.translate.instant('FILTER.PRICE.SALES_AVERAGE')) {
                    min = parseInt(this.translate.instant('FILTER.PRICE.SALES_AVERAGE'));
                }
                else {
                    min = 2090;
                }
                if (this.translate.instant('FILTER.PRICE.SALES_MAX')) {
                    max = parseInt(this.translate.instant('FILTER.PRICE.SALES_MAX'));
                }
                else {
                    max = 3344;
                }
                return this.numTransform(min, max, rangeMin, value);
            }
        }
        if (type == 'rent') {
            //console.log(value);
            if (pos == 0 && value == 1) {
                value = 1.1;
            }
            if (value >= 0 && value <= 1) {
                var rangeMin = 0;
                var min = 8;
                var max = 12.3;
                return this.numTransform(min, max, rangeMin, value);
            }
            if (value > 1 && value <= 2) {
                var rangeMin = 1;
                var min = 11.5;
                var max = 17.7;
                return this.numTransform(min, max, rangeMin, value);
            }
        }
        if (type == 'buildings') {
            if (pos == 0 && value > 1) {
                value = value - 0.5;
            }
            if (pos == 1 && value == 1) {
                value = 1.1;
            }
            if (value >= 0 && value <= 0.5) {
                var rangeMin = 0;
                var min = 0;
                var max = 15;
                return this.numTransform(min, max, rangeMin, value);
            }
            if (value >= 0.5 && value <= 1.5) {
                var rangeMin = 1;
                var min = 15;
                var max = 50;
                return this.numTransform(min, max, rangeMin, value);
            }
            if (value >= 1.5 && value <= 2.5) {
                var rangeMin = 1;
                var min = 50;
                var max = 500;
                return this.numTransform(min, max, rangeMin, value);
            }
            if (value >= 2.5 && value <= 3) {
                var rangeMin = 2;
                var min = 900;
                var max = 2340;
                return this.numTransform(min, max, rangeMin, value);
            }
        }
    };
    HoodsService.prototype.searchHoods = function (q) {
        // console.log(q);
        var params;
        if (q.services == null &&
            q.buildings == null &&
            q.rent == null &&
            q.sales == null &&
            q.features == null &&
            q.address == null &&
            q.municipalities == null) {
            params = '';
        }
        else {
            var pricefilter = '';
            // console.log(q.pricefilter);
            if (q.pricefilter == 'rent') {
                pricefilter =
                    '&rent-price=' +
                        this.range('rent', this.fixme(q.rent, 0), 0) +
                        '-' +
                        this.range('rent', this.fixme(q.rent, 1), 1);
            }
            if (q.pricefilter == 'sales') {
                pricefilter =
                    '&sales-price=' +
                        this.range('sales', this.fixme(q.sales, 0), 0) +
                        '-' +
                        this.range('sales', this.fixme(q.sales, 1), 1);
            }
            params = '?locations=' + this.fixme(q.services) + pricefilter;
            // '&buildings=' + this.range('buildings', this.fixme(q.buildings, 0), 0) + '-' + this.range('buildings', this.fixme(q.buildings, 1), 1) +
            if (q.features) {
                params += '&features=' + q.mode + q.features;
            }
            if (q.lots === 'true')
                params += '&lots';
            if (q.lotstypes !== undefined)
                params += '&lots-type=' + this.fixme(q.lotstypes);
            if (q.lotspricetypes !== undefined)
                params += '&lots-price-type=' + this.fixme(q.lotspricetypes);
            if (q.municipalities !== undefined)
                params += '&municipalities=' + this.fixme(q.municipalities);
            if (q.address && q.addressWayOfTransport && q.addressTimeLimit) {
                params += "&address=" + q.address;
                params += "&addressWayOfTransport=" + q.addressWayOfTransport;
                params += "&addressTimeLimit=" + q.addressTimeLimit;
            }
            console.log('Query url:' + params);
        }
        var s = q.sorting;
        var sorting = 'sort=' + s.sort + '&dir=' + s.dir;
        // UNCOMMENT TO USE PAGINATION:
        // let sorting = 'sort=' + s.sort + '&dir=' + s.dir + '&page=' + s.page + '&start=' + s.start;
        if (params == '') {
            sorting = '?' + sorting;
        }
        else {
            sorting = '&' + sorting;
        }
        var query = endpoints.search + params + sorting + '&draft=' + this.showDrafts;
        // console.log(query);
        // Send query params to GA
        //(<any>window).gtag('event', 'page_view', {
        //  'page_path': '/results' + params + sorting
        //});
        var analytics_string = 'featureFilter:' +
            q.features +
            '; priceFilter:' +
            q.price +
            '; serviceFilter:' +
            q.services +
            '; searchArea:Turku';
        // console.log(analytics_string);
        window.gtag('event', 'search', {
            event_category: 'hood_search',
            event_label: analytics_string,
        });
        var response = this.http.get(query).pipe(map(this.extractData));
        return response;
    };
    HoodsService.prototype.getHoods = function () {
        return this.http
            .get(this.endpoint + '?sort=name&draft=' + this.showDrafts)
            .pipe(map(this.extractData));
    };
    HoodsService.prototype.getHoodsFromCountry = function () {
        return this.http
            .get(this.endpointCountry + '?sort=name&draft=' + this.showDrafts)
            .pipe(map(this.extractData));
    };
    HoodsService.prototype.updateHoodsSubject = function (hoods) {
        this.hoodsSubject.next(hoods.hoods);
    };
    HoodsService.prototype.getHood = function (id) {
        return this.http.get(this.endpoint2 + id).pipe(map(this.extractData));
    };
    HoodsService.prototype.getSlugs = function () {
        return this.http
            .get(this.endpointCountry + '?draft=' + this.showDrafts)
            .pipe(map(this.extractData));
    };
    HoodsService.ngInjectableDef = i0.defineInjectable({ factory: function HoodsService_Factory() { return new HoodsService(i0.inject(i1.HttpClient), i0.inject(i2.TranslateService), i0.inject(i3.TokenStorageService)); }, token: HoodsService, providedIn: "root" });
    return HoodsService;
}());
export { HoodsService };
