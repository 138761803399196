/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fab-feedback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/common";
import * as i10 from "./fab-feedback.component";
import * as i11 from "../fab.service";
var styles_FabFeedbackComponent = [i0.styles];
var RenderType_FabFeedbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FabFeedbackComponent, data: {} });
export { RenderType_FabFeedbackComponent as RenderType_FabFeedbackComponent };
function View_FabFeedbackComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "feedback-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "button", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleBubble() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["class", "mat-flat-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.toggleBubble() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(15, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_5 = ((_co.currentLanguage ? ("/" + _co.currentLanguage) : "") + "/info/feedback"); _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("AC.BUTTON.CLOSE")); _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("FEEDBACK_BUBBLE.TITLE")); _ck(_v, 9, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("FEEDBACK_BUBBLE.CONTENT")); _ck(_v, 11, 0, currVal_4); var currVal_6 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("FEEDBACK_BUBBLE.BUTTON")); _ck(_v, 15, 0, currVal_6); }); }
function View_FabFeedbackComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "floating feedback-bubble"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleBubble() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"], ["icon", "comment-alt"]], [[8, "innerHTML", 1]], null, null, i6.View_FaIconComponent_0, i6.RenderType_FaIconComponent)), i1.ɵdid(2, 573440, null, 0, i7.FaIconComponent, [i8.DomSanitizer, i7.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = "comment-alt"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("AC.BUTTON.FEEDBACK")); _ck(_v, 4, 0, currVal_2); }); }
export function View_FabFeedbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FabFeedbackComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FabFeedbackComponent_2)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showFeedback; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showFeedbackBubble; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FabFeedbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fab-feedback", [], null, null, null, View_FabFeedbackComponent_0, RenderType_FabFeedbackComponent)), i1.ɵdid(1, 245760, null, 0, i10.FabFeedbackComponent, [i11.FabService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FabFeedbackComponentNgFactory = i1.ɵccf("app-fab-feedback", i10.FabFeedbackComponent, View_FabFeedbackComponent_Host_0, { currentLanguage: "currentLanguage" }, {}, []);
export { FabFeedbackComponentNgFactory as FabFeedbackComponentNgFactory };
