import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { map, take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var DialogService = /** @class */ (function () {
    function DialogService(dialog) {
        this.dialog = dialog;
    }
    DialogService.prototype.open = function (options) {
        this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: options.title,
                message: options.message,
                cancel: options.cancel,
                confirm: options.confirm,
            },
        });
    };
    DialogService.prototype.confirmed = function () {
        return this.dialogRef.afterClosed().pipe(take(1), map(function (res) {
            return res;
        }));
    };
    DialogService.ngInjectableDef = i0.defineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.inject(i1.MatDialog)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
