import { Component, Input, OnInit } from '@angular/core';
import { GeoJsonPoint, SimpleMapComponent } from '../../../simple-map/simple-map.component';
import { ParsedEvent } from '../event-item-small.component';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { MatDialog } from '@angular/material';
import { MapComponent } from '../../../map/map.component';

@Component({
  selector: 'event-item-small-tab-info',
  templateUrl: './event-item-small-tab-info.component.html',
  styleUrls: ['./event-item-small-tab-info.component.scss'],
})
export class EventItemSmallTabInfoComponent implements OnInit {
  @Input() public point: any;
  @Input() public event: ParsedEvent;
  @Input() public isVirtual: boolean;

  public showLongDescription = false;

  // Social media icons
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faTwitter = faTwitter;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    console.log(this.event);
  }

  openLargeMap() {
    const dialogRef = this.dialog.open(SimpleMapComponent, {
      maxWidth: '100vw',
      height: '100vh',
      width: '100%',
      panelClass: 'event-modal',
      data: {
        point: this.point,
        isLargeMap: true,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log('Closed large map');
    });
  }
}
