import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { PropertyService } from '../../services/properties/properties.service';
import { LotType, Property } from '../../services/properties/property.model';

@Component({
  selector: 'app-map-marker-popup',
  templateUrl: './map-marker-popup.component.html',
  styleUrls: ['./map-marker-popup.component.scss'],
})
export class MapMarkerPopupComponent implements OnInit {
  // general
  public name = '';
  public icon = '';
  public isServiceContent = false;
  public isLotContent = false;
  public currentLanguage = '';
  public lotIcons = ['warehouse', 'home', 'building', 'briefcase', 'hotel'];

  // lots
  public selectedLot = this.data.selectedLot;
  public reserveUrl = '';
  public lotTranslation: any;
  public municipalitySlug = '';

  // services
  public serviceData: any;
  public osmUrl = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ngZone: NgZone,
    public translate: TranslateService,
    private dialogRef: MatDialogRef<MapMarkerPopupComponent>,
    private propertyService: PropertyService,
  ) {}

  ngOnInit() {
    if (this.data.selectedLot && this.data.selectedLot.id) {
      this.initLotData();
    }
    if (this.data && this.data.point && this.data.point.id) {
      this.initServiceData();
    }
  }

  initServiceData() {
    this.isServiceContent = true;
    this.name = this.data.point.name;
    this.icon = this.data.icon;
    if (this.data.point.sourceId === 10) {
      this.osmUrl = 'https://www.openstreetmap.org/login';
    }
  }

  initLotData() {
    this.isLotContent = true;
    this.currentLanguage = this.data.currentLanguage;
    this.municipalitySlug = this.data.municipalitySlug;
    this.lotTranslation = this.propertyService.getTranslation(
      this.selectedLot,
      this.currentLanguage,
    );
    this.reserveUrl =
      this.lotTranslation.externalLink ||
      this.selectedLot.externalLink ||
      this.reserveLotUrl(this.selectedLot.referenceId, this.municipalitySlug);
    this.name = this.lotTranslation.name;
    this.icon = this.lotIcons[this.data.selectedLot.type];
  }

  closeDialog() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  getLotTypes(lotTypes: LotType[]) {
    if (lotTypes && lotTypes.length > 0) {
      let lotTypeTranslations = lotTypes.map((type) => {
        if (type === 0) {
          return this.translate.instant('LOT_OTHER');
        } else if (type === 1) {
          return this.translate.instant('LOT_DETACHED-HOUSING');
        } else if (type === 2) {
          return this.translate.instant('LOT_FLATS');
        } else if (type === 3) {
          return this.translate.instant('LOT_BUSINESS');
        } else if (type === 4) {
          return this.translate.instant('LOT_FREETIME');
        }
      });
      return lotTypeTranslations.join(', ');
    }
  }

  public reserveLotUrl(referenceId: string, muniSlug: string): string | undefined {
    if (!muniSlug) return '';
    if (!referenceId) return '';

    muniSlug = muniSlug.toLowerCase().trim();
    if (muniSlug === 'kaarina') {
      return `https://opaskartta.turku.fi/eSiteKaarina?siteList=${referenceId}`;
    } else if (referenceId.startsWith('KarttatiimiEura')) {
      return 'https://www.eurantontit.fi/';
    } else if (referenceId.startsWith('KarttatiimiKemionSaari')) {
      return 'https://www.kemionsaari.fi/fi/asuminen_ymparisto/asuminen_tontit/myytavat_tontit';
    }
  }
}
