import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Event, LinkedEventsCategory } from './event.model';
import { endpoints } from '../../configs/sources';
import { convertToBasic, getUpcomingDate } from '../../utils/getFormattedDate';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EventService = /** @class */ (function () {
    function EventService(http) {
        var _this = this;
        this.http = http;
        this.pageLimit = 20;
        this.filterSource = new BehaviorSubject(null);
        this.currentFilters = this.filterSource.asObservable();
        this.loadingSource = new BehaviorSubject(false);
        this.loadingStatus = this.loadingSource.asObservable();
        this.currentFilters.subscribe(function (data) {
            _this.filters = data;
        });
    }
    EventService.prototype.getEvents = function (requesterId, requester, opts) {
        this.setLoadingStatus(true);
        var filters = this.filters;
        if (opts && opts.skipFilters) {
            filters = undefined;
        }
        var searchParams = { _and: [] };
        // Search by hood or municipality
        if (requester && requester === 'hood') {
            searchParams._and.push({ areas: { area_id: { hoods_id: { _eq: requesterId } } } });
        }
        if (requester && requester === 'municipality') {
            searchParams._and.push({
                areas: { area_id: { municipality_id: { _eq: requesterId } } },
            });
        }
        // Search by date when no filters or no specific date filters exist
        if (!filters || (!filters.formControlStart && !filters.formControlEnd)) {
            searchParams._and.push(this.getDefaultDateFilter());
        }
        if (filters && (filters.formControlStart || filters.formControlEnd)) {
            filters.formControlStart = filters.formControlStart
                ? convertToBasic(filters.formControlStart)
                : '';
            filters.formControlEnd = filters.formControlEnd ? convertToBasic(filters.formControlEnd) : '';
            // If start date without end date
            if (filters.formControlStart && !filters.formControlEnd) {
                searchParams._and.push({
                    end_time: {
                        _gte: filters.formControlStart + 'T00:00Z',
                    },
                });
            }
            // If end date without start date
            if (!filters.formControlStart && filters.formControlEnd) {
                searchParams._and.push({
                    end_time: {
                        _gte: filters.formControlEnd + 'T00:00Z',
                    },
                });
                searchParams._and.push({
                    start_time: {
                        _lte: filters.formControlEnd + 'T23:59Z',
                    },
                });
            }
            // If start date with end date
            if (filters.formControlStart && filters.formControlEnd) {
                searchParams._and.push({
                    end_time: {
                        _lte: filters.formControlEnd + 'T23:59Z',
                    },
                });
                searchParams._and.push({
                    end_time: {
                        _gte: filters.formControlStart + 'T00:00Z',
                    },
                });
            }
        }
        // Search by audience
        if (filters && filters.formControlAudience && filters.formControlAudience.length > 0) {
            searchParams._and.push({
                audience: {
                    linked_events_category_id: { id: { _in: filters.formControlAudience.toString() } },
                },
            });
        }
        // Search by categories
        if (filters &&
            ((filters.formControlContent && filters.formControlContent.length > 0) ||
                (filters.formControlType && filters.formControlType.length > 0))) {
            var categories = [];
            if (filters.formControlContent.length > 0) {
                categories = categories.concat(filters.formControlContent);
            }
            if (filters.formControlType.length > 0) {
                categories = categories.concat(filters.formControlType);
            }
            searchParams._and.push({
                linked_events_category: {
                    linked_events_category_id: { id: { _in: categories.toString() } },
                },
            });
        }
        // Search by super event > disabled because of bad user experience
        // if (filters && filters.formControlSuper) {
        //   searchParams._and.push({ super_event_type: { _nnull: true } });
        // }
        // Hide sub events from list
        searchParams._and.push({ sub_event_type: { _null: true } });
        var limit = this.pageLimit;
        if (opts && opts.limit) {
            limit = opts.limit;
        }
        var params = {
            filter: "?filter=" + JSON.stringify(searchParams),
            sort: "&sort=start_time",
            fields: "&fields=id,name,start_time,end_time,images",
            meta: "&meta=filter_count",
            limit: "&limit=" + limit,
        };
        var url = endpoints.directusLinkedEvents + params.filter + params.fields + params.meta + params.limit;
        if (opts && opts.page) {
            url += "&page=" + opts.page;
        }
        return this.http.get(url);
    };
    EventService.prototype.getEvent = function (id) {
        var params = {
            fields: "?fields=id,name,start_time,end_time,images,location,super_event_name,short_description,description,info_url,external_links,virtualevent_url,linked_events_category.linked_events_category_id,sub_events,super_event_id,audience,point",
        };
        var url = endpoints.directusLinkedEvents + id + params.fields;
        return this.http.get(url);
    };
    EventService.prototype.getEventsById = function (ids) {
        var searchParams = { _and: [] };
        searchParams._and.push({
            id: { _in: ids },
        }, this.getDefaultDateFilter());
        var params = {
            filter: "?filter=" + JSON.stringify(searchParams),
            fields: "&fields=id,name,start_time,end_time,images",
            sort: "&sort=start_time",
        };
        var url = endpoints.directusLinkedEvents + params.filter + params.fields + params.sort;
        return this.http.get(url);
    };
    EventService.prototype.getSubEvents = function (id) {
        var searchParams = { _and: [] };
        searchParams._and.push({
            id: { _eq: id },
        }, this.getDefaultDateFilter());
        var params = {
            filter: "?filter=" + JSON.stringify(searchParams),
            fields: "&fields=sub_events",
        };
        var url = endpoints.directusLinkedEvents + params.filter + params.fields;
        return this.http.get(url);
    };
    EventService.prototype.getSimilarEvents = function (categoryIds) {
        var searchParams = { _and: [] };
        var twoMonthsFromNow = getUpcomingDate(2);
        searchParams._and.push({
            linked_events_category: {
                linked_events_category_id: { id: { _in: categoryIds.toString() } },
            },
        }, {
            end_time: {
                _lte: twoMonthsFromNow,
            },
        });
        var params = {
            filter: "?filter=" + JSON.stringify(searchParams),
            fields: "&fields=id,name,start_time,end_time,images",
        };
        var url = endpoints.directusLinkedEvents + params.filter + params.fields;
        return this.http.get(url);
    };
    EventService.prototype.getEventCategories = function () {
        var url = endpoints.directusLinkedEventsCategory;
        return this.http.get(url);
    };
    EventService.prototype.getDefaultDateFilter = function () {
        var filter = {
            _or: [
                {
                    end_time: {
                        _gte: '$NOW()',
                    },
                },
                {
                    _and: [
                        {
                            end_time: {
                                _null: true,
                            },
                        },
                        {
                            start_time: {
                                _gte: '$NOW()',
                            },
                        },
                    ],
                },
                {
                    _and: [
                        {
                            end_time: {
                                _null: true,
                            },
                        },
                        {
                            start_time: {
                                _null: true,
                            },
                        },
                    ],
                },
            ],
        };
        return filter;
    };
    EventService.prototype.setFilters = function (filters) {
        this.filterSource.next(filters);
        this.setLoadingStatus(true);
    };
    EventService.prototype.getFilters = function () {
        return this.filters;
    };
    EventService.prototype.setLoadingStatus = function (loading) {
        this.loadingSource.next(loading);
    };
    EventService.ngInjectableDef = i0.defineInjectable({ factory: function EventService_Factory() { return new EventService(i0.inject(i1.HttpClient)); }, token: EventService, providedIn: "root" });
    return EventService;
}());
export { EventService };
