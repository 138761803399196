import * as tslib_1 from "tslib";
import { LocationsService } from '../locations/locations.service';
import { HttpClient } from '@angular/common/http';
import { endpoints } from '../../configs/sources';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../locations/locations.service";
import * as i2 from "@angular/common/http";
var ServicesFilterService = /** @class */ (function () {
    function ServicesFilterService(locationService, http) {
        var _this = this;
        this.locationService = locationService;
        this.http = http;
        this.defaultSelectedServiceFilters = {
            sources: [12],
            categories: [],
            showMunicipalityServices: false,
        };
        this.updatedServices = new BehaviorSubject(true);
        this.updateServices$ = this.updatedServices.asObservable();
        this.servicesFiltersChanged = new BehaviorSubject(this.defaultSelectedServiceFilters);
        this.servicesFiltersChanged$ = this.servicesFiltersChanged.asObservable();
        this.ASSOCIATION_CATEGORY = {
            id: 15,
            name: 'Yhdistykset',
        };
        this.servicesFiltersChanged$.subscribe(function (data) {
            _this.filters = data;
            _this.refreshServices(true);
        });
    }
    ServicesFilterService.prototype.getCategories = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var parents, childs, sources;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getParentCategories()
                            .pipe(map(function (data) {
                            // Show only the association category
                            var associationCategory = data.locationCategories.filter(function (parentCategory) { return parentCategory.id === _this.ASSOCIATION_CATEGORY.id; });
                            return associationCategory;
                        }))
                            .toPromise()
                            .then(function (data) { return data; })];
                    case 1:
                        parents = _a.sent();
                        return [4 /*yield*/, this.getChildCategories()
                                .pipe(map(function (data) {
                                // Remove majoitus (see tickets 11237, 12277) and show only association categories.
                                var filtered = data.locationTypes.filter(function (type) { return type.id !== 5 && type.locationCategoryId === _this.ASSOCIATION_CATEGORY.id; });
                                return filtered;
                            }))
                                .toPromise()
                                .then(function (data) {
                                var filters = _this.filters.categories;
                                data.forEach(function (locationType) {
                                    if (filters.includes(locationType.id)) {
                                        locationType.selected = true;
                                    }
                                    else {
                                        locationType.selected = false;
                                    }
                                });
                                return data;
                            })];
                    case 2:
                        childs = _a.sent();
                        return [4 /*yield*/, this.getDataSources()
                                .toPromise()
                                .then(function (data) {
                                var filters = _this.filters.sources;
                                data.data.forEach(function (source) {
                                    if (filters.includes(source.code)) {
                                        source.selected = true;
                                    }
                                    else {
                                        source.selected = false;
                                    }
                                });
                                return data.data;
                            })];
                    case 3:
                        sources = _a.sent();
                        return [2 /*return*/, { parents: parents, childs: childs, sources: sources }];
                }
            });
        });
    };
    ServicesFilterService.prototype.getParentCategories = function () {
        return this.locationService.getCategories();
    };
    ServicesFilterService.prototype.getChildCategories = function () {
        return this.locationService.getTypes();
    };
    ServicesFilterService.prototype.getDataSources = function () {
        var fields = [
            'id',
            'abbreviation',
            'code',
            'translations.languages_code',
            'translations.name',
            'translations.description',
        ];
        return this.http.get(endpoints.directusDataSource, {
            params: {
                fields: fields.join(','),
                'filter[status]': 'published',
            },
        });
    };
    ServicesFilterService.prototype.setSelectedServicesFilters = function (selectedFilters) {
        var isNullOrUndefined = selectedFilters.showMunicipalityServices === null ||
            selectedFilters.showMunicipalityServices === undefined;
        var toSave = {
            categories: selectedFilters.categories ? selectedFilters.categories : this.filters.categories,
            sources: selectedFilters.sources ? selectedFilters.sources : this.filters.sources,
            showMunicipalityServices: !isNullOrUndefined
                ? selectedFilters.showMunicipalityServices
                : this.filters.showMunicipalityServices,
        };
        this.servicesFiltersChanged.next(toSave);
    };
    ServicesFilterService.prototype.refreshServices = function (updateMap) {
        this.updatedServices.next(updateMap);
    };
    ServicesFilterService.prototype.getSourceBadgeCount = function (selectedSources) {
        return selectedSources.filter(function (source) { return source.selected; }).length;
    };
    ServicesFilterService.ngInjectableDef = i0.defineInjectable({ factory: function ServicesFilterService_Factory() { return new ServicesFilterService(i0.inject(i1.LocationsService), i0.inject(i2.HttpClient)); }, token: ServicesFilterService, providedIn: "root" });
    return ServicesFilterService;
}());
export { ServicesFilterService };
