import * as tslib_1 from "tslib";
import { OnInit, isDevMode } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import fi from '@angular/common/locales/fi';
import { TextsService } from './services/texts/texts.service';
import { MapDataService } from './services/mapdata/mapdata.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import { RoutingState } from './services/routing/routingState';
import { base } from './configs/sources';
import { MunicipalitiesService } from './services/municipalities/municipalities.service';
import { RegionsService } from './services/regions/regions.service';
import { SubregionsService } from './services/subregions/subregions.service';
import { StorageService } from 'ngx-webstorage-service';
import { Meta } from '@angular/platform-browser';
import { TokenStorageService } from './services/registration/token-storage.service';
import { AuthService } from './services/registration/auth.service';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { UserService } from './services/registration/user.service';
import { HoodsService } from './services/hoods/hoods.service';
import { MatMenuTrigger } from '@angular/material';
import { filter } from 'rxjs/operators';
var AppComponent = /** @class */ (function () {
    function AppComponent(mapdata, router, translate, strings, tooltipConfig, municipalityService, regionsService, subregionsService, routingState, tokenStorage, authService, meta, userService, hoodsService, storage) {
        var _this = this;
        this.mapdata = mapdata;
        this.router = router;
        this.translate = translate;
        this.strings = strings;
        this.tooltipConfig = tooltipConfig;
        this.municipalityService = municipalityService;
        this.regionsService = regionsService;
        this.subregionsService = subregionsService;
        this.tokenStorage = tokenStorage;
        this.authService = authService;
        this.meta = meta;
        this.userService = userService;
        this.hoodsService = hoodsService;
        this.storage = storage;
        this.faUser = faUser;
        this.faLock = faLock;
        this.title = 'Hoods';
        this.showDisclaimer = true;
        this.showFeedback = false;
        this.pages = [
            {
                title: 'HOME.NAVLINK',
                route: '/home',
                visible: true,
                children: false,
            },
            {
                title: 'RESULTS.NAVLINK',
                route: '/search',
                visible: true,
                children: false,
            },
            {
                title: 'CITIES.NAVLINK',
                route: '',
                visible: true,
                children: true,
                subpages: [
                    {
                        title: 'Turku',
                        route: '/city/turku',
                    },
                ],
            },
            {
                title: 'BLOG.NAVLINK',
                route: '/blog',
                visible: true,
                children: false,
            },
            {
                title: 'INFO.NAVLINK',
                route: '',
                visible: true,
                children: true,
                subpages: [
                    {
                        title: 'INFO.NAVLINK-HOODS',
                        route: '/info',
                    },
                    {
                        title: 'INFO.NAVLINK-MAP',
                        route: '/info',
                    },
                ],
            },
        ];
        this.navRegions = [];
        this.backendServer = base;
        this.showDebug = false;
        this.adminAccess();
        if (isDevMode()) {
            this.showDebug = true;
        }
        // Updates nav menu and draft rights when reloading the browser tab
        var isUserTest = this.tokenStorage.getTestUser();
        if (isUserTest == 'true') {
            this.router.events
                .pipe(filter(function (rs) { return rs instanceof NavigationEnd; }))
                .subscribe(function (event) {
                if (event.id === 1 && event.url === event.urlAfterRedirects) {
                    _this.hoodsService.setShowDrafts(true);
                    _this.municipalityService.setShowDrafts(true);
                    _this.regionsService.setShowDrafts(true);
                    _this.subregionsService.setShowDrafts(true);
                    _this.loadNavData();
                }
            });
        }
        else {
            this.hoodsService.setShowDrafts(false);
            this.municipalityService.setShowDrafts(false);
            this.regionsService.setShowDrafts(false);
            this.subregionsService.setShowDrafts(false);
            this.loadNavData();
        }
        // Updates nav menu municipality list with drafts after user signs in
        authService.isUserTester.subscribe(function (showDrafts) {
            if (showDrafts === true) {
                _this.loadNavData();
            }
            else {
                _this.hoodsService.setShowDrafts(false);
                _this.municipalityService.setShowDrafts(false);
                _this.regionsService.setShowDrafts(false);
                _this.subregionsService.setShowDrafts(false);
                _this.loadNavData();
            }
        });
        if (translate.currentLang.length == 2) {
            this.currentLang = translate.currentLang;
        }
        translate.onLangChange.subscribe(function () {
            if (translate.currentLang.length == 2) {
                _this.currentLang = translate.currentLang;
            }
        });
        translate.setDefaultLang('fi');
        translate.addLangs(this.strings.getAvailableLanguages());
        // this.refreshTranslations();
        routingState.loadRouting();
        //tooltipConfig.placement = 'top';
        tooltipConfig.triggers = 'click';
        // set fallback language
        // Fetch language from session preferences
        this.mapdata.userPreferences.subscribe(function (preferences) {
            if (preferences !== undefined && preferences.language !== undefined) {
                _this.lang = preferences.language;
                // Select initial language
                translate.use(_this.lang);
                // this.refreshTranslations();
            }
        });
        // Refresh translations every second
        // TODO: Replace with a more sophisticated server side solution
        // setInterval(() => {
        //  this.refreshTranslations();
        // }, 1000);
        this.mapdata.refreshPreferences();
        // Comment this out if you want to translate by browser language
        //const browserLang = translate.getBrowserLang();
        //translate.use(browserLang.match(/en|fi/) ? browserLang : 'en');
        // Capture analytics from router
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                var url = event.urlAfterRedirects.split('/');
                if (url[1] == 'search' || url[2] == 'search') {
                    _this.meta.updateTag({ name: 'robots', content: 'noindex, follow' });
                }
                else {
                    _this.meta.updateTag({ name: 'robots', content: 'index, follow' });
                }
                gtag('event', 'page_view', {
                    page_path: event.urlAfterRedirects,
                });
                if (window.Cookiebot.consent.marketing) {
                    fbq('track', 'PageView');
                }
                // Adjusted bounce rate
                setTimeout(function () {
                    gtag('event', '30_seconds', {
                        page_path: event.urlAfterRedirects,
                    });
                }, 30000);
            }
        });
        this.mapdata.userPreferences.subscribe(function (preferences) {
            if (preferences !== undefined) {
                _this.showDisclaimer = !preferences.acceptTerms;
            }
        });
        this.mapdata.refreshPreferences();
        // this.metaService.updateTitle();
    }
    AppComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        registerLocaleData(fi);
                        this.getHoods();
                        _a = this;
                        return [4 /*yield*/, this.getUserHood()];
                    case 1:
                        _a.userHoodId = _c.sent();
                        if (!this.userHoodId) return [3 /*break*/, 3];
                        _b = this;
                        return [4 /*yield*/, this.getHoodSlug(this.userHoodId)];
                    case 2:
                        _b.userHoodSlug = _c.sent();
                        _c.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.loadNavData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, regions, subRegions, municipalities;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.getRegions(),
                            this.getSubregions(),
                            this.getMunicipalities(),
                        ])];
                    case 1:
                        _a = _b.sent(), regions = _a[0], subRegions = _a[1], municipalities = _a[2];
                        this.navRegions = this.treeify(municipalities, subRegions, regions);
                        this.pages[3].subpages = this.navRegions;
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.viewAfterInit = function () {
        // this.refreshTranslations();
    };
    AppComponent.prototype.refreshTranslations = function () {
        var _this = this;
        this.texts = this.strings.parseStrings().then(function (resolve) {
            //console.log("refreshing translations");
            var translations = resolve;
            // Fetch the languages
            _this.strings.getAvailableLanguages().forEach(function (key) {
                _this.translate.setTranslation(key, translations[key]);
            });
            if (_this.mapdata.checkLanguage()) {
                _this.translate.use(_this.mapdata.getLanguage());
            }
            else {
                _this.translate.use(_this.mapdata.preferences.language);
            }
            _this.currentLang = _this.translate.currentLang;
        }, function (reject) {
            console.log('Failed to load translations');
        });
    };
    AppComponent.prototype.clearCache = function () {
        this.storage.clear();
        window.location.reload();
    };
    AppComponent.prototype.changeLang = function (lang) {
        var url = this.router.url.split('/');
        var supportedLanguages = this.strings.getAvailableLanguages();
        if (url[1] === lang) {
            this.mapdata.saveLanguage(lang);
            return;
        }
        if (url[1] === 'map') {
            // language missing from url if just map page. Then add it
            url.unshift('');
        }
        if (supportedLanguages.includes(lang)) {
            url[1] = lang;
        }
        else {
            url[0] = lang;
        }
        console.log(url);
        this.mapdata.saveLanguage(lang);
        this.router.navigateByUrl('/' + url.join('/'));
        //this.translate.use(lang);
    };
    AppComponent.prototype.termsAccepted = function () {
        this.mapdata.acceptTerms();
        this.showDisclaimer = false;
    };
    AppComponent.prototype.navigate = function (page) {
        //console.log(page);
    };
    AppComponent.prototype.toggleBubble = function () {
        this.showFeedback = !this.showFeedback;
    };
    AppComponent.prototype.scrollTop = function () {
        window.scroll({
            top: 0,
            left: 0,
        });
    };
    AppComponent.prototype.getRegions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, ret;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.regionsService.getRegions().toPromise()];
                    case 1:
                        data = _a.sent();
                        ret = [];
                        data.regions.forEach(function (element) {
                            ret.push({
                                id: 'r' + element.id,
                                parentId: 0,
                                title: element.name,
                                slug: element.slug,
                                route: '/',
                                children: true,
                                subpages: [],
                            });
                        });
                        return [2 /*return*/, ret];
                }
            });
        });
    };
    AppComponent.prototype.getSubregions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, ret;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.subregionsService.getSubregions().toPromise()];
                    case 1:
                        data = _a.sent();
                        ret = [];
                        data.subRegions.forEach(function (element) {
                            ret.push({
                                id: 's' + element.id,
                                parentId: 'r' + element.regionId,
                                title: element.name,
                                slug: element.slug,
                                route: '/',
                                children: true,
                                subpages: [],
                            });
                        });
                        return [2 /*return*/, ret];
                }
            });
        });
    };
    AppComponent.prototype.getMunicipalities = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.municipalityService.getMunicipalities(true).toPromise()];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data.municipalities.map(function (m) { return ({
                                id: 'm' + m.id,
                                parentId: 's' + m.subRegionId,
                                title: m.name,
                                slug: m.slug,
                                hasHoods: m.hasHoods,
                            }); })];
                }
            });
        });
    };
    AppComponent.prototype.getHoods = function () {
        var _this = this;
        this.hoodsService.getHoodsFromCountry().subscribe(function (data) {
            _this.hoodsService.updateHoodsSubject(data);
        });
    };
    AppComponent.prototype.getCurrentLocale = function (currentLanguage) {
        // Update switch cases if language abbreviations are modified
        switch (currentLanguage) {
            case 'fi':
                return 'fi';
            case 'en':
                return 'en';
            case 'sv':
                return 'sv';
            default:
                return 'fi';
        }
    };
    AppComponent.prototype.sortAlphabetically = function (area) {
        var _this = this;
        var locale = this.getCurrentLocale(this.currentLang);
        area.sort(function (a, b) {
            if (!a.title[_this.currentLang]) {
                // Default to fi because of some missing translations
                return a.title['fi'].localeCompare(b.title['fi'], 'fi');
            }
            return a.title[_this.currentLang].localeCompare(b.title[_this.currentLang], locale);
        });
        return area;
    };
    AppComponent.prototype.treeify = function (municipalities, subRegions, regions) {
        for (var _i = 0, municipalities_1 = municipalities; _i < municipalities_1.length; _i++) {
            var muni = municipalities_1[_i];
            for (var _a = 0, subRegions_1 = subRegions; _a < subRegions_1.length; _a++) {
                var subRegion = subRegions_1[_a];
                if (muni.parentId !== subRegion.id)
                    continue;
                for (var _b = 0, regions_1 = regions; _b < regions_1.length; _b++) {
                    var region = regions_1[_b];
                    if (subRegion.parentId !== region.id)
                        continue;
                    region.subpages.push({
                        title: muni.title,
                        route: '/city/' + muni.slug,
                        hasHoods: muni.hasHoods,
                    });
                }
            }
        }
        return regions;
    };
    AppComponent.prototype.isLoggedIn = function () {
        return !!this.tokenStorage.getToken();
    };
    AppComponent.prototype.logout = function () {
        this.authService.logoutUser();
        this.router.navigate([(this.currentLang ? '/' + this.currentLang : '') + '/home']);
        this.menuTrigger.closeMenu();
    };
    AppComponent.prototype.getUserHood = function () {
        var _this = this;
        var user = this.tokenStorage.getCurrentUser();
        if (!user) {
            return;
        }
        return new Promise(function (resolve) {
            _this.userService.getUserDetailsById(user.id).subscribe(function (data) {
                var userHood = data.details.hood;
                resolve(userHood);
            });
        });
    };
    AppComponent.prototype.getHoodSlug = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.hoodsService.getHood(id).subscribe(function (data) {
                            var dataHood = data['hood'];
                            var userHoodSlug = {
                                hood: dataHood.slug,
                                municipality: dataHood.municipality.slug,
                            };
                            resolve(userHoodSlug);
                        });
                    })];
            });
        });
    };
    AppComponent.prototype.gotoUserHood = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.router.navigate([
                    (this.currentLang ? '/' + this.currentLang : '') +
                        '/hood/' +
                        this.userHoodSlug.municipality +
                        '/',
                    this.userHoodSlug.hood,
                ]);
                return [2 /*return*/];
            });
        });
    };
    AppComponent.prototype.adminAccess = function () {
        // Login and register pages always have access
        var url = window.location.href;
        if (url.includes('login') || url.includes('register') || url.includes('verify'))
            return;
        var currentUser = this.tokenStorage.getCurrentUser();
        var host = window.location.host;
        var params = new URLSearchParams(window.location.search);
        var mode = params.get('mode');
        var token = params.get('token');
        if (host === 'beta.hoods.fi') {
            console.log('You are veiwing in development mode');
            if (!currentUser) {
                if (mode === 'test' && token === 'xyz') {
                    console.log('Access granted');
                    return;
                }
                else {
                    this.router.navigate(['/login']);
                }
            }
        }
    };
    return AppComponent;
}());
export { AppComponent };
