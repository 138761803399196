import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { LinkedEventsCategory } from '../../../services/events/event.model';
import { EventService, Filter } from '../../../services/events/event.service';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { getTodaysDate } from '../../../utils/getFormattedDate';
import { DateAdapter, MatDialogRef, MAT_DATE_LOCALE } from '@angular/material';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { HostListener } from '@angular/core';

@Component({
  selector: 'event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'fi-FI' }],
})
export class EventFilterComponent implements OnInit, OnDestroy {
  // Filter categories
  public contents: LinkedEventsCategory[];
  public types: LinkedEventsCategory[];
  public audiences: LinkedEventsCategory[];

  // Select forms
  public filterGroup = new FormGroup({
    formControlAudience: new FormControl([]),
    formControlContent: new FormControl([]),
    formControlType: new FormControl([]),
    formControlSuper: new FormControl(false),
    formControlStart: new FormControl(''),
    formControlEnd: new FormControl(''),
  });

  // Date picker
  public today: string = null;
  public selectedEnd: string = null;
  public selectedStart: string = null;

  public currentLanguage = 'fi';
  public loading = false;

  // CSS
  public isSmallDeviceWidth = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private filterCopy: Filter;

  constructor(
    private eventService: EventService,
    private translate: TranslateService,
    private adapter: DateAdapter<any>,
    public tooltipConfig: NgbTooltipConfig,
    @Optional() public dialogRef: MatDialogRef<EventFilterComponent>,
  ) {
    this.getScreenSize();

    this.currentLanguage = translate.currentLang;

    this.translate.onLangChange.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      this.currentLanguage = this.translate.currentLang;
      this.adapter.setLocale(this.currentLanguage);
    });

    tooltipConfig.triggers = 'click hover';
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    if (window.innerWidth <= 768) {
      this.isSmallDeviceWidth = true;
    }
  }

  ngOnInit() {
    this.getFilterCategories();
    this.updateEventsOnFilterChanges();
    this.today = getTodaysDate();
    this.getLoadingStatus();
    this.adapter.setLocale(this.currentLanguage);
    this.initFormGroup();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    if (!this.isSmallDeviceWidth) {
      this.resetFilters();
    }
  }

  initFormGroup() {
    if (this.dialogRef) {
      this.dialogRef.afterOpened().subscribe(() => {
        this.eventService.currentFilters.subscribe((filters) => {
          if (filters) {
            this.filterGroup.setValue(filters);
          }
        });
      });
      return;
    }
    this.filterCopy = this.filterGroup.value;
    this.eventService.setFilters(this.filterCopy);
  }

  getLoadingStatus() {
    this.eventService.loadingStatus
      .pipe(takeUntil(this.destroyed$))
      .subscribe((loading) => (this.loading = loading));
  }

  updateEventsOnFilterChanges() {
    this.filterGroup.valueChanges
      .pipe(
        // Eliminate double value changes in datepicker
        distinctUntilChanged((prev, curr) => {
          return (
            prev.formControlStart === curr.formControlStart &&
            prev.formControlEnd === curr.formControlEnd &&
            prev.formControlAudience === curr.formControlAudience &&
            prev.formControlContent === curr.formControlContent &&
            prev.formControlType === curr.formControlType &&
            prev.formControlSuper === curr.formControlSuper
          );
        }),
      )
      .subscribe(async (changes: Filter) => {
        this.filterCopy = changes;
        // Update with button instead
        // if (!this.isSmallDeviceWidth) {
        //   this.eventService.setFilters(changes);
        // }
      });
  }

  getFilterCategories() {
    this.eventService
      .getEventCategories()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.contents = response.data.filter(
          (category) => category.category_group === 'turku:topic_content',
        );
        this.types = response.data.filter(
          (category) => category.category_group === 'turku:topic_type',
        );
        this.audiences = response.data.filter(
          (category) => category.category_group === 'turku:audience',
        );
      });
  }

  endChanged(event) {
    this.selectedEnd = event.value.toISOString();
  }

  startChanged(event) {
    this.selectedStart = event.value.toISOString();
  }

  resetDate(type: 'start' | 'end') {
    if (type === 'start') {
      this.selectedStart = null;
      this.filterGroup.get('formControlStart').patchValue('');
    }
    if (type === 'end') {
      this.selectedEnd = null;
      this.filterGroup.get('formControlEnd').patchValue('');
    }
  }

  closeFilterDialog(fromElement: 'close' | 'show') {
    const filters = fromElement === 'close' ? null : this.filterCopy;
    this.dialogRef.close(filters);
  }

  showResults() {
    const filters = this.eventService.getFilters();
    if (JSON.stringify(this.filterCopy) !== JSON.stringify(filters)) {
      this.eventService.setFilters(this.filterCopy);
    }
  }

  clearFilters(device?: 'mobile' | 'desktop') {
    this.resetFilters();
    if (device === 'mobile') {
      return;
    }
    const filters = this.eventService.getFilters();
    if (JSON.stringify(this.filterCopy) !== JSON.stringify(filters)) {
      this.eventService.setFilters(this.filterCopy);
    }
  }

  resetFilters() {
    this.selectedStart = null;
    this.selectedEnd = null;
    this.filterGroup.reset({
      formControlAudience: [],
      formControlContent: [],
      formControlType: [],
      formControlSuper: false,
      formControlStart: '',
      formControlEnd: '',
    });
  }
}
