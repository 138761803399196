import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var FabService = /** @class */ (function () {
    function FabService() {
        this.showFabFeedback = new Subject();
        this.showFabFeedback$ = this.showFabFeedback.asObservable();
        this.showFabScrollUp = new Subject();
        this.showFabScrollUp$ = this.showFabScrollUp.asObservable();
    }
    FabService.prototype.setShowFeedbackBubbleState = function (state) {
        this.showFabFeedback.next(state);
    };
    FabService.prototype.setShowUpState = function (state) {
        this.showFabScrollUp.next(state);
    };
    FabService.ngInjectableDef = i0.defineInjectable({ factory: function FabService_Factory() { return new FabService(); }, token: FabService, providedIn: "root" });
    return FabService;
}());
export { FabService };
