import { format, isSameDay, isSameHour, parseISO, formatISO, addMonths } from 'date-fns';

export function getDateRange(startDate: string, endDate: string) {
  if (!startDate) {
    return '';
  }

  if (!endDate) {
    return format(parseISO(startDate), 'dd.MM.yyyy HH:mm');
  }

  const formattedFullRange =
    format(parseISO(startDate), 'dd.MM.yyyy - ') + format(parseISO(endDate), 'dd.MM.yyyy');

  const sameDay = isSameDay(parseISO(startDate), parseISO(endDate));
  const sameHour = isSameHour(parseISO(startDate), parseISO(endDate));

  if (sameDay) {
    if (sameHour) {
      return format(parseISO(startDate), 'dd.MM.yyyy HH:mm');
    }
    return (
      format(parseISO(startDate), 'dd.MM.yyyy HH:mm') + ' - ' + format(parseISO(endDate), 'HH:mm')
    );
  }

  return formattedFullRange;
}

export function getTodaysDate() {
  const today = new Date(new Date().getTime());
  return today.toISOString();
}

export function getUpcomingDate(amount: number) {
  const today = new Date(new Date().getTime());
  return addMonths(today, amount).toISOString();
}

export function convertToISO(date: string) {
  const converted = new Date(date);
  return converted.toISOString();
}

export function convertToBasic(date: string) {
  return formatISO(new Date(date), { representation: 'date' });
}
