import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { base, endpoints } from '../../configs/sources';

@Injectable({
  providedIn: 'root',
})
export class SubregionsService {
  endpoint = endpoints.subregions;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  showDrafts = false;

  constructor(private http: HttpClient) {
    let location = window.location.host;

    if (location == 'dev.hoods.fi' || location == 'beta.hoods.fi' || location == 'localhost:4200') {
      this.showDrafts = true;
    }
  }

  setShowDrafts(value: boolean) {
    this.showDrafts = value;
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getSubregions(): Observable<any> {
    return this.http.get(this.endpoint + '?draft=' + this.showDrafts).pipe(map(this.extractData));
  }

  getSubregion(id): Observable<any> {
    return this.http
      .get(this.endpoint + '/' + id + '?draft=' + this.showDrafts)
      .pipe(map(this.extractData));
  }

  getSubregionsMunicipalities(id): Observable<any> {
    return this.http
      .get(this.endpoint + '/' + id + '/municipalities' + '?draft=' + this.showDrafts)
      .pipe(map(this.extractData));
  }
}
