<div class="container">
  <div *ngIf="event" class="info">
    <div class="header">
      <div class="title">
        <div class="super-name" *ngIf="event.superEventName">{{ event.superEventName }}</div>
        <h1 class="name">{{ event.name }}</h1>
        <div class="date">{{ event.date }}</div>
      </div>

      <div class="content">
        <p>{{ event.shortDescription }}</p>
        <ng-container *ngIf="event.longDescription">
          <p *ngIf="showLongDescription" [innerHTML]="event.longDescription | safeHtml"></p>
          <div class="show-button">
            <button mat-button (click)="showLongDescription = !showLongDescription">
              <span *ngIf="!showLongDescription">{{ 'EVENT_ITEM.SHOW_MORE' | translate }}</span>
              <span *ngIf="showLongDescription">{{ 'EVENT_ITEM.SHOW_LESS' | translate }}</span>
            </button>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="contact">
      <h2 class="title">
        {{ 'EVENT_ITEM.CONTACT.TITLE' | translate }}
      </h2>

      <div
        *ngIf="
          !event.virtualeventUrl &&
          !event.address.street &&
          !event.web &&
          !event.instagram &&
          !event.facebook &&
          !event.twitter
        "
      >
        <p>{{ 'EVENT_ITEM.CONTACT.NO_RESULTS' | translate }}</p>
      </div>

      <div class="details">
        <div *ngIf="event.address.street">
          <div class="align-left">
            <mat-icon>pin_drop</mat-icon>
            <div class="fields">
              <div>{{ event.address.street }}</div>
              <div>
                <span>{{ event.address.postalCode }} </span>
                <span>{{ event.address.city }}</span>
              </div>
            </div>
          </div>
          <!--FIX: Issue with multiple leaflet maps -->
          <!-- <a mat-button (click)="openLargeMap()">{{ 'Open map' }}</a> -->
        </div>

        <div *ngIf="event.virtualeventUrl">
          <div class="align-left">
            <mat-icon>pin_drop</mat-icon>
            <div class="fields">
              <div>{{ event.address.name }}</div>
              <a href="{{ event.virtualeventUrl }}" target="_blank">{{
                'EVENT_ITEM.CONTACT.VIRTUAL_OPEN' | translate
              }}</a>
            </div>
          </div>
        </div>

        <div *ngIf="event.phone">
          <div class="align-left">
            <mat-icon>smartphone</mat-icon>
            <div class="fields">{{ event.phone }}</div>
          </div>
          <a href="tel:{{ event.phone }}">{{ 'EVENT_ITEM.CONTACT.CALL' | translate }}</a>
        </div>

        <div *ngIf="event.web">
          <div class="align-left">
            <mat-icon>language</mat-icon>
            <a href="{{ event.web }}" target="_blank">{{
              'EVENT_ITEM.CONTACT.WEB_OPEN' | translate
            }}</a>
          </div>
        </div>

        <div *ngIf="event.instagram || event.facebook || event.twitter" class="social">
          <div *ngIf="event.facebook" class="align-left">
            <fa-icon [icon]="faFacebook"></fa-icon>
            <a href="{{ event.facebook }}" target="_blank">
              {{ 'EVENT_ITEM.MEDIA.FACEBOOK_OPEN' | translate }}</a
            >
          </div>
          <div *ngIf="event.instagram" class="align-left">
            <fa-icon [icon]="faInstagram"></fa-icon>
            <a href="{{ event.instagram }}" target="_blank">
              {{ 'EVENT_ITEM.MEDIA.INSTAGRAM_OPEN' | translate }}</a
            >
          </div>
          <div *ngIf="event.twitter" class="align-left">
            <fa-icon [icon]="faTwitter"></fa-icon>
            <a href="{{ event.twitter }}" target="_blank">
              {{ 'EVENT_ITEM.MEDIA.TWITTER_OPEN' | translate }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="point" class="map-area">
    <simple-map *ngIf="point" [point]="point"></simple-map>
  </div>
</div>
