import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchResultsComponent } from '../../../modules/search/search-results/search-results.component';
import { scrollToTop } from '../../../utils/scroll';
import { FabService } from '../fab.service';

@Component({
  selector: 'app-scroll-up',
  templateUrl: './scroll-up.component.html',
  styleUrls: ['./scroll-up.component.scss'],
})
export class ScrollUpComponent implements OnInit, OnDestroy {
  @ViewChild(SearchResultsComponent) searchComponent: SearchResultsComponent;
  public showUp = true;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private fabService: FabService, private router: Router) {}

  ngOnInit() {
    this.getShowUpState();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  scroll() {
    scrollToTop();
  }

  getShowUpState() {
    this.fabService.showFabScrollUp$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state: boolean) => {
        this.showUp = state;
      });
  }
}
