/**
 * A Fisher–Yates shuffle alogrithm to randomize a list. This modifies
 * the original input.
 * @param array The array to shuffle
 */
export function shuffle(array) {
  if (!Array.isArray(array) && array.length < 1) {
    throw new Error('The argument is not an array type or is empty');
  }
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

/**
 * Gets a random number of items from an array.
 * @param array The array to shuffle
 * @param amount The amount of items to return
 * @returns A list of randomly picked items from array
 */
export function getMultipleShuffled(array: any[], amount: number) {
  const toShuffle = array;
  shuffle(toShuffle);
  return toShuffle.slice(0, amount);
}
