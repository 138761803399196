<ng-container *ngIf="service.imageUrl">
  <div class="dialog-image" [style.background-image]="'url(' + service.picture + ')'"></div>
</ng-container>
<ng-container *ngIf="!service.imageUrl">
  <div class="dialog-divider-top"><mat-divider class="dialog-divider"></mat-divider></div>
</ng-container>
<div class="dialog-content">
  <ng-container *ngIf="description">
    <div class="dialog-description" [innerHTML]="description"></div>
  </ng-container>

  <ng-container *ngIf="service.address">
    <div class="dialog-row">
      <i style="font-size: 1.3em; margin-right: 0.8em" class="fas fa-map-marker-alt"></i>
      <span>{{ service.address }}{{ service.city ? ', ' + service.city : '' }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="service.url">
    <div class="dialog-row align-items">
      <div>
        <i style="font-size: 1.3em; margin-right: 0.8em" class="fas fa-globe"></i>
        <a href="{{ urlLink(service.url) }}" target="_blank">{{ formatUrl(service.url) }}</a>
      </div>
      <div class="fade">
        <i style="font-size: 1.3em; text-align: end" class="fas fa-external-link-alt"></i>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="osmUrl">
    <div class="dialog-row">
      <mat-expansion-panel class="osm-panel" *xs>
        <mat-expansion-panel-header class="osm-panel-header">
          <mat-panel-title>
            {{ 'LOCATIONS.RECOMMEND_CHANGES' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <p class="osm-explanation">{{ 'LOCATIONS.OSM_EXPLANATION' | translate }}</p>

          <a [href]="osmUrl" target="_blank">
            {{ 'LOCATIONS.GO_TO_OSM_MAP' | translate }}
          </a>
        </ng-template>
      </mat-expansion-panel>
    </div>
  </ng-container>
</div>
