<div [class.border]="!loading" class="card" role="button" (click)="openEventItem()">
  <div class="header">
    <img *ngIf="imageUrl" src="{{ imageUrl }}" alt="{{ imageAltText }}" />
    <div *ngIf="!imageUrl" class="placeholder-bg"><mat-icon>favorite</mat-icon></div>
  </div>

  <div class="content">
    <div class="title">
      {{ name }}
    </div>
    <div class="date">{{ date }}</div>
  </div>
</div>

<div *ngIf="loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
