/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./snackbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./snackbar.component";
import * as i4 from "@angular/material/snack-bar";
var styles_SnackbarComponent = [i0.styles];
var RenderType_SnackbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SnackbarComponent, data: {} });
export { RenderType_SnackbarComponent as RenderType_SnackbarComponent };
export function View_SnackbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.data)); _ck(_v, 1, 0, currVal_0); }); }
export function View_SnackbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "snackbar", [], null, null, null, View_SnackbarComponent_0, RenderType_SnackbarComponent)), i1.ɵdid(1, 114688, null, 0, i3.SnackbarComponent, [i4.MatSnackBarRef, i4.MAT_SNACK_BAR_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SnackbarComponentNgFactory = i1.ɵccf("snackbar", i3.SnackbarComponent, View_SnackbarComponent_Host_0, {}, {}, []);
export { SnackbarComponentNgFactory as SnackbarComponentNgFactory };
