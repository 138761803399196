import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: './modules/landing/landing.module#LandingModule',
    pathMatch: 'full',
    data: {
      title: 'Know Your Hoods',
      description: '',
    },
  },
  {
    path: 'home',
    loadChildren: './modules/landing/landing.module#LandingModule',
    data: {
      title: 'Know Your Hoods',
      description: '',
    },
  },
  {
    path: ':lang/home',
    loadChildren: './modules/landing/landing.module#LandingModule',
    data: {
      title: 'Know Your Hoods',
      description: '',
    },
  },
  {
    path: 'content',
    loadChildren: './modules/content/content.module#ContentModule',
    data: {
      title: 'Sisältö',
      description: '',
    },
  },
  {
    path: ':lang/content',
    loadChildren: './modules/content/content.module#ContentModule',
    data: {
      title: 'Sisältö',
      description: '',
    },
  },
  {
    path: 'info',
    loadChildren: './modules/content/content.module#ContentModule',
    data: {
      title: 'Tietoa palvelusta',
      description: '',
    },
  },
  {
    path: ':lang/info',
    loadChildren: './modules/content/content.module#ContentModule',
    data: {
      title: 'Tietoa palvelusta',
      description: '',
    },
  },
  {
    path: 'info/:id',
    loadChildren: './modules/content/content.module#ContentModule',
    data: {
      title: 'Tietoa palvelusta',
      description: '',
    },
  },
  {
    path: ':lang/info/:id',
    loadChildren: './modules/content/content.module#ContentModule',
    data: {
      title: 'Tietoa palvelusta',
      description: '',
    },
  },
  {
    path: 'region',
    loadChildren: './modules/region/region.module#RegionModule',
    data: {
      title: 'Kunta',
      description: '',
    },
  },
  {
    path: ':lang/region',
    loadChildren: './modules/region/region.module#RegionModule',
    data: {
      title: 'Kunta',
      description: '',
    },
  },
  {
    path: 'region/:id',
    loadChildren: './modules/region/region.module#RegionModule',
    data: {
      title: 'Kunta',
      description: '',
    },
  },
  {
    path: ':lang/region/:id',
    loadChildren: './modules/region/region.module#RegionModule',
    data: {
      title: 'Kunta',
      description: '',
    },
  },
  {
    path: 'municipality',
    loadChildren: './modules/region/region.module#RegionModule',
    data: {
      title: 'Kunta',
      description: '',
    },
  },
  {
    path: ':lang/municipality',
    loadChildren: './modules/region/region.module#RegionModule',
    data: {
      title: 'Kunta',
      description: '',
    },
  },
  {
    path: 'municipality/:id',
    loadChildren: './modules/region/region.module#RegionModule',
    data: {
      title: 'Kunta',
      description: '',
    },
  },
  {
    path: ':lang/municipality/:id',
    loadChildren: './modules/region/region.module#RegionModule',
    data: {
      title: 'Kunta',
      description: '',
    },
  },
  {
    path: 'city',
    loadChildren: './modules/region/region.module#RegionModule',
    data: {
      title: 'Kunta',
      description: '',
    },
  },
  {
    path: ':lang/city',
    loadChildren: './modules/region/region.module#RegionModule',
    data: {
      title: 'Kunta',
      description: '',
    },
  },
  {
    path: 'city/:slug',
    loadChildren: './modules/region/region.module#RegionModule',
    data: {
      title: 'Kunta',
      description: '',
    },
  },
  {
    path: ':lang/city/:slug',
    loadChildren: './modules/region/region.module#RegionModule',
    data: {
      title: 'Kunta',
      description: '',
    },
  },
  {
    path: 'search',
    loadChildren: './modules/search/search.module#SearchModule',
    data: {
      title: 'Hae unelmiesi naapurustoa',
      description: 'Know Your Hoods auttaa löytämään sinulle sopivimman asuinalueen.',
    },
  },
  {
    path: ':lang/search',
    loadChildren: './modules/search/search.module#SearchModule',
    data: {
      title: 'Hae unelmiesi naapurustoa',
      description: 'Know Your Hoods auttaa löytämään sinulle sopivimman asuinalueen.',
    },
  },
  {
    path: 'map',
    loadChildren: './modules/map/map.module#MapModule',
    pathMatch: 'full',
    data: {
      title: 'Kartta',
      description: '',
    },
  },
  {
    path: ':lang/map',
    loadChildren: './modules/map/map.module#MapModule',
    pathMatch: 'full',
    data: {
      title: 'Kartta',
      description: '',
    },
  },
  {
    path: 'map/:slug',
    loadChildren: './modules/map/map.module#MapModule',
    pathMatch: 'full',
    data: {
      title: 'Kartta',
      description: '',
    },
  },
  {
    path: ':lang/map/:slug',
    loadChildren: './modules/map/map.module#MapModule',
    pathMatch: 'full',
    data: {
      title: 'Kartta',
      description: '',
    },
  },
  {
    path: 'hood',
    loadChildren: './modules/hood/hood.module#HoodModule',
    data: {
      title: 'Aluesivu',
      description: '',
    },
  },
  {
    path: ':lang/hood',
    loadChildren: './modules/hood/hood.module#HoodModule',
    data: {
      title: 'Aluesivu',
      description: '',
    },
  },
  /* Redundant routing, child routing is handled in the Hood module. 
   {
      "path": "hood/:city/:id",
      "loadChildren": "./modules/hood/hood.module#HoodModule",
      "data": {
         "title": "Aluesivu",
         "description": ""
      }
   },
   {
      "path": ":lang/hood/:city/:id",
      "loadChildren": "./modules/hood/hood.module#HoodModule",
      "data": {
         "title": "Aluesivu",
         "description": ""
      }
   },
   {
      "path": "hood/:region/:subregion/:city/:id",
      "loadChildren": "./modules/hood/hood.module#HoodModule",
      "data": {
         "title": "Aluesivu",
         "description": ""
      }
   },
   {
      "path": ":lang/hood/:region/:subregion/:city/:id",
      "loadChildren": "./modules/hood/hood.module#HoodModule",
      "data": {
         "title": "Aluesivu",
         "description": ""
      }
   },
   {
      "path": "hood/:id",
      "loadChildren": "./modules/hood/hood.module#HoodModule",
      "data": {
         "title": "Aluesivu",
         "description": ""
      }
   },
   {
      "path": ":lang/hood/:id",
      "loadChildren": "./modules/hood/hood.module#HoodModule",
      "data": {
         "title": "Aluesivu",
         "description": ""
      }
   }, */
  {
    path: 'blog',
    loadChildren: './modules/article/article.module#ArticleModule',
    data: {
      title: 'Blogi',
      description: '',
    },
  },
  {
    path: ':lang/blog',
    loadChildren: './modules/article/article.module#ArticleModule',
    data: {
      title: 'Blogi',
      description: '',
    },
  },
  {
    path: 'blog/article/:id',
    loadChildren: './modules/article/article.module#ArticleModule',
    data: {
      title: 'Blogi',
      description: '',
    },
  },
  {
    path: ':lang/blog/article/:id',
    loadChildren: './modules/article/article.module#ArticleModule',
    data: {
      title: 'Blogi',
      description: '',
    },
  },
  {
    path: 'blog/galleria1',
    redirectTo: 'blog/article/364',
    pathMatch: 'full',
  },
  {
    path: ':lang/blog/galleria1',
    redirectTo: 'blog/article/364',
    pathMatch: 'full',
  },
  {
    path: 'blog/galleria2',
    redirectTo: 'blog/article/505',
    pathMatch: 'full',
  },
  {
    path: ':lang/blog/galleria2',
    redirectTo: 'blog/article/505',
    pathMatch: 'full',
  },
  {
    path: 'blog/galleria3',
    redirectTo: 'blog/article/626',
    pathMatch: 'full',
  },
  {
    path: ':lang/blog/galleria3',
    redirectTo: 'blog/article/626',
    pathMatch: 'full',
  },
  {
    path: 'user',
    loadChildren: './modules/registration/registration.module#RegistrationModule',
    data: {
      title: 'Login',
      description: '',
    },
  },
  {
    path: ':lang/user',
    loadChildren: './modules/registration/registration.module#RegistrationModule',
    data: {
      title: 'Login',
      description: '',
    },
  },
  {
    path: 'profile',
    loadChildren: './modules/profile/profile.module#ProfileModule',
    data: {
      title: 'Register',
      description: '',
    },
  },
  {
    path: ':lang/profile',
    loadChildren: './modules/profile/profile.module#ProfileModule',
    data: {
      title: 'Register',
      description: '',
    },
  },
  {
    path: 'edit',
    redirectTo: 'profile/edit',
    pathMatch: 'full',
  },
  {
    path: ':lang/edit',
    redirectTo: ':lang/profile/edit',
    pathMatch: 'full',
  },
  {
    path: 'logout',
    loadChildren: './modules/registration/registration.module#RegistrationModule',
    data: {
      title: 'Register',
      description: '',
    },
  },
  {
    path: ':lang/logout',
    loadChildren: './modules/registration/registration.module#RegistrationModule',
    data: {
      title: 'Register',
      description: '',
    },
  },
  {
    path: 'login',
    redirectTo: 'user/login',
    pathMatch: 'full',
  },
  {
    path: ':lang/login',
    redirectTo: ':lang/user/login',
    pathMatch: 'full',
  },
  {
    path: 'register',
    redirectTo: 'user/register',
    pathMatch: 'full',
  },
  {
    path: ':lang/register',
    redirectTo: ':lang/user/register',
    pathMatch: 'full',
  },
  {
    path: 'verify',
    redirectTo: 'user/verify',
    pathMatch: 'full',
  },
  {
    path: ':lang/verify',
    redirectTo: ':lang/user/verify',
    pathMatch: 'full',
  },
  {
    path: 'thanks',
    redirectTo: 'user/thanks',
    pathMatch: 'full',
  },
  {
    path: ':lang/thanks',
    redirectTo: ':lang/user/thanks',
    pathMatch: 'full',
  },
  {
    path: '404',
    redirectTo: 'not-found',
    pathMatch: 'full',
  },
  {
    path: ':lang/404',
    redirectTo: 'not-found',
    pathMatch: 'full',
  },
  {
    path: ':lang/not-found',
    redirectTo: 'not-found',
    pathMatch: 'full',
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    data: {
      title: 'Sivua ei löydy',
      description: 'Valitettavasti hakemaasi sivua ei löydy.',
    },
  },
  {
    path: ':lang/',
    loadChildren: './modules/landing/landing.module#LandingModule',
    pathMatch: 'full',
    data: {
      title: 'Know Your Hoods',
      description: '',
    },
  },
  {
    path: ':lang',
    loadChildren: './modules/landing/landing.module#LandingModule',
    pathMatch: 'full',
    data: {
      title: 'Know Your Hoods',
      description: '',
    },
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      title: 'Sivua ei löydy',
      description: 'Valitettavasti hakemaasi sivua ei löydy.',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
