import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { endpoints } from '../../configs/sources';
import { PropertyService } from '../properties/properties.service';
import { ReferenceIdPrefix } from '../properties/property.model';
import { addQueryParameters } from '../../utils/urlUtils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../properties/properties.service";
var MunicipalitiesService = /** @class */ (function () {
    function MunicipalitiesService(http, translate, propertyService) {
        this.http = http;
        this.translate = translate;
        this.propertyService = propertyService;
        this.endpoint = endpoints.municipalities;
        this.endpoint2 = endpoints.municipalitypictures;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        this.showDrafts = false;
        var location = window.location.host;
        if (location == 'dev.hoods.fi' || location == 'beta.hoods.fi' || location == 'localhost:4200') {
            this.showDrafts = true;
        }
    }
    MunicipalitiesService.prototype.setShowDrafts = function (value) {
        this.showDrafts = value;
    };
    MunicipalitiesService.prototype.extractData = function (res) {
        var body = res;
        return body || {};
    };
    MunicipalitiesService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            // console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            // console.log(`${operation} failed: ${error.message}`);
            return of(result);
        };
    };
    MunicipalitiesService.prototype.getMunicipalities = function (simplified) {
        if (simplified === void 0) { simplified = false; }
        var url = this.endpoint + "?draft=" + this.showDrafts;
        if (simplified) {
            url += "&simplified=" + true;
        }
        return this.http.get(url).pipe(map(this.extractData));
    };
    MunicipalitiesService.prototype.getMunicipality = function (id) {
        return this.http.get(this.endpoint + id).pipe(map(this.extractData));
    };
    MunicipalitiesService.prototype.getPictures = function () {
        return this.http.get(this.endpoint2).pipe(map(this.extractData));
    };
    MunicipalitiesService.prototype.getMunicipalityPictures = function (id) {
        return this.http.get(this.endpoint + id + '/pictures').pipe(map(this.extractData));
    };
    MunicipalitiesService.prototype.getHoods = function (id) {
        return this.http
            .get(this.endpoint + id + '/hoods?draft=' + this.showDrafts + '&sort=name')
            .pipe(map(this.extractData));
    };
    MunicipalitiesService.prototype.getMunicipalitiesCenters = function () {
        return this.http
            .get(this.endpoint + '/centers?draft=' + this.showDrafts)
            .pipe(map(this.extractData));
    };
    MunicipalitiesService.prototype.getMunicipalityLocations = function (municipalityId, filters, isMapQuery, pagination, hoodListQuery) {
        var baseUrl = "" + endpoints.municipalities + municipalityId + "/locations/list";
        var finalUrl = baseUrl;
        if (pagination) {
            finalUrl = addQueryParameters(finalUrl, pagination);
        }
        if (filters) {
            var params = {};
            if (filters.sources.length > 0) {
                params.source = filters.sources;
            }
            if (filters.categories.length > 0) {
                params.category = filters.categories;
            }
            finalUrl = addQueryParameters(finalUrl, params);
        }
        if (isMapQuery) {
            finalUrl = addQueryParameters(finalUrl, { map: true });
        }
        if (hoodListQuery && hoodListQuery.intersects) {
            finalUrl = addQueryParameters(finalUrl, hoodListQuery);
        }
        return this.http
            .get(finalUrl)
            .pipe(map(function (response) {
            return {
                count: response.meta.total,
                data: response.locations,
            };
        }), catchError(function (error) {
            console.error(error);
            return throwError('Something went wrong while fetching municipality locations.');
        }));
    };
    MunicipalitiesService.prototype.reserveLotUrl = function (referenceId, muniSlug) {
        if (!muniSlug)
            return '';
        if (!referenceId)
            return '';
        muniSlug = muniSlug.toLowerCase().trim();
        if (muniSlug === 'kaarina') {
            return "https://opaskartta.turku.fi/eSiteKaarina?siteList=" + referenceId;
        }
        else if (referenceId.startsWith(ReferenceIdPrefix.KarttatiimiEura)) {
            return 'https://www.eurantontit.fi/';
        }
        else if (referenceId.startsWith(ReferenceIdPrefix.KarttatiimiKemionSaari)) {
            return 'https://www.kemionsaari.fi/fi/asuminen_ymparisto/asuminen_tontit/myytavat_tontit';
        }
        else if (referenceId.startsWith(ReferenceIdPrefix.SwecoPoytya)) {
            return 'https://www.poytya.fi/asu-ja-rakenna/asuminen/asuintontit';
        }
    };
    MunicipalitiesService.prototype.lotPopupText = function (property, municipalitySlug, hood, currentLang, onclickGtag, image, municipalityName) {
        if (onclickGtag === void 0) { onclickGtag = ''; }
        if (image === void 0) { image = undefined; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var icons, icon, popupContent, addressArea, strings, lotTranslation, reserveUrl;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        icons = ['warehouse', 'home', 'building', 'briefcase', 'hotel'];
                        icon = '<i style="font-size: 1.3em;margin-right: 0.8em;" class="fas fa-' +
                            icons[property.type] +
                            '"></i>';
                        popupContent = '';
                        addressArea = '';
                        return [4 /*yield*/, this.translate
                                .get([
                                'AREA.NAVLINK',
                                'PROPERTY_AREA',
                                'PERMITTED_AREA',
                                'OPEN_RESERVATIONS',
                                'GO_TO_HOOD',
                                'SALES_PRICE',
                                'RENTAL_PRICE',
                                'LOT_SELLER',
                                'LOT_SELLER_CITY',
                                'PROPERTY_ADDRESS',
                            ])
                                .toPromise()];
                    case 1:
                        strings = _a.sent();
                        if (property.description) {
                            while (property.description.match(/^(\s|<br>)/)) {
                                if (property.description.match(/^\s/))
                                    property.description = property.description.slice(1);
                                else
                                    property.description = property.description.slice('<br>'.length);
                            }
                        }
                        if (municipalityName) {
                            addressArea = ', ' + municipalityName[currentLang];
                        }
                        else {
                            addressArea = '';
                        }
                        lotTranslation = this.propertyService.getTranslation(property, currentLang);
                        reserveUrl = lotTranslation.externalLink ||
                            property.externalLink ||
                            this.reserveLotUrl(property.referenceId, municipalitySlug);
                        // All the classes are in styles.scss
                        popupContent = "\n    <div class=\"property-card\">\n      <div class=\"property-card-title\">\n        <div class=\"card-title-icon\">" + icon + "</div>\n        <div class=\"card-title-text\">\n          <div class=\"card-title-name\">" + lotTranslation.name + "</div> \n          " + (property.propertyType
                            ? "<div class=\"card-title-type\">" + property.propertyType + "</div>"
                            : '') + "\n        </div>\n      </div>\n\n      " + (image
                            ? "<div class=\"card-image\" style=\"background-image:url(" + image.url + ");\">\n      </div>"
                            : '<div class="card-divider-top"><div class="card-divider"></div></div>') + "      \n\n      " + (lotTranslation.description
                            ? "<div class=\"card-description\">\n          " + lotTranslation.description + "\n        </div>"
                            : "") + "\n\n      " + (lotTranslation.address
                            ? "<div class=\"card-row\">\n        <strong>" + strings['PROPERTY_ADDRESS'] + ":</strong> " + lotTranslation.address + addressArea + "\n      </div>"
                            : '') + "\n\n      " + (lotTranslation.seller === ''
                            ? "<div class=\"card-row\">\n        <strong>" + strings['LOT_SELLER'] + ":</strong> \n        " + (municipalityName ? municipalityName[currentLang] : '') + " (" + strings['LOT_SELLER_CITY'] + ")\n      </div>"
                            : "<div class=\"card-row\">\n          <strong>" + strings['LOT_SELLER'] + ":</strong> \n          " + lotTranslation.seller + "\n        </div>") + "\n\n      " + (lotTranslation.areaSize
                            ? "<div class=\"card-row\">\n        <strong>" + strings['PROPERTY_AREA'] + ":</strong> " + lotTranslation.areaSize + "\n      </div>"
                            : '') + "\n\n      " + (property.buildArea
                            ? "<div class=\"card-row\">\n        <strong>" + strings['PERMITTED_AREA'] + ":</strong> " + property.buildArea + " k-m\u00B2\n      </div>"
                            : '') + "\n\n      " + (property.rentPrice
                            ? "<div class=\"card-row\">\n        <strong>" + strings['RENTAL_PRICE'] + ":</strong> " + property.rentPrice + " \u20AC\n      </div>"
                            : '') + "\n\n      " + (lotTranslation.salePrice
                            ? "<div class=\"card-row\"> \n        <strong>" + strings['SALES_PRICE'] + ":</strong> " + lotTranslation.salePrice + "\n      </div>"
                            : '') + "\n\n      " + (hood
                            ? "\n          <div class=\"card-row\">\n        <strong>" + strings['AREA.NAVLINK'] + ":</strong>\n        <a class=\"card-hood-link\" href=\"/" + currentLang + "/hood/" + (municipalitySlug ? municipalitySlug + '/' : '') + hood.slug + "\"\n        >" + hood.name[currentLang] + "</a>\n        </div>\n      "
                            : '') + "\n\n      " + (reserveUrl
                            ? "\n      <div class=\"card-spacer\"></div>\n      <div class=\"card-divider\"></div>\n      <div>\n      <a class=\"card-link\" \n          " + (onclickGtag ? onclickGtag : '') + " \n          href=\"" + reserveUrl + "\" \n          style=\"display:block;width:100%;\" \n          target=\"_blank\">\n            " + strings['OPEN_RESERVATIONS'] + "\n             \n        </a>\n        " + (lotTranslation.linkDescription ? "" + lotTranslation.linkDescription : '') + "\n        </div>"
                            : '') + "\n\n      </div>\n      ";
                        return [2 /*return*/, popupContent];
                }
            });
        });
    };
    MunicipalitiesService.ngInjectableDef = i0.defineInjectable({ factory: function MunicipalitiesService_Factory() { return new MunicipalitiesService(i0.inject(i1.HttpClient), i0.inject(i2.TranslateService), i0.inject(i3.PropertyService)); }, token: MunicipalitiesService, providedIn: "root" });
    return MunicipalitiesService;
}());
export { MunicipalitiesService };
