export const formatUrl = (url: string) => {
  if (!url) {
    return;
  }

  const pathArray = url.split('/');
  const protocol = pathArray[0];
  const host = pathArray[2];
  let newUrl = protocol + '//' + host;
  newUrl = newUrl.replace(/^https?:\/\//, '');

  while (newUrl.includes('//undefined')) newUrl = newUrl.replace('//undefined', '');
  return newUrl;
};

export const formatUrl2 = (url: string) => {
  if (!url) {
    return;
  }

  const startsWithHttp = url.includes('://');
  const includesWww = url.includes('www.');

  if (startsWithHttp) {
    const split = url.split('://');
    url = split[1];
    if (url.includes('www.')) {
      const split = url.split('www.');
      url = split[1];
    }
  } else if (includesWww) {
    const split = url.split('www.');
    url = split[1];
  }

  url = url.endsWith('/') ? url.slice(0, -1) : url;

  return url;
};

export const mustUrlProtocol = (url: string) => {
  if (!url) {
    return '';
  }
  if (!url.startsWith('http')) {
    url = 'https://' + url;
  }
  return url;
};

export const addQueryParameters = (baseUrl: string, queryParams: { [key: string]: any }) => {
  const queryString = Object.keys(queryParams)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]))
    .join('&');
  const separator = baseUrl.includes('?') ? '&' : '?';
  const finalUrl = baseUrl + separator + queryString;
  return finalUrl;
};
