import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { endpoints } from '../../configs/sources';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { TranslationString } from '../../app.module';

@Injectable({
  providedIn: 'root',
})
export class TextsService {
  strings;
  lang;
  endpoint = endpoints.texts;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  availableLanguages: string[] = [];
  constructor(private http: HttpClient, @Inject(SESSION_STORAGE) private storage: StorageService) {}

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // console.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  parseStrings() {
    return new Promise((resolve, reject) => {
      if (this.storage.has('app_texts')) {
        const translations = this.storage.get('app_texts');
        Object.keys(translations).forEach((key) => {
          this.addAvailableLanguage(key);
        });
        resolve(translations);
      } else {
        const translations = {};
        this.getTexts().subscribe((results) => {
          if (results.data && results.data.length) {
            results.data.forEach((translationString: TranslationString) => {
              if (translationString.translations && translationString.translations.length) {
                translationString.translations.forEach((translation) => {
                  if (translation.translation && translation.translation.length) {
                    const path = translationString.name.split('.');
                    if (!translations[translation.languages_code.split('-')[0]]) {
                      translations[translation.languages_code.split('-')[0]] = {};
                    }
                    let temporaryObject = translations[translation.languages_code.split('-')[0]];
                    if (temporaryObject) {
                      path.forEach((pathPart, index) => {
                        if (index !== path.length - 1) {
                          if (!temporaryObject[pathPart]) {
                            temporaryObject[pathPart] = {};
                          }
                          temporaryObject = temporaryObject[pathPart];
                        } else {
                          temporaryObject[pathPart] = translation.translation;
                        }
                      });
                    }
                  }
                });
              }
            });
          }
          this.storage.set('app_texts', translations);
          Object.keys(translations).forEach((key) => {
            this.addAvailableLanguage(key);
          });
          resolve(translations);
        });
      }
    });
  }
  getTexts(): Observable<any> {
    return this.http.get(this.endpoint).pipe(map(this.extractData));
  }

  addAvailableLanguage = (language: string) => {
    if (!this.availableLanguages.includes(language)) {
      this.availableLanguages.push(language);
    }
  };

  getAvailableLanguages = () => {
    return this.availableLanguages;
  };
}
