import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { endpoints } from '../../configs/sources';
import { LotPicture, PropertyService } from '../properties/properties.service';
import { Property, PropertyHood, ReferenceIdPrefix } from '../properties/property.model';
import { Location } from '../locations/locations.model';
import { addQueryParameters } from '../../utils/urlUtils';
import { SelectedServiceFilters } from '../services-filter/services-filter.service';
@Injectable({
  providedIn: 'root',
})
export class MunicipalitiesService {
  endpoint = endpoints.municipalities;
  endpoint2 = endpoints.municipalitypictures;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  showDrafts = false;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private propertyService: PropertyService,
  ) {
    let location = window.location.host;

    if (location == 'dev.hoods.fi' || location == 'beta.hoods.fi' || location == 'localhost:4200') {
      this.showDrafts = true;
    }
  }

  setShowDrafts(value: boolean) {
    this.showDrafts = value;
  }

  private extractData(res: Response): any {
    let body = res;
    return body || {};
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // console.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  getMunicipalities(simplified = false): Observable<{ municipalities: Municipality[] }> {
    let url = `${this.endpoint}?draft=${this.showDrafts}`;
    if (simplified) {
      url += `&simplified=${true}`;
    }
    return this.http.get(url).pipe(map(this.extractData));
  }

  getMunicipality(id): Observable<{ municipality: Municipality }> {
    return this.http.get(this.endpoint + id).pipe(map(this.extractData));
  }

  getPictures(): Observable<any> {
    return this.http.get(this.endpoint2).pipe(map(this.extractData));
  }

  getMunicipalityPictures(id: number) {
    return this.http.get(this.endpoint + id + '/pictures').pipe(map(this.extractData));
  }

  getHoods(id): Observable<any> {
    return this.http
      .get(this.endpoint + id + '/hoods?draft=' + this.showDrafts + '&sort=name')
      .pipe(map(this.extractData));
  }

  getMunicipalitiesCenters(): Observable<any> {
    return this.http
      .get(this.endpoint + '/centers?draft=' + this.showDrafts)
      .pipe(map(this.extractData));
  }

  getMunicipalityLocations(
    municipalityId: number,
    filters: SelectedServiceFilters,
    isMapQuery: boolean,
    pagination?: { offset?: number; limit?: number },
    hoodListQuery?: { intersects: number; reverseIntersect: boolean },
  ): Observable<{ count: number; data: Location[] }> {
    const baseUrl = `${endpoints.municipalities}${municipalityId}/locations/list`;
    let finalUrl = baseUrl;
    if (pagination) {
      finalUrl = addQueryParameters(finalUrl, pagination);
    }
    if (filters) {
      const params: { source?: number[]; category?: number[] } = {};
      if (filters.sources.length > 0) {
        params.source = filters.sources;
      }
      if (filters.categories.length > 0) {
        params.category = filters.categories;
      }
      finalUrl = addQueryParameters(finalUrl, params);
    }
    if (isMapQuery) {
      finalUrl = addQueryParameters(finalUrl, { map: true });
    }
    if (hoodListQuery && hoodListQuery.intersects) {
      finalUrl = addQueryParameters(finalUrl, hoodListQuery);
    }

    return this.http
      .get<{ error: string; meta: { total: number }; locations: Location[] }>(finalUrl)
      .pipe(
        map((response) => {
          return {
            count: response.meta.total,
            data: response.locations,
          };
        }),
        catchError((error) => {
          console.error(error);
          return throwError('Something went wrong while fetching municipality locations.');
        }),
      );
  }

  public reserveLotUrl(referenceId: string, muniSlug: string): string | undefined {
    if (!muniSlug) return '';
    if (!referenceId) return '';

    muniSlug = muniSlug.toLowerCase().trim();
    if (muniSlug === 'kaarina') {
      return `https://opaskartta.turku.fi/eSiteKaarina?siteList=${referenceId}`;
    } else if (referenceId.startsWith(ReferenceIdPrefix.KarttatiimiEura)) {
      return 'https://www.eurantontit.fi/';
    } else if (referenceId.startsWith(ReferenceIdPrefix.KarttatiimiKemionSaari)) {
      return 'https://www.kemionsaari.fi/fi/asuminen_ymparisto/asuminen_tontit/myytavat_tontit';
    } else if (referenceId.startsWith(ReferenceIdPrefix.SwecoPoytya)) {
      return 'https://www.poytya.fi/asu-ja-rakenna/asuminen/asuintontit';
    }
  }

  public async lotPopupText(
    property: Property,
    municipalitySlug: string,
    hood: PropertyHood,
    currentLang: string,
    onclickGtag: string = '',
    image: LotPicture | undefined = undefined,
    municipalityName?: string,
  ): Promise<string> {
    const icons = ['warehouse', 'home', 'building', 'briefcase', 'hotel'];
    const icon =
      '<i style="font-size: 1.3em;margin-right: 0.8em;" class="fas fa-' +
      icons[property.type] +
      '"></i>';

    let popupContent = '';
    let addressArea = '';
    const strings = await this.translate
      .get([
        'AREA.NAVLINK',
        'PROPERTY_AREA',
        'PERMITTED_AREA',
        'OPEN_RESERVATIONS',
        'GO_TO_HOOD',
        'SALES_PRICE',
        'RENTAL_PRICE',
        'LOT_SELLER',
        'LOT_SELLER_CITY',
        'PROPERTY_ADDRESS',
      ])
      .toPromise();

    if (property.description) {
      while (property.description.match(/^(\s|<br>)/)) {
        if (property.description.match(/^\s/)) property.description = property.description.slice(1);
        else property.description = property.description.slice('<br>'.length);
      }
    }

    if (municipalityName) {
      addressArea = ', ' + municipalityName[currentLang];
    } else {
      addressArea = '';
    }
    const lotTranslation = this.propertyService.getTranslation(property, currentLang);
    const reserveUrl =
      lotTranslation.externalLink ||
      property.externalLink ||
      this.reserveLotUrl(property.referenceId, municipalitySlug);
    // All the classes are in styles.scss
    popupContent = `
    <div class="property-card">
      <div class="property-card-title">
        <div class="card-title-icon">${icon}</div>
        <div class="card-title-text">
          <div class="card-title-name">${lotTranslation.name}</div> 
          ${
            property.propertyType
              ? `<div class="card-title-type">${property.propertyType}</div>`
              : ''
          }
        </div>
      </div>

      ${
        image
          ? `<div class="card-image" style="background-image:url(${image.url});">
      </div>`
          : '<div class="card-divider-top"><div class="card-divider"></div></div>'
      }      

      ${
        lotTranslation.description
          ? `<div class="card-description">
          ${lotTranslation.description}
        </div>`
          : ``
      }

      ${
        lotTranslation.address
          ? `<div class="card-row">
        <strong>${strings['PROPERTY_ADDRESS']}:</strong> ${lotTranslation.address}${addressArea}
      </div>`
          : ''
      }

      ${
        lotTranslation.seller === ''
          ? `<div class="card-row">
        <strong>${strings['LOT_SELLER']}:</strong> 
        ${municipalityName ? municipalityName[currentLang] : ''} (${strings['LOT_SELLER_CITY']})
      </div>`
          : `<div class="card-row">
          <strong>${strings['LOT_SELLER']}:</strong> 
          ${lotTranslation.seller}
        </div>`
      }

      ${
        lotTranslation.areaSize
          ? `<div class="card-row">
        <strong>${strings['PROPERTY_AREA']}:</strong> ${lotTranslation.areaSize}
      </div>`
          : ''
      }

      ${
        property.buildArea
          ? `<div class="card-row">
        <strong>${strings['PERMITTED_AREA']}:</strong> ${property.buildArea} k-m²
      </div>`
          : ''
      }

      ${
        property.rentPrice
          ? `<div class="card-row">
        <strong>${strings['RENTAL_PRICE']}:</strong> ${property.rentPrice} €
      </div>`
          : ''
      }

      ${
        lotTranslation.salePrice
          ? `<div class="card-row"> 
        <strong>${strings['SALES_PRICE']}:</strong> ${lotTranslation.salePrice}
      </div>`
          : ''
      }

      ${
        hood
          ? `
          <div class="card-row">
        <strong>${strings['AREA.NAVLINK']}:</strong>
        <a class="card-hood-link" href="/${currentLang}/hood/${
              municipalitySlug ? municipalitySlug + '/' : ''
            }${hood.slug}"
        >${hood.name[currentLang]}</a>
        </div>
      `
          : ''
      }

      ${
        reserveUrl
          ? `
      <div class="card-spacer"></div>
      <div class="card-divider"></div>
      <div>
      <a class="card-link" 
          ${onclickGtag ? onclickGtag : ''} 
          href="${reserveUrl}" 
          style="display:block;width:100%;" 
          target="_blank">
            ${strings['OPEN_RESERVATIONS']}
             
        </a>
        ${lotTranslation.linkDescription ? `${lotTranslation.linkDescription}` : ''}
        </div>`
          : ''
      }

      </div>
      `;
    return popupContent;
  }
}

export interface Municipality {
  id: number;
  subRegionId: number;
  name: MunicipalityTranslation;
  municipalityCode: string;
  draft: boolean;
  slug: string;
  shortDescription: MunicipalityTranslation;
  longDescription: MunicipalityTranslation;
  area: any;
  color: string;
  link: MunicipalityTranslation;
  slogan: MunicipalityTranslation;
  showLocations: boolean;
  hasHoods: 1 | 0;
  mapBbox?: string;
}

export type MunicipalityTranslation = {
  [key: string]: string;
};
