import { Injectable } from '@angular/core';

Injectable();
export class Globals {
  DEFAULT_LANG: String = 'fi';
  LANG: String = 'fi';
  LANG_OPTIONS = [
    {
      id: 1,
      code: 'fi',
      name: 'Suomi',
    },
    {
      id: 2,
      code: 'en',
      name: 'English',
    },
  ];
}
