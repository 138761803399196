import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { HoodComponent } from '../modules/hood/hood/hood.component';
import { DialogService } from '../services/dialog/dialog.service';
import { Observable } from 'rxjs';

@Injectable()
export class ConfirmDeactivateGuard implements CanDeactivate<HoodComponent> {
  constructor(private dialogService: DialogService) {}

  canDeactivate(target: HoodComponent): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (
        target.reviewFormComponent !== undefined &&
        target.reviewFormComponent.reviewForm.touched
      ) {
        let options = {
          title: 'DIALOG.CANCEL.TITLE',
          message: 'DIALOG.CANCEL.MESSAGE',
          cancel: 'DIALOG.CANCEL.CANCEL',
          confirm: 'DIALOG.CANCEL.CONFIRM',
        };
        this.dialogService.open(options);
        this.dialogService.confirmed().subscribe((res) => {
          resolve(res);
        });
      } else {
        resolve(true);
      }
    });
  }
}
