/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./service-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i3 from "@angular/material/divider";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../node_modules/@angular/material/expansion/typings/index.ngfactory";
import * as i6 from "@angular/material/expansion";
import * as i7 from "@angular/cdk/collections";
import * as i8 from "@angular/common";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "ngx-responsive";
import * as i12 from "./service-content.component";
import * as i13 from "../../../services/locations/locations.service";
var styles_ServiceContentComponent = [i0.styles];
var RenderType_ServiceContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ServiceContentComponent, data: {} });
export { RenderType_ServiceContentComponent as RenderType_ServiceContentComponent };
function View_ServiceContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "dialog-image"]], [[4, "background-image", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.service.picture) + ")"); _ck(_v, 1, 0, currVal_0); }); }
function View_ServiceContentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "dialog-divider-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-divider", [["class", "dialog-divider mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i2.View_MatDivider_0, i2.RenderType_MatDivider)), i1.ɵdid(3, 49152, null, 0, i3.MatDivider, [], null, null)], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).vertical ? "vertical" : "horizontal"); var currVal_1 = i1.ɵnov(_v, 3).vertical; var currVal_2 = !i1.ɵnov(_v, 3).vertical; var currVal_3 = i1.ɵnov(_v, 3).inset; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_ServiceContentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "dialog-description"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 1, 0, currVal_0); }); }
function View_ServiceContentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "dialog-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-map-marker-alt"], ["style", "font-size: 1.3em; margin-right: 0.8em"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", "", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.service.address; var currVal_1 = (_co.service.city ? (", " + _co.service.city) : ""); _ck(_v, 4, 0, currVal_0, currVal_1); }); }
function View_ServiceContentComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "dialog-row align-items"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-globe"], ["style", "font-size: 1.3em; margin-right: 0.8em"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "fade"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fas fa-external-link-alt"], ["style", "font-size: 1.3em; text-align: end"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.urlLink(_co.service.url), ""); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.formatUrl(_co.service.url); _ck(_v, 5, 0, currVal_1); }); }
function View_ServiceContentComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "osm-explanation"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("LOCATIONS.OSM_EXPLANATION")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.osmUrl; _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("LOCATIONS.GO_TO_OSM_MAP")); _ck(_v, 4, 0, currVal_2); }); }
function View_ServiceContentComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 13, "mat-expansion-panel", [["class", "osm-panel mat-expansion-panel"]], [[2, "mat-expanded", null], [2, "_mat-animation-noopable", null], [2, "mat-expansion-panel-spacing", null]], null, null, i5.View_MatExpansionPanel_0, i5.RenderType_MatExpansionPanel)), i1.ɵdid(1, 1753088, null, 1, i6.MatExpansionPanel, [[3, i6.MAT_ACCORDION], i1.ChangeDetectorRef, i7.UniqueSelectionDispatcher, i1.ViewContainerRef, i8.DOCUMENT, [2, i9.ANIMATION_MODULE_TYPE], [2, i6.MAT_EXPANSION_PANEL_DEFAULT_OPTIONS]], null, null), i1.ɵqud(335544320, 1, { _lazyContent: 0 }), i1.ɵprd(256, null, i6.MAT_ACCORDION, undefined, []), (_l()(), i1.ɵeld(4, 0, null, 0, 7, "mat-expansion-panel-header", [["class", "osm-panel-header mat-expansion-panel-header"], ["role", "button"]], [[1, "id", 0], [1, "tabindex", 0], [1, "aria-controls", 0], [1, "aria-expanded", 0], [1, "aria-disabled", 0], [2, "mat-expanded", null], [40, "@expansionHeight", 0]], [[null, "click"], [null, "keydown"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._keydown($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_MatExpansionPanelHeader_0, i5.RenderType_MatExpansionPanelHeader)), i1.ɵdid(5, 180224, null, 0, i6.MatExpansionPanelHeader, [i6.MatExpansionPanel, i1.ElementRef, i10.FocusMonitor, i1.ChangeDetectorRef, [2, i6.MAT_EXPANSION_PANEL_DEFAULT_OPTIONS]], null, null), i1.ɵpod(6, { collapsedHeight: 0, expandedHeight: 1 }), i1.ɵpod(7, { value: 0, params: 1 }), (_l()(), i1.ɵeld(8, 0, null, 0, 3, "mat-panel-title", [["class", "mat-expansion-panel-header-title"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i6.MatExpansionPanelTitle, [], null, null), (_l()(), i1.ɵted(10, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, 1, 1, null, View_ServiceContentComponent_8)), i1.ɵdid(13, 16384, [[1, 4]], 0, i6.MatExpansionPanelContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).expanded; var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_2 = i1.ɵnov(_v, 1)._hasSpacing(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 5).panel._headerId; var currVal_4 = (i1.ɵnov(_v, 5).disabled ? (0 - 1) : 0); var currVal_5 = i1.ɵnov(_v, 5)._getPanelId(); var currVal_6 = i1.ɵnov(_v, 5)._isExpanded(); var currVal_7 = i1.ɵnov(_v, 5).panel.disabled; var currVal_8 = i1.ɵnov(_v, 5)._isExpanded(); var currVal_9 = _ck(_v, 7, 0, i1.ɵnov(_v, 5)._getExpandedState(), _ck(_v, 6, 0, i1.ɵnov(_v, 5).collapsedHeight, i1.ɵnov(_v, 5).expandedHeight)); _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("LOCATIONS.RECOMMEND_CHANGES")); _ck(_v, 10, 0, currVal_10); }); }
function View_ServiceContentComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "dialog-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceContentComponent_7)), i1.ɵdid(3, 212992, null, 0, i11.XsDirective, [i1.TemplateRef, i1.ViewContainerRef, i11.ResponsiveState, i1.ChangeDetectorRef, i1.PLATFORM_ID], { xs: [0, "xs"] }, null)], function (_ck, _v) { var currVal_0 = null; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ServiceContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceContentComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceContentComponent_2)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "dialog-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceContentComponent_3)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceContentComponent_4)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceContentComponent_5)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceContentComponent_6)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.service.imageUrl; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.service.imageUrl; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.description; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.service.address; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.service.url; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.osmUrl; _ck(_v, 12, 0, currVal_5); }, null); }
export function View_ServiceContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-service-content", [], null, null, null, View_ServiceContentComponent_0, RenderType_ServiceContentComponent)), i1.ɵdid(1, 114688, null, 0, i12.ServiceContentComponent, [i13.LocationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ServiceContentComponentNgFactory = i1.ɵccf("app-service-content", i12.ServiceContentComponent, View_ServiceContentComponent_Host_0, { data: "data" }, {}, []);
export { ServiceContentComponentNgFactory as ServiceContentComponentNgFactory };
