import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * A shared component that provides navigation for paged information.
 *
 */
@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  // The total length of data
  @Input() public total: number;

  // Each component will state it's own page settings
  @Input() public paginationOptionsRef: PaginationOptions;

  // Update the components on pagination settings changes
  @Output() public paginationOptions = new EventEmitter<PaginationOptions>();

  // The amount of selectable page numbers to display
  @Input() public pageNumberCount: number;

  constructor() {}

  ngOnInit() {}

  // The total count of page numbers
  getPageArray() {
    if (this.total === 1) {
      return [1];
    }
    const pages = new Array(Math.ceil(this.total / this.paginationOptionsRef.pageSize));
    return pages;
  }

  // The page number offset to correctly display the page numbers
  getPageOffset(): number {
    if (this.getPageArray().length <= this.pageNumberCount) {
      return 0;
    }

    const middle = Math.floor(this.pageNumberCount / 2);

    let offset = Math.max(0, this.paginationOptionsRef.pageIndex - middle);

    if (offset + this.pageNumberCount > this.getPageArray().length) {
      offset = this.getPageArray().length - this.pageNumberCount;
    }

    return offset;
  }

  prevPage() {
    if (this.paginationOptionsRef.pageIndex > 1) {
      this.paginationOptionsRef.pageIndex--;
    }
    this.paginationOptions.emit(this.paginationOptionsRef);
  }

  nextPage() {
    const pages = this.getPageArray().length;
    if (this.paginationOptionsRef.pageIndex < pages) {
      this.paginationOptionsRef.pageIndex++;
    }
    this.paginationOptions.emit(this.paginationOptionsRef);
  }

  goToPage(index: number) {
    this.paginationOptionsRef.pageIndex = this.getPageOffset() + index + 1;
    this.paginationOptions.emit(this.paginationOptionsRef);
  }

  maxPageNumbersToShow(): number {
    return Math.min(this.pageNumberCount, this.getPageArray().length) + this.getPageOffset();
  }
}

export interface PaginationOptions {
  pageIndex: number;
  pageSize: number;
}
