<ng-container *ngIf="image">
  <div class="dialog-image" [style.background-image]="'url(' + image.url + ')'"></div>
</ng-container>
<ng-container *ngIf="!image">
  <div class="dialog-divider-top"><mat-divider class="dialog-divider"></mat-divider></div>
</ng-container>
<div class="dialog-content">
  <ng-container *ngIf="lotTranslation.description">
    <div class="dialog-description" [innerHTML]="lotTranslation.description"></div>
  </ng-container>

  <ng-container *ngIf="lotTranslation.name !== lotTranslation.address">
    <div class="dialog-row">
      <strong>{{ 'PROPERTY_ADDRESS' | translate }}:</strong>
      {{ lotTranslation.address }} {{ addressArea }}
    </div>
  </ng-container>
  <ng-container *ngIf="lotTranslation.seller === ''">
    <div class="dialog-row">
      <strong>{{ 'LOT_SELLER' | translate }}:</strong>
      {{ municipalityName ? municipalityName[currentLanguage] : '' }}
      ({{ 'LOT_SELLER_CITY' | translate }})
    </div>
  </ng-container>
  <ng-container *ngIf="lotTranslation.seller !== ''">
    <div class="dialog-row">
      <strong>{{ 'LOT_SELLER' | translate }}:</strong>
      {{ lotTranslation.seller }}
    </div>
  </ng-container>

  <ng-container *ngIf="lotTranslation.areaSize">
    <div class="dialog-row">
      <strong>{{ 'PROPERTY_AREA' | translate }}:</strong>
      {{ lotTranslation.areaSize }}
    </div>
  </ng-container>

  <ng-container *ngIf="selectedLot.buildArea">
    <div class="dialog-row">
      <strong>{{ 'PERMITTED_AREA' | translate }}:</strong>
      {{ selectedLot.buildArea }} m²
    </div>
  </ng-container>

  <ng-container *ngIf="selectedLot.rentPrice">
    <div class="dialog-row">
      <strong>{{ 'RENTAL_PRICE' | translate }}:</strong>
      {{ lotTranslation.rentPrice }} €
    </div>
  </ng-container>

  <ng-container *ngIf="lotTranslation.salePrice">
    <div class="dialog-row">
      <strong>{{ 'SALES_PRICE' | translate }}:</strong>
      {{ lotTranslation.salePrice }}
    </div>
  </ng-container>

  <ng-container *ngIf="hoods">
    <div class="dialog-row">
      <strong>{{ 'AREA.NAVLINK' | translate }}:</strong>
      <a
        mat-button
        class="dialog-hood-link"
        href="/{{ currentLanguage }}/hood/{{ municipalitySlug ? municipalitySlug + '/' : '' }}{{
          hoods.slug
        }}"
      >
        {{ hoods.name[currentLanguage] }}</a
      >
    </div>
  </ng-container>
</div>
