import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MapDataService } from '../services/mapdata/mapdata.service';
import { TranslationFilter } from '../filters/translation.filter';
import { MapComponent } from '../components/map/map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NotFoundComponent } from '../components/not-found/not-found.component';
import { SidenavComponent } from '../components/sidenav/sidenav.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MunicipalitiesService } from '../services/municipalities/municipalities.service';
import { SplitPipe } from '../filters/split.filter';
import { SlugService } from '../services/slugs/slug.service';
import { ServicePointsSelector } from '../components/service-points/service-points.component';
import { SafeHtmlPipe } from '../filters/safehtml.filter';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { DialogService } from '../services/dialog/dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDividerModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTabsModule,
  MatToolbarModule,
  NativeDateModule,
} from '@angular/material';
import { NumberInputDirective } from '../validators/numberInput.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { ResidenceCardComponent } from '../components/residence-card/residence-card.component';
import { RouterModule } from '@angular/router';
import { NgStringPipesModule } from 'angular-pipes';
import { ReviewCardComponent } from '../components/review-card/review-card.component';
import { ResponsiveModule } from 'ngx-responsive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SingleReviewRatingComponent } from './hood/single-review-rating/single-review-rating.component';
import { ArticleCardComponent } from '../components/article-card/article-card.component';
import { MapMarkerPopupComponent } from '../components/map-marker-popup/map-marker-popup.component';
import { ServiceContentComponent } from '../components/map-marker-popup/service-content/service-content.component';
import { LotContentComponent } from '../components/map-marker-popup/lot-content/lot-content.component';
import { EventViewComponent } from '../components/event/event-view/event-view.component';
import { PaginatorComponent } from '../components/paginator/paginator.component';
import { EventCardComponent } from '../components/event/event-card/event-card.component';
import { EventFilterComponent } from '../components/event/event-filter/event-filter.component';
import { EventItemComponent } from '../components/event/event-item/event-item.component';
import { SimpleMapComponent } from '../components/simple-map/simple-map.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EventCardSmallComponent } from '../components/event/event-card-small/event-card-small.component';
import { EventItemSmallComponent } from '../components/event/event-item-small/event-item-small.component';
import { EventItemSmallTabInfoComponent } from '../components/event/event-item-small/event-item-small-tab-info/event-item-small-tab-info.component';
import { ScrollUpComponent } from '../components/fab/scroll-up/scroll-up.component';
import { FabFeedbackComponent } from '../components/fab/fab-feedback/fab-feedback.component';
import { ServicesFilterComponent } from '../components/services/services-filter/services-filter.component';
import { ServicesListComponent } from '../components/services/services-list/services-list.component';
import { ServicesListItemComponent } from '../components/services/services-list-item/services-list-item.component';
import { ServicesListItemDetailsComponent } from '../components/services/services-list-item-details/services-list-item-details.component';
import { ServicesFilterMobileComponent } from '../components/services/services-filter-mobile/services-filter-mobile.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    MapComponent,
    TranslationFilter,
    NotFoundComponent,
    SidenavComponent,
    ServicePointsSelector,
    SafeHtmlPipe,
    ConfirmDialogComponent,
    NumberInputDirective,
    SnackbarComponent,
    ResidenceCardComponent,
    ReviewCardComponent,
    SingleReviewRatingComponent,
    ArticleCardComponent,
    MapMarkerPopupComponent,
    ServiceContentComponent,
    LotContentComponent,
    EventViewComponent,
    PaginatorComponent,
    EventCardComponent,
    EventFilterComponent,
    EventItemComponent,
    SimpleMapComponent,
    EventCardSmallComponent,
    EventItemSmallComponent,
    EventItemSmallTabInfoComponent,
    ScrollUpComponent,
    FabFeedbackComponent,
    ServicesFilterComponent,
    ServicesListComponent,
    ServicesListItemComponent,
    ServicesListItemDetailsComponent,
    ServicesFilterMobileComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    LeafletModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatCardModule,
    MatIconModule,
    ResponsiveModule,
    RouterModule,
    NgStringPipesModule,
    FontAwesomeModule,
    MatDividerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatProgressBarModule,
    NativeDateModule,
    NgbTooltipModule,
    MatTabsModule,
    MatCheckboxModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatMenuModule,
  ],
  exports: [
    TranslateModule,
    MapComponent,
    TranslationFilter,
    NotFoundComponent,
    SidenavComponent,
    ServicePointsSelector,
    SafeHtmlPipe,
    ConfirmDialogComponent,
    NumberInputDirective,
    ResidenceCardComponent,
    ReviewCardComponent,
    ArticleCardComponent,
    EventViewComponent,
    PaginatorComponent,
    EventCardComponent,
    EventCardSmallComponent,
    EventFilterComponent,
    FabFeedbackComponent,
    ScrollUpComponent,
    ServicesFilterComponent,
    ServicesListComponent,
  ],
  entryComponents: [
    ConfirmDialogComponent,
    SnackbarComponent,
    SingleReviewRatingComponent,
    MapMarkerPopupComponent,
    SimpleMapComponent,
    EventItemComponent,
    EventFilterComponent,
    EventItemSmallComponent,
    ServicesListItemDetailsComponent,
    ServicesFilterMobileComponent,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [MapDataService, MunicipalitiesService, SlugService, DialogService],
    };
  }
}
