import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { base, endpoints } from '../../configs/sources';

@Injectable({
  providedIn: 'root',
})
export class RegionsService {
  endpoint = endpoints.regions;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  showDrafts = false;

  constructor(private http: HttpClient) {
    let location = window.location.host;

    if (location == 'dev.hoods.fi' || location == 'beta.hoods.fi' || location == 'localhost:4200') {
      this.showDrafts = true;
    }
  }

  setShowDrafts(value: boolean) {
    this.showDrafts = value;
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // console.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  getRegions(): Observable<any> {
    return this.http.get(this.endpoint + '?draft=' + this.showDrafts).pipe(map(this.extractData));
  }

  getRegion(id): Observable<any> {
    return this.http
      .get(this.endpoint + '/' + id + +'?draft=' + this.showDrafts)
      .pipe(map(this.extractData));
  }

  getRegionsSubregions(id): Observable<any> {
    return this.http
      .get(this.endpoint + '/' + id + '/subregions' + +'?draft=' + this.showDrafts)
      .pipe(map(this.extractData));
  }
}
