<div class="container" [ngClass]="isSmallDeviceWidth ? 'filters-small' : 'filters'">
  <!-- HEADER -->
  <div class="header">
    <span class="align-tooltop"
      >{{ 'EVENT_FILTER.HEADING' | translate }}
      <mat-icon
        matListIcon
        class="ngb-more-info"
        ngbTooltip="{{ 'EVENT_FILTER.TOOLTIP.HEADING' | translate }}"
        placement="top"
        >info</mat-icon
      >
    </span>

    <button *xs mat-icon-button (click)="closeFilterDialog('close')">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider *xs></mat-divider>

  <!-- FILTER FORMS -->
  <div class="filters-list">
    <form [formGroup]="filterGroup" [class.form-small]="isSmallDeviceWidth">
      <!-- TOGGLE SUPEREVENT -->
      <!-- Currently disabled because of bad user experience -->
      <!-- <div class="filter-group toggle">
        <label for="formControlSuper" class="align-tooltop"
          >{{ 'EVENT_FILTER.LABEL.SUPER_EVENTS' | translate }}
          <mat-icon
            matListIcon
            class="ngb-more-info"
            ngbTooltip="{{ 'EVENT_FILTER.TOOLTIP.SUPEREVENT' | translate }}"
            placement="top"
            >info</mat-icon
          ></label
        >

        <mat-slide-toggle #isChecked formControlName="formControlSuper">
          <span *ngIf="isChecked.checked">{{ 'EVENT_FILTER.LABEL.TOGGLE_ON' | translate }}</span>
          <span *ngIf="!isChecked.checked">{{ 'EVENT_FILTER.LABEL.TOGGLE_OFF' | translate }}</span>
        </mat-slide-toggle>
      </div> -->
      <!-- <mat-divider *xs></mat-divider> -->

      <div *xs class="sub-headings">
        <span>{{ 'EVENT_FILTER.SUBHEADING.CATEGORY' | translate }}</span>
      </div>

      <!-- CATEGORY CONTENT -->
      <div class="filter-group">
        <label for="formControlContent">{{ 'EVENT_FILTER.LABEL.CONTENT' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select
            disableRipple
            formControlName="formControlContent"
            multiple
            #formControlContentRef
          >
            <div class="close-select">
              <button mat-icon-button mat-button (click)="formControlContentRef.close()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <div class="mat-option-container">
              <mat-option *ngFor="let content of contents" [value]="content.id">{{
                content.name[currentLanguage]
              }}</mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- CATEGORY TYPE -->
      <div class="filter-group">
        <label for="formControlType">{{ 'EVENT_FILTER.LABEL.TYPE' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select disableRipple formControlName="formControlType" multiple #formControlTypeRef>
            <div class="close-select">
              <button mat-icon-button mat-button (click)="formControlTypeRef.close()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <div class="mat-option-container">
              <mat-option *ngFor="let type of types" [value]="type.id">{{
                type.name[currentLanguage]
              }}</mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- CATEGORY AUDIENCE -->
      <div class="filter-group">
        <label for="formControlAudience">{{ 'EVENT_FILTER.LABEL.AUDIENCE' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select
            disableRipple
            formControlName="formControlAudience"
            multiple
            #formControlAudienceRef
          >
            <div class="close-select">
              <button mat-icon-button mat-button (click)="formControlAudienceRef.close()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <div class="mat-option-container">
              <mat-option *ngFor="let audience of audiences" [value]="audience.id">{{
                audience.name[currentLanguage]
              }}</mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-divider *xs></mat-divider>

      <div *xs class="sub-headings">
        <span>{{ 'EVENT_FILTER.SUBHEADING.DATE' | translate }}</span>
      </div>

      <!-- DATE START -->
      <div class="filter-group date-field">
        <label for="formControlStart">{{ 'EVENT_FILTER.LABEL.START_DATE' | translate }}</label>
        <mat-form-field
          appearance="outline"
          (click)="formControlStart.open()"
          class="enable-mouse-over"
        >
          <input
            class="enable-mouse-over"
            formControlName="formControlStart"
            matInput
            [matDatepicker]="formControlStart"
            (dateChange)="startChanged($event)"
            [min]="today"
            [max]="selectedEnd ? selectedEnd : null"
          />
          <mat-icon class="calendar-icon" matSuffix *ngIf="!selectedStart">today</mat-icon>
          <mat-icon
            matSuffix
            *ngIf="selectedStart"
            (click)="resetDate('start'); $event.stopPropagation()"
            role="button"
            >close</mat-icon
          >
          <mat-datepicker [touchUi]="isSmallDeviceWidth" #formControlStart></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- DATE END -->
      <div class="filter-group date-field">
        <label for="formControlEnd">{{ 'EVENT_FILTER.LABEL.END_DATE' | translate }}</label>
        <mat-form-field
          appearance="outline"
          (click)="formControlEnd.open()"
          class="enable-mouse-over"
        >
          <input
            class="enable-mouse-over"
            formControlName="formControlEnd"
            matInput
            [matDatepicker]="formControlEnd"
            (dateChange)="endChanged($event)"
            [min]="selectedStart ? selectedStart : today"
          />
          <mat-icon class="calendar-icon" matSuffix *ngIf="!selectedEnd">today</mat-icon>
          <mat-icon
            matSuffix
            *ngIf="selectedEnd"
            (click)="resetDate('end'); $event.stopPropagation()"
            role="button"
            >close</mat-icon
          >
          <mat-datepicker [touchUi]="isSmallDeviceWidth" #formControlEnd></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
  </div>

  <div *hideItBootstrap="['xs']" class="close-button-desktop">
    <button mat-flat-button (click)="showResults()">
      {{ 'EVENT_FILTER.BUTTON.RESULTS' | translate }}
    </button>
    <button *hideItBootstrap="['xs']" mat-button (click)="clearFilters()">
      {{ 'EVENT_FILTER.BUTTON.CLEAR' | translate }}
    </button>
  </div>

  <!-- LOADING BAR -->
  <ng-container *hideItBootstrap="['xs']">
    <div *ngIf="loading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </ng-container>
</div>

<!-- MOBILE ACTION BUTTON -->
<div *xs class="close-button">
  <button mat-button (click)="clearFilters('mobile')">
    {{ 'EVENT_FILTER.BUTTON.CLEAR' | translate }}
  </button>
  <button mat-flat-button (click)="closeFilterDialog('show')">
    {{ 'EVENT_FILTER.BUTTON.RESULTS' | translate }}
  </button>
</div>
