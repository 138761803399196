import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../../../services/events/event.model';
import { getDateRange } from '../../../utils/getFormattedDate';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { EventService } from '../../../services/events/event.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { EventItemSmallComponent } from '../event-item-small/event-item-small.component';
import { Router } from '@angular/router';

@Component({
  selector: 'event-card-small',
  templateUrl: './event-card-small.component.html',
  styleUrls: ['./event-card-small.component.scss'],
})
export class EventCardSmallComponent implements OnInit {
  @Input() public data: Event;

  // public placeholderImage = 'assets/images/hood-placeholder.jpeg';
  public currentLang = 'fi';

  public name = '';
  public imageUrl = '';
  public imageAltText = '';
  public date = '';
  public loading = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    private eventService: EventService,
    private router: Router,
  ) {
    this.currentLang = this.translate.currentLang;

    this.translate.onLangChange.subscribe((event) => {
      this.currentLang = this.translate.currentLang;
    });
  }

  ngOnInit() {
    this.getLoadingStatus();
    if (this.data) {
      this.date = this.getEventDate();
      this.imageUrl = this.data.images.length > 0 ? this.data.images[0].url : '';
      this.imageAltText =
        this.data.images.length > 0 &&
        this.data.images[0].alt_text &&
        this.data.images[0].alt_text[this.currentLang]
          ? this.data.images[0].alt_text[this.currentLang]
          : '';
      this.name = this.data.name[this.currentLang] ? this.data.name[this.currentLang] : '';
    }
  }

  getEventDate(): string {
    return getDateRange(this.data.start_time, this.data.end_time);
  }

  openEventItem() {
    this.router.navigate([], { queryParams: { item: this.data.id } });

    // const dialogRef = this.dialog.open(EventItemSmallComponent, {
    //   maxWidth: '100vw',
    //   height: '100%',
    //   width: '100%',
    //   panelClass: 'event-modal',
    //   data: { id: this.data.id },
    // });
  }

  getLoadingStatus() {
    this.eventService.loadingStatus.pipe(takeUntil(this.destroyed$)).subscribe((loading) => {
      this.loading = loading;
    });
  }
}
