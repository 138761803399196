<div mat-dialog-content class="dialog-rating">
  <div *ngFor="let rating of data.ratings" class="rating-category">
    <label
      ><strong>{{
        'SUBFEATURE.' + (rating?.category?.name | uppercase) + '.TITLE' | translate
      }}</strong></label
    >

    <div class="average-rating">
      <div *ngIf="rating?.value != 0; then thenAverage; else elseAverage"></div>
      <ng-template #thenAverage>
        <fa-icon class="star" [icon]="faHeartSolid"></fa-icon>
        <span class="rating-value"
          ><strong>{{ rating?.value | number: '1.1-1':'fi' }}</strong></span
        >
      </ng-template>
      <ng-template #elseAverage>
        <fa-icon class="star" [icon]="faHeartSolid"></fa-icon>
        <span class="rating-value"><strong>-</strong></span>
      </ng-template>
    </div>
  </div>
</div>
