<ng-container *ngIf="service$ | async as service">
  <div class="container">
    <mat-toolbar *xs>
      <span class="fill-remaining-space"></span>
      <button
        mat-icon-button
        attr.aria-label="{{ 'AC.BUTTON.CLOSE' | translate }}"
        (click)="closeDialog()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
    <div class="image" [ngStyle]="{ 'background-image': 'url(' + featurePicture + ')' }">
      <button
        mat-fab
        (click)="closeDialog()"
        *hideItBootstrap="['xs']"
        attr.aria-label="{{ 'AC.BUTTON.CLOSE' | translate }}"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="content">
      <div class="description">
        <h1>{{ service.name }}</h1>
        <div class="text">
          <div [innerHTML]="service.description"></div>
        </div>
      </div>

      <div class="contact">
        <h2>{{ 'service-list-item-details.contact.heading' | translate }}</h2>
        <div class="contact-details">
          <div *ngIf="service.address" class="contact-details-address">
            <b>{{ 'service-list-item-details.contact.address' | translate }}</b>
            <div class="contact-details-address-street">
              {{ service.address }}
            </div>
            <div *ngIf="service.city" class="contact-details-address-city">
              <span *ngIf="service.zipCode">{{ service.zipCode }}, </span>{{ service.city }}
            </div>
          </div>
          <div *ngIf="service.url" class="contact-details-url">
            <b>{{ 'service-list-item-details.contact.url' | translate }}</b>
            <div class="text-overflow">
              <a href="{{ fixUrl(service.url) }}" target="_blank">{{ fixUrl(service.url) }}</a>
            </div>
          </div>
          <div *ngIf="service.email" class="contact-details-email">
            <b>{{ 'service-list-item-details.contact.email' | translate }}</b>
            <div class="text-overflow">
              <a href="mailto:{{ service.email }}">{{ service.email }}</a>
            </div>
          </div>
        </div>

        <!-- TODO: Should be using coordinates instead of address -->
        <ng-container *ngIf="service.address">
          <div *xs class="open-map-button">
            <button class="bordered" mat-flat-button (click)="openMapDialog()">
              {{ 'service-list-item-details.map.button' | translate }}
            </button>
          </div>
        </ng-container>

        <div *ngIf="service.externalId" class="edit-action">
          <div class="text">{{ 'service-list-item-details.edit.label' | translate }}</div>
          <button class="bordered" mat-flat-button (click)="editLocation(service.externalId)">
            {{ 'service-list-item-details.edit.button' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
