import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import * as L from 'leaflet';
import { SimpleMapService } from './simple-map.service';
import { AreaProperties, FeatureCollection, ServicesService } from '../services/services.service';
import { take, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ServicesFilterService } from '../../services/services-filter/services-filter.service';
import { ServicesListItemDetailsComponent } from '../services/services-list-item-details/services-list-item-details.component';

export class GeoJsonPoint {
  type: string;
  id: string;
  geometry: {
    type: string;
    coordinates: number[];
  };
  properties?: {
    title: string;
    categories: string;
  };
}

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/icons/hoods-marker.png';
const shadowUrl = 'assets/icons/hoods-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [32, 32],
  shadowAnchor: [16, 32],
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'simple-map',
  templateUrl: './simple-map.component.html',
  styleUrls: ['./simple-map.component.scss'],
})
export class SimpleMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() point: GeoJsonPoint;
  @Input() isLargeMap = false;
  @Input() zoomToMarkerId: number;

  public simpleMap: L.Map;
  private tileServerUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  private attribution = '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
  private options: L.MapOptions = {
    center: [60.4516, 22.2659],
    zoom: 9,
    zoomControl: false,
  };
  private markersById = {};
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private currentOpenToolTip: L.Layer;
  private multipleCategoryMarkerIcon = 'shapes';
  private areaBorderLayer = L.layerGroup();
  public area: AreaProperties;

  private cluster: L.MarkerClusterGroup = (L.markerClusterGroup as any).layerSupport({
    spiderfyOnMaxZoom: true,
    showCoverageOnHover: false,
    zoomToBoundsOnClick: true,
    spiderLegPolylineOptions: { opacity: 0 },
    maxClusterRadius: function (zoom) {
      return zoom == 18 ? 1 : 80;
    },
    // disableClusteringAtZoom: 18,
    iconCreateFunction: function (cluster) {
      return L.divIcon({
        html: '<div class="cluster"><strong>' + cluster.getChildCount() + '</strong></div>',
      });
    },
  });

  constructor(
    @Optional() public dialogRef: MatDialogRef<SimpleMapComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { point: GeoJsonPoint; isLargeMap: boolean; zoomToMarkerId: number },
    private simpleMapService: SimpleMapService,
    private servicesService: ServicesService,
    private servicesFilterService: ServicesFilterService,
    public dialog: MatDialog,
  ) {
    this.getAreaProperties();

    if (this.dialogRef && this.data) {
      this.zoomToMarkerId = this.data.zoomToMarkerId;
    }

    this.servicesFilterService.updateServices$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data && this.simpleMap) {
          this.simpleMapService.showLoading(this.simpleMap);
        }
      });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.initMap().whenReady(() => {
      this.simpleMapService.mapServices$.pipe(takeUntil(this.destroyed$)).subscribe((services) => {
        // Fix for mobile devices. Mobile instantiates this simple map component using a button.
        const servicesCompare = this.simpleMapService.getServicesCompare();
        if (servicesCompare === services && !this.servicesService.isMobile) {
          return;
        }
        this.simpleMapService.setServicesCompare(services);
        this.loadMarkers(services);
      });

      this.simpleMapService.zoomToMarkerToken$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((data) => {
          this.zoomToMarker(data);
        });

      if (this.zoomToMarkerId) {
        this.zoomToMarker(this.zoomToMarkerId);
      }
    });
  }

  async getAreaProperties() {
    this.servicesService.areaProperties$.pipe(takeUntil(this.destroyed$)).subscribe((area) => {
      this.area = area;
    });
  }

  ngOnDestroy(): void {
    this.simpleMap.remove();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private initMap() {
    this.simpleMap = L.map('simple-map', this.options);

    const tiles = L.tileLayer(this.tileServerUrl, {
      maxZoom: 18,
      minZoom: 3,
      attribution: this.attribution,
    });

    tiles.addTo(this.simpleMap);
    this.renderAreaBorders();
    return this.simpleMap;
  }

  private getArea(): AreaProperties {
    // Fix for mobile devices. Only render Hoods borders when showing all map points from municipality.
    let area = this.area;
    const showMunicipalityServices = this.servicesService.getShowMunicipalityServices();
    if (showMunicipalityServices) {
      area = this.simpleMapService.getHoodArea();
    }
    return area;
  }

  private renderAreaBorders() {
    const areaToRender = this.getArea();
    const geoJson = L.geoJSON(areaToRender.area);
    const style = {
      color: areaToRender.color,
      fillColor: areaToRender.color,
      fillOpacity: 0.05,
    };
    geoJson.setStyle(style);
    this.areaBorderLayer.clearLayers();
    geoJson.addTo(this.areaBorderLayer);
    this.areaBorderLayer.addTo(this.simpleMap);
  }

  private fitViewToAreaBounds() {
    const areaToRender = this.getArea();
    const bounds = L.geoJSON(areaToRender.area).getBounds();
    this.simpleMap.fitBounds(bounds);
  }

  private renderMarkerIcon(icons) {
    let iconHtml = '';
    icons.forEach((icon) => {
      iconHtml += '<i class="fas fa-' + icon + '"></i>';
    });
    let classNames =
      icons.length === 1 ? 'leaflet-div-icon single' : 'leaflet-div-icon single multi';
    return L.divIcon({
      html: iconHtml,
      className: classNames,
      iconSize: [32, 32],
    });
  }

  public loadMarkers(geoJsonData: FeatureCollection) {
    if (!geoJsonData || geoJsonData.features.length === 0) {
      // Default to area bounds when no markers exist
      this.fitViewToAreaBounds();
      this.cluster.clearLayers();
      this.simpleMapService.hideLoading();
      return;
    }

    if (this.cluster) {
      this.cluster.clearLayers();
    }

    // Create a marker for each location
    const layerGroup = L.geoJSON(geoJsonData, {
      pointToLayer: (feature, latlng) => {
        // Do not render the marker if it is outside of selected area
        const isInsideSelectedArea = this.simpleMapService.isPointInPolygon(
          [latlng.lng, latlng.lat],
          this.area.area.geometry,
        );
        if (!isInsideSelectedArea) {
          return;
        }

        const icons = feature.properties.categories.map((category) => category.icon);
        const temporaryIcon = icons.length === 1 ? icons : [this.multipleCategoryMarkerIcon];
        const options: L.MarkerOptions = {
          icon: this.renderMarkerIcon(temporaryIcon),
        };
        const marker = L.marker(latlng, options);
        return marker;
      },
      onEachFeature: (feature, layer) => {
        const id = parseInt(feature.id.toString());
        const title = feature.properties.title;
        if (feature.properties && feature.properties.title) {
          let options: L.TooltipOptions = {
            direction: 'top',
            offset: L.point(0, -15),
          };
          if (this.servicesService.isMobile) {
            options.permanent = true;
          }
          layer.bindTooltip(title, options).openTooltip();
        }
        if (feature.id) {
          this.markersById[feature.id] = layer;
        }
        layer.on('click', () => {
          this.openServiceItemDetails(id);
        });
      },
    });

    layerGroup.addTo(this.cluster);
    this.cluster.addTo(this.simpleMap);

    // BUG: A race condition occurs with fitBounds and setView
    // when zooming to marker in mobile
    if (!this.zoomToMarkerId) {
      this.simpleMap.fitBounds(layerGroup.getBounds(), {
        padding: [6, 6],
      });
    }

    this.simpleMapService.hideLoading();
  }

  private openServiceItemDetails(id: number) {
    const options: MatDialogConfig = {
      panelClass: 'services-list-item-details-dialog',
      data: { id: id, referer: 'map' },
    };
    if (this.servicesService.isMobile) {
      options.width = '100%';
      options.height = '100%';
      options.maxWidth = '100%';
      options.autoFocus = false;
    } else {
      options.width = '900px';
      options.height = '600px';
      // Stops autoscrolling the modal to focusable element
      options.autoFocus = false;
    }
    const dialogRef = this.dialog.open(ServicesListItemDetailsComponent, options);
    if (this.servicesService.isMobile) {
      dialogRef.afterOpened().subscribe(() => {
        this.servicesService.setIsItemDetailsOpen(true);
      });
      dialogRef.afterClosed().subscribe(() => {
        this.servicesService.setIsItemDetailsOpen(false);
      });
    }
  }

  public zoomToMarker(id: number): void {
    if (!this.markersById[id]) {
      return;
    }

    if (this.currentOpenToolTip) {
      this.currentOpenToolTip.removeFrom(this.simpleMap);
    }

    const feature: L.Marker = this.markersById[id];
    this.simpleMap.setView(feature.getLatLng(), 18);

    feature.openTooltip();
    const tooltip = feature.getTooltip();
    if (tooltip) {
      this.currentOpenToolTip = tooltip;
    }
  }

  closeMap() {
    this.dialogRef.close();
  }
}
