import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '../../configs/sources';
import { IVote } from '../../models/IVote';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class VotingService {
  readonly API_URL = endpoints.votes;

  constructor(private http: HttpClient) {}

  getVotes(type: string, id: number): Observable<any> {
    return this.http.get<any>(this.API_URL + '?type=' + type + '&id=' + id);
  }

  getFeedback(type: string, id: number, ip: string): Observable<any> {
    return this.http.get<any>(this.API_URL + '?type=' + type + '&id=' + id + '&ip=' + ip);
  }

  updateVote(vote: IVote): Observable<IVote> {
    return this.http.put<IVote>(this.API_URL, vote);
  }

  getIpAddress(): Observable<any> {
    return this.http.get('https://hoods-users.azurewebsites.net/users/headers');
  }
}
